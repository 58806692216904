/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable indent */

import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../shared/mixins/localize-nova.js';
import { mapify } from '../../../shared/methods.js';

export default class VisibilityManager extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      /**
       * Current tenant whose visibility settings are being managed
       */
      contextTenant: { type: Object },
      /**
       * All provider tenants
       */
      providers: { type: Array },
      /**
       * Nested JSON hierarchy of id's of providers > programs > courses
       */
      _activitiesHierarchy: { type: Object },
      /**
       * Map of all activities and all providers to allow O(1) id > entity lookups
       */
      _visibleProviders: { type: Object, attribute: false },
      /**
       * Is the search filter currently applied
       */
      _searching: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        .visibility-list {
          margin-left: 10px;
          list-style-type: none;
          border-left: 1px solid var(--d2l-color-corundum);
        }

        .visibility-list-item {
          display: flex;
          align-items: baseline;
          line-height: 0.5rem;
          vertical-align: super;
        }

        .status-text {
          margin: 0.5rem;
        }

        .status-error {
          color: red;
          border-color: red;
        }

        .greyed {
          color: var(--d2l-color-corundum);
        }

        d2l-input-checkbox.visible-cb {
          margin: 0;
        }
`,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._activitiesHierarchy = {};

    this.providers.forEach(provider => {
      this._activitiesHierarchy[provider.id] = {};
    });

    await this._updateVisibilities();
  }

  async _updateVisibilities() {
    const transform = ({ subjectId }) => subjectId;
    this._visibleProviders = mapify(await this.client.getVisibleProviders(this.contextTenant.id), 'subjectId', transform);
  }

  render() {
    if (!this.contextTenant || !this.providers) return html`Loading`;
    return html`
      ${this.providers.map(provider => this._providerAccordionTemplate(provider))}
    `;
  }

  /**
   * @param {Object} parentProvider - parent provider tenant
   * @param {Object} childPrograms - object of child programs and their child courses
   * [ <programId1>: {
   *    course: [{ id: <>, title: <> }, ... ],
   *    title: <programTitle>,
   * },
   * <programId2>: {...}
   * ]
   * @param {Array<Objects>} orphanActivities - array of orphan id's and titles [{ id: <>, title: <> }]
   * @returns lit-html template for provider-level accordion
   */
  _providerAccordionTemplate(parentProvider) {
    const isProviderVisible = this._isProviderVisible(parentProvider.id);
    const { programs, orphans, visibility } = this._activitiesHierarchy[parentProvider.id] || {};

    return html`
      <d2l-collapsible-panel
        expanded=${ifDefined(this._searching ? true : undefined)}
        type="inline"
        panel-title=${parentProvider.name}
        id="panel-${parentProvider.id}"
        @d2l-collapsible-panel-expand=${this._generateActivityVisibilitList}
      >
        <d2l-input-checkbox
          slot="before"
          class="visible-cb"
          ?checked=${isProviderVisible}
          @click=${this._preventAccordionClose}
          @change=${this._visibilityChanged(parentProvider, 'provider')}
        >
        </d2l-input-checkbox>
        ${programs || orphans ? html`
          <ul class="visibility-list" id=${parentProvider.id}>
            ${Object.entries(programs).map(([id, program]) =>
              this._programListTemplate({ id, ...program }, !isProviderVisible, visibility)
            )}
            ${orphans.map(orphan => {
              const isOrphanVisible = !!visibility[orphan.id];
              return this._topLevelActivityTemplate({ orphan: true, ...orphan }, isOrphanVisible, !isProviderVisible);
            })}
          </ul>
        ` : html`loading`}
      </d2l-collapsible-panel>
    `;
  }

  /**
   * @param {String} program - program object
   * @param {Boolean} isProviderDisabled - is the parent provider checkbox unchecked
   * @returns - lit-html template for a program checkbox and an indented list of courses
   */
  _programListTemplate(program, isProviderDisabled, visibility) {
    const isProgramVisible = !!visibility[program.id];
    const shouldDisableActivity = isProviderDisabled || !isProgramVisible;

    return html`
      <li>
        ${this._topLevelActivityTemplate(program, isProgramVisible, isProviderDisabled)}
        <ul class="visibility-list">
        ${program.courses.map(child => {
          return this._childActivityTemplate(child, visibility, shouldDisableActivity);
        })}
        </ul>
      </li>
    `;
  }

  /**
   * @param {Object} activity - activity object
   * @param {Boolean} isVisible - is in tenant list of visible activities
   * @param {Boolean} isDisabled - is the parent provider checkbox unchecked
   * @returns - lit-html template for an activity title and checkbox
   */
  _topLevelActivityTemplate(activity, isVisible, isDisabled = false) {
    return html`
      <li>
        <div class="visibility-list-item">
          <d2l-input-checkbox
            class="visible-cb"
            ?checked=${isVisible}
            ?disabled=${isDisabled}
            @change=${this._visibilityChanged(activity, 'activity')}
          ></d2l-input-checkbox>
          <p class="${!isVisible || isDisabled ? 'greyed' : ''}">${activity.title}</p>
          ${ activity.orphan ? this._orphanStatusTemplate() : nothing }
        </div>
     </li>
    `;
  }

  /**
   * @param {Object} activity - activity object
   * @param {Object} visibility - object list of activities belonging to a specific provider
   * @param {Boolean} shouldDisable - is the parent program checkbox unchecked
   * @returns - lit-html template for a child course list item
   */
  _childActivityTemplate(activity, visibility, shouldDisable = false) {
    // The parent program is disabled but the child course still has visibility elsewhere
    const isProviderVisible = this._isProviderVisible(activity.provider);
    const parentIsDisabledButIsVisible = shouldDisable && !!visibility[activity.id] && isProviderVisible;

    if (parentIsDisabledButIsVisible) {
      // Fetch parents and verify there is at least one parent that is visible
      this.client.fetchActivity(activity.id).then(({ parents }) => {
        if (!parents.some(({ id }) => visibility[id])) {
          activity.visibilityError = true;
        }
      });
    }

    return activity ? html`
      <li>
        <div class="visibility-list-item">
          <p class="${shouldDisable ? 'greyed' : ''}">${activity.title}</p>
          ${parentIsDisabledButIsVisible ? html`
            <d2l-status-indicator
              class="status-text${activity.visibilityError ? ' status-error' : ''}"
              state="default"
              text="${activity.visibilityError ? 'Error: Currently visible' : 'Note: Currently visible in a different program'}"
            ></d2l-status-indicator>
          ` : nothing}
        </div>
      </li>
    ` : nothing;
  }

  _orphanStatusTemplate() {
    return html`
      <d2l-status-indicator
        class="status-text"
        state="default"
        text="orphan"
      ></d2l-status-indicator>
    `;
  }

  _preventAccordionClose(e) {
    e.stopPropagation();
  }

  _isProviderVisible(providerId) {
    return this._visibleProviders?.[providerId] || false;
  }

  _displayNotificationToast(changedVis, type, visible) {
    let message = '';
    const verb = visible ? 'Showing' : 'Hiding';
    const preposition = visible ? 'to' : 'from';
    if (type === 'provider') {
      message = `${verb} provider ${changedVis.name} (and their enabled programs/courses) ${preposition} current tenant`;
    } else if (type === 'activity') {
      message = `${verb} ${changedVis.title} ${preposition} current tenant`;
    }
    this.session.toast({ type: 'default', message });
  }

  async _generateActivityVisibilitList(e) {
    const providerId = e.target.id.split('panel-')[1];
    const tempHierarchy = Object.assign({}, this._activitiesHierarchy);
    if (!this._activitiesHierarchy[providerId].programs && !this._activitiesHierarchy[providerId].orphans) {
      tempHierarchy[providerId] = await this.client.generateActivityHierarchy(this.contextTenant.id, providerId);
      this._activitiesHierarchy = tempHierarchy;
    }
  }

  _visibilityChanged(changedVis, entity) {
    return async e => {
      const visible = e.target.checked;
      await this.client.setVisibleState({
        active: changedVis.active,
        providerId: changedVis.provider,
        tenantId: this.contextTenant.id,
        id: changedVis.id,
        imageUrl: changedVis.imageUrl,
        name: changedVis.name,
        entity,
        visible,
      });
      await this._updateVisibilities();

      if (entity === 'activity') {
        const tempHierarchy = Object.assign({}, this._activitiesHierarchy);
        tempHierarchy[changedVis.provider] = await this.client.generateActivityHierarchy(this.contextTenant.id, changedVis.provider);
        this._activitiesHierarchy = tempHierarchy;
      }
      this._displayNotificationToast(changedVis, entity, visible);
    };
  }
}

window.customElements.define('visibility-manager', VisibilityManager);
