import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class CostModelSchema extends NovaSchema {

  constructor() {
    const config = {
      cost: new SchemaAttribute(),
      currency: new SchemaAttribute(),
      originalCurrency: new SchemaAttribute(),
      conversionRate: new SchemaAttribute(1),
    };
    super('cost', config);
  }
}

export const CostSchema = new CostModelSchema();
