import { bodyCompactStyles, bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';

import { novaTableStyles } from './application-table-styles.js';

class ApplicationTableCell extends LitElement {

  static get properties() {
    return {
      custom: { type: Object, reflect: false },
      mainText: { type: Object, reflect: false },
      subText: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      bodySmallStyles,
      offscreenStyles,
      novaTableStyles,
      css`
        .main-text,
        .sub-text {
          display: -webkit-box;
          min-width: none;
          padding: 0;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .main-text[title*="institution"] {
          -webkit-line-clamp: 2;
        }

        d2l-status-indicator[is-one-of-two-approved] {
          text-transform: none;
        }

        .indicator-container {
          display: block;
          width: 135px;
          max-width: 100%;
        }

        /**
         * The -20px is for the padding of the indicator.
         * box-sizing: border-box doesn't work because the associated
         * tooltip component's 'show-truncated-only' calculation
         * does not end up working properly
         */
        d2l-status-indicator {
          max-width: calc(100% - 20px);
        }

        d2l-icon {
          vertical-align: sub;
        }
`,
    ];
  }

  render() {
    return html`
      <div class="table-item" role="cell">
        ${this._mainContentTemplate}
      </div>
    `;
  }

  get _mainContentTemplate() {
    if (this.mainText) {
      const mainTitle = typeof this.mainText === 'object' ? undefined : this.mainText;
      const mainTextContent = html`
        <div class="main-text d2l-body-compact" title=${ifDefined(mainTitle)} tabindex="0">${this.mainText}</div>
      `;

      const subTitle = typeof this.subText === 'object' ? undefined : this.subText;
      const subTextContent = this.subText ? html`
        <div class="sub-text d2l-body-small" title=${ifDefined(subTitle)} tabindex="0">${this.subText}</div>
      ` : nothing;

      return html`
        ${mainTextContent}
        ${subTextContent}
      `;
    }
    return this.custom || nothing;
  }

}

window.customElements.define('application-table-cell', ApplicationTableCell);
