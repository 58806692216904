import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/icons/icon.js';

import './activity-small.js';
import './activity-small-list.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova.js';
import { novaCardStyles } from '../../styles/nova-card.js';

import Activity from '../../../../shared/models/activity/activity.js';

class ActivityRelationships extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activity: { type: Object },
      hideRequestability: { type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading4Styles,
      novaCardStyles,
      css`
        .course-completed {
          margin-top: 10px;
          background-color: var(--d2l-color-regolith);
          cursor: auto;
        }

        .celebration-card {
          display: flex;
          align-items: center;
          padding: 0.4rem 1.5rem;
        }

        .celebration-title {
          padding-left: 0.6rem;
        }

        .d2l-heading-4 {
          margin: 0;
        }

        d2l-collapsible-panel {
          background: white;
        }

        .electives-icon {
          padding-right: 20px;
          padding-left: 20px;
        }

        .activity-details > h3 {
          margin-top: 0;
        }

        @media (max-width: 615px) {

          .electives-icon {
            align-self: center;
            justify-self: center;
            min-width: 2.6rem;
            padding-right: unset;
            padding-left: unset;
          }

          .card-content {
            padding: 18px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = new Activity();
    this.hideRequestability = false;
  }

  get children() {
    return this.activity?.children?.filter(c => c.hasTag('active')).sort(this.sortChildren) || [];
  }

  get parents() {
    return this.activity?.parents?.filter(p => p.hasTag('active')) || [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    if (this.activity.type === 'program') {
      return this._cardifyTemplate(this._requiredCoursesTemplate);
    }
    if (this.parents?.length > 0) {
      return this._cardifyTemplate(this._relatedProgramsTemplate);
    }
    return nothing;
  }

  get _relatedProgramsTemplate() {
    return html`
      <div class="activity-details courses">
        <h3 class="d2l-heading-3">${this.localize('activity-relationships.relatedProgramsHeader')}</h3>
        ${this.parents.map(activity => html`
          <activity-small
            class="course-item-hover"
            mini
            .activity=${activity}>
          </activity-small>`)}
      </div>
    `;
  }

  sortChildren(a, b) {
    const parsedOrderA = parseInt(a.meta?.order);
    const parsedOrderB = parseInt(b.meta?.order);
    const aIsNumber = !isNaN(parsedOrderA);
    const bIsNumber = !isNaN(parsedOrderB);

    if (aIsNumber && bIsNumber) {
      return parsedOrderA - parsedOrderB;
    }

    // If no order, it goes first, which is consistent with the review-application page
    if (aIsNumber) return 1;
    if (bIsNumber) return -1;
    return 0;
  }

  get _requiredCoursesTemplate() {
    const numberOfElectives = this.activity.numberElectives || 0;
    return html`
      <div class="activity-details courses">
        <h3 class="d2l-heading-3">${this.localize('activity-relationships.requiredCourses')}</h3>
        <activity-small-list>
          ${this.children.map(course => (course.meta.type === 'core' ? html`
          <activity-small
          slot="course-item"
          class="course-item-hover"
          mini
          .activity=${course}
          showPrice></activity-small>
          ` : nothing))}
          ${this.children.filter(course => course.meta.type === 'elective').length > 0 ? html`
          <d2l-collapsible-panel
            heading-style="4"
            panel-title=${this.localize('activity-relationships.completeElectives', { numberOfElectives })}
            slot="course-item"
          >
            <d2l-icon slot="before" class="electives-icon" icon="tier2:add"></d2l-icon>
            ${this.children.map(course => (course.meta.type === 'elective' ? html`
              <activity-small
              class="course-item-hover"
              mini
              .activity=${course}
              showPrice></activity-small>
            ` : nothing))}
          </d2l-collapsible-panel>
          ` : nothing}
          <div slot="course-item" class="course-completed card">
            <div class="celebration-card">
              <img height="42" width="42" src="../../../assets/img/tada.png" alt="">
              <div class="celebration-title d2l-heading-4">
                ${this.activity.title}
              </div>
            </div>
          </div>
        </activity-small-list>
      </div>
    `;
  }

  _cardifyTemplate(template) {
    return html`
        <div class="card ${this.hideRequestability ? '' : 'relationship-card'} d2l-skeletize">
          <div class="card-content">
            <div class="activity-relationships">
            ${template}
            </div>
          </div>
        </div>
    `;
  }

}

window.customElements.define('activity-relationships', ActivityRelationships);
