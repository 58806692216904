import './application-table-cell.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import { ActiveLinkMixin } from '../../../shared/mixins/active-link/active-link-mixin.js';
import { formatCurrencyAsDecimalWithCode } from '../../../../shared/helpers/number.js';
import { formatIsoDate } from '../../../../shared/helpers/dateTime.js';
import { getTableContent } from './templates/application-table-templates.js';
import { localizeStartDate } from '../../../../shared/models/schema/activity/shared.js';
import { novaLocalize } from '../../../../shared/l10n/localize.js';

import { novaTableStyles } from './application-table-styles.js';

class ApplicationTableRow extends SkeletonMixin(ActiveLinkMixin(LitElement)) {

  static get properties() {
    return {
      application: { type: Object, reflect: false },
      persona: { type: String, reflect: false },
      tenant: { type: Object, reflect: false },
      _tableContent: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      novaTableStyles,
      css`
        :host {
          border-radius: 10px;
        }

        :host([hover]) .table-row {
          background-color: #fafafa;
        }

        .table-row[role=row] {
          grid-column-gap: 1.1rem;
          grid-template-columns: 0.4fr 1.5fr 1.5fr 0.9fr 1fr 1fr 1fr;
        }

        @media (max-width: 960px) {
          :host {
            display: none;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._tableContent = [];
  }

  get href() {
    return this.application?.uuid
      ? `/requests/${this.application.uuid}`
      : undefined;
  }

  render() {
    this.ariaLabel = novaLocalize('application-table-row.aria-label', {
      employeeName: this.application?.user?.getDisplayName(),
      activityTitle: this.application?.activity?.title,
      applicationDate: formatIsoDate(this.application?.applicationDate),
      nextSessionDate: this.application?.nextSessionDateWhenPaid ? localizeStartDate(this.application?.nextSessionDateWhenPaid) : this.application?.activity.formattedStartDate,
      activityCost: formatCurrencyAsDecimalWithCode(this.application?.postTaxActivityCost, this.application?.providerCurrency),
      status: novaLocalize(this.application.status.langKey),
      slug: this.application.slug,
    });

    return this.wrapWithLink(html`
      <div class="table-row d2l-skeletize" role="row">
      ${repeat(this._tableContent, value => html`
        <application-table-cell
          .mainText=${value.mainText}
          .subText=${value.subText}
          .custom=${value.custom}
        ></application-table-cell>
      `)}
      </div>
    `);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    for (const [propName] of _changedProperties) {
      if (['persona', 'application', 'tenant'].includes(propName)) {
        this._tableContent = getTableContent(this.persona, this.application, this.tenant);
      }
    }
  }

}

window.customElements.define('application-table-row', ApplicationTableRow);
