import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import { css, html, LitElement } from 'lit';
import { ButtonMixin } from '@brightspace-ui/core/components/button/button-mixin.js';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { getUniqueId } from '@brightspace-ui/core/helpers/uniqueId.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';

/**
 * A button component used like native button. Can force active state by passing force-active.
 * @slot - Default content placed inside of the button
 */
class NovaButton extends ButtonMixin(LitElement) {

  static get properties() {
    return {
      /**
       * A description to be added to the button for accessibility when text on button does not provide enough context
       */
      description: { type: String },
      /**
       * Disables use of button, applied disabled styling
       */
      disabled: { type: Boolean },
      /**
       * Tooltip text to display when button is disabled
       */
      disabledTooltip: { type: String, attribute: 'disabled-tooltip' },
      /**
       * Applies styling per Nova/Wave branding for 'light' mode button (only difference from default is content color)
       */
      novaLight: { type: Boolean, reflect: true, attribute: 'light' },
      /**
       * Applies styling per Nova/Wave branding for 'dark' mode button
       */
      novaDark: { type: Boolean, reflect: true, attribute: 'dark' },
      /**
       * Applies styling the same as button's active state (useful for applied states of filters, etc.)
       */
      styleActive: { type: Boolean, reflect: true, attribute: 'style-active' },
    };
  }

  static get styles() {
    return [
      labelStyles,
      buttonStyles,
      css`
        :host {
          /* Default */
          --nova-content-color: var(--d2l-color-ferrite);
          --nova-background-color: white;
          --nova-border-color: var(--d2l-color-galena);
          /* Active/Focus/Hover */
          --nova-content-color-active: var(--d2l-color-celestine);
          --nova-background-color-active: var(--d2l-color-regolith);
          --nova-border-color-active: var(--d2l-color-celestine);
          --nova-border-shadow-color: var(--d2l-color-celestine);
          /* Disabled */
          --nova-content-color-disabled: var(--d2l-color-ferrite);
          --nova-background-color-disabled: var(--d2l-color-gypsum);
          --nova-border-color-disabled: var(--d2l-color-ferrite);
          --nova-border-shadow-color-disabled: var(--d2l-color-ferrite);
          /* Styling */
          display: inline-block;
        }

        /* Disabled + Active/Focus/Hover */
        /* Tooltip focus messed with styling, needed to override with separate host([disabled]) scope */
        :host([disabled]) {
          --nova-content-color-active: var(--nova-content-color-disabled);
          --nova-background-color-active: var(--nova-background-color-disabled);
          --nova-border-color-active: var(--nova-border-color-disabled);
          --nova-border-shadow-color: var(--nova-border-shadow-color-disabled);
        }

        :host([light]) {
          /* Default */
          --nova-content-color: var(--d2l-color-celestine);
          /* Disabled */
          --nova-content-color-disabled: var(--d2l-color-celestine);
        }

        :host([dark]) {
          /* Default */
          --nova-content-color: var(--d2l-color-regolith);
          --nova-background-color: var(--d2l-color-celestine-minus-1);
          --nova-border-color: var(--d2l-color-celestine-minus-1);
          /* Active/Focus/Hover */
          --nova-content-color-active: white;
          --nova-background-color-active: var(--d2l-color-celestine);
          --nova-border-color-active: var(--d2l-color-celestine);
          --nova-border-shadow-color: var(--d2l-color-celestine);
          /* Disabled */
          --nova-content-color-disabled: var(--d2l-color-regolith);
          --nova-background-color-disabled: var(--d2l-color-celestine);
          --nova-border-color-disabled: var(--d2l-color-celestine);
          --nova-border-shadow-color-disabled: var(--d2l-color-celestine);
        }

        :host([hidden]) {
          display: none;
        }

        /* Firefox includes a hidden border which messes up button dimensions */
        button::-moz-focus-inner {
          border: 0;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 2.5rem;
          padding: 0.3rem 0.6rem;
          color: var(--nova-content-color);
          font-family: inherit;
          background-color: var(--nova-background-color);
          border: 1px solid var(--nova-border-color);
        }

        button ::slotted(*) {
          color: var(--nova-content-color);
        }

        button:hover,
        button:focus,
        :host([active]) button,
        :host([style-active]) button {
          color: var(--nova-content-color-active);
          background-color: var(--nova-background-color-active);
          border-color: var(--nova-border-color-active);
          -moz-box-shadow: inset 0 0 0 1px var(--nova-border-shadow-color);
          box-shadow: inset 0 0 0 1px var(--nova-border-shadow-color);
        }

        :host([disabled]) button {
          cursor: default;
          opacity: 0.5;
        }

        button[disabled]:hover,
        button[disabled]:focus,
        :host([active]) button[disabled],
        :host([style-active]) button[disabled] {
          color: var(--nova-content-color-disabled);
          background-color: var(--nova-background-color-disabled);
          border-color: var(--nova-border-color-disabled);
          -moz-box-shadow: inset 0 0 0 1px var(--nova-border-shadow-color-disabled);
          box-shadow: inset 0 0 0 1px var(--nova-border-shadow-color-disabled);
        }

        button:hover ::slotted(*),
        button:focus ::slotted(*),
        :host([active]) button ::slotted(*),
        :host([style-active]) button ::slotted(*) {
          color: var(--nova-content-color-active);
        }

        button[disabled]:hover ::slotted(*),
        button[disabled]:focus ::slotted(*),
        :host([active]) button[disabled] ::slotted(*),
        :host([style-active]) button[disabled] ::slotted(*) {
          color: var(--nova-content-color-disabled);
        }
`,
    ];
  }

  constructor() {
    super();
    this.disabled = false;
    this.styleActive = false;
    this._buttonId = getUniqueId();
  }

  render() {
    return html`
			<button
        aria-disabled="${ifDefined(this.disabled && this.disabledTooltip ? 'true' : undefined)}"
				aria-expanded="${ifDefined(this.ariaExpanded)}"
				aria-haspopup="${ifDefined(this.ariaHaspopup)}"
				aria-label="${ifDefined(this.description || this.ariaLabel)}"
				?autofocus="${this.autofocus}"
				class="d2l-label-text"
				?disabled="${this.disabled && !this.disabledTooltip}"
				form="${ifDefined(this.form)}"
				formaction="${ifDefined(this.formaction)}"
				formenctype="${ifDefined(this.formenctype)}"
				formmethod="${ifDefined(this.formmethod)}"
				?formnovalidate="${this.formnovalidate}"
				formtarget="${ifDefined(this.formtarget)}"
        id="${this._buttonId}"
				name="${ifDefined(this.name)}"
				type="${this._getType()}">
        <slot></slot>
			</button>
      ${this.disabled && this.disabledTooltip ? html`<d2l-tooltip for="${this._buttonId}">${this.disabledTooltip}</d2l-tooltip>` : ''}
		`;
  }
}
customElements.define('nova-button', NovaButton);
