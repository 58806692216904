import '@brightspace-ui/core/components/icons/icon.js';

import { css, html, LitElement, nothing } from 'lit';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../shared/models/activity/activity.js';
import { LocalizeNova } from '../../mixins/localize-nova.js';

class ActivityFeatures extends LocalizeNova(SkeletonMixin(LitElement)) {

  static get properties() {
    return {
      activity: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      offscreenStyles,
      css`
        .features {
          display: grid;
          grid-gap: 15px;
          grid-template-columns: 1fr 1fr 1fr;
        }

        .feature-text {
          padding-left: 8px;
        }

        d2l-icon.tier2-size {
          width: 24px;
        }

        @media (max-width: 767px) {
          .features {
            display: grid;
            grid-gap: 15px;
            grid-template-columns: 1fr 1fr;
          }

          .feature {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .feature-text {
            line-height: 1.2em;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = new Activity();
  }

  render() {
    const featureTemplate = (icon, title, text, iconClass = 'tier1-size') => {
      if (!text) return nothing;
      return html`
      <div class="feature d2l-skeletize">
        <span class="d2l-offscreen">${title}</span>
        <d2l-icon icon="${icon}" class="${iconClass}" title="${title}" aria-hidden="true"></d2l-icon>
        <span class="feature-text">${text}</span>
      </div>
      `;
    };
    const translatedDeliveryType = this.activity.isLocationBased
      ? this.localize('activity.delivery.inPerson')
      : this.activity.getTranslatedValue('delivery');

    return html`
      <div class="features">
        ${featureTemplate('tier2:time', this.localize('activity-features.timeCommitment'), this.activity.getTranslatedValue('weeklyTimeCommitment'))}
        ${featureTemplate('tier2:self-assessment', this.localize('activity-features.format'), this.activity.getTranslatedValue('format'))}
        ${featureTemplate('tier2:browser', this.localize('activity-features.delivery'), translatedDeliveryType)}

      ${this.activity.type === 'program' ? html`
        ${featureTemplate('tier2:grade', this.localize('activity-features.certificateType'), this.activity.getTranslatedValue('certificateType'))}
        ${featureTemplate('tier2:calendar', this.localize('activity-features.duration'), this.activity.getTranslatedValue('duration'))}
        ${featureTemplate('tier1:tile-view', this.localize('activity-features.coursesRequired'), this.localize('activity-features.numberOfCourses', { numberOfCourses: this.activity.coursesRequired || 0 }), 'tier2-size')}
      ` : nothing }
      </div>
    `;
  }
}

window.customElements.define('activity-features', ActivityFeatures);
