/**
 * Contains methods to filter/sort activities by their properties
 */
class ActivitiesHelper {

  /** @typedef {Object} skillMap
  * @property {string} id - Unique alphanum id of the skill
  * @property {string} name - Name of skill as provided by skills third party service
  * @property {number} frequency - Unique activities that have this skill listed
  */

  /**
   * Provided a list of activities, extract all skills and their frequency from activity entities
   * No sort order - order is as encountered via activity scrape
   *
   * @param {Array.<Object>} activities
   * @returns {skillMap}
   */
  static extractSkillCounts(activities) {
    const skillMap = {};
    activities.forEach(activity => {
      activity.skills.forEach(skill => {
        if (skillMap[skill.id]) {
          skillMap[skill.id].count++;
        } else {
          skillMap[skill.id] = { ...skill, count: 1 };
        }
      });
    });
    return skillMap;
  }

  /**
   * Returns a map of skills with the number of courses and programs that have that skill
   * @param activities -- array of modeled activities
   * @returns {{}}
   */
  static extractSkillsMap(activities) {
    const skills = {};
    for (const activity of activities) {
      for (const skill of activity.visibleSkills) {
        if (!skills[skill.id]) {
          skills[skill.id] = { ...skill, courseCount: 0, programCount: 0 };
        }
        if (activity.type === 'program') {
          skills[skill.id].programCount++;
        } else if (activity.type === 'course') {
          skills[skill.id].courseCount++;
        }
      }
    }
    return skills;
  }

  /**
   * Get an array of all skills from activities. A skill object will be included for each activity(there may be duplicates with different activity info)
   * @param activities
   * @returns {*[]}
   */
  static extractSkills(activities) {
    const skills = [];
    for (const activity of activities) {
      for (const skill of activity.skills) {
        skills.push({ ...skill, activityId: activity.id, activityName: activity.title, activityType: activity.type });
      }
    }
    return skills;
  }

  /**
   * Sort extractedSkillMap by frequency of skills (number of activities with unique skill)
   *
   * @param {Array.<Object>} activities
   * @returns {skillMap}
   */
  static extractSkillsFreqSort(activities) {
    const skillMap = this.extractSkillCounts(activities);
    return Object.values(skillMap).sort((a, b) => {
      const countDiff = b.count - a.count;
      return countDiff === 0 ? a.name.localeCompare(b.name) : countDiff;
    });
  }

  /**
   * Given a list of dates, return the nearest future date as an ISO Date String ending in Z.
   * If no future date is found, returns undefined.
   *
   * @param {Array.<string>} arrayOfDates An array of strings representing dates in ISO format.
   * @returns {string|undefined} The next session date represented as an ISO string ending in Z, or undefined if no future date is available.
   */
  static getNextSessionDateAsString(arrayOfDates = []) {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const isoToday = today.toISOString();
    const dateArray = arrayOfDates.map(date => (new Date(date)).toISOString());
    const sortedArray = dateArray.sort();
    for (let i = 0; i < sortedArray.length; i++) {
      if (sortedArray[i] >= isoToday) {
        return sortedArray[i];
      }
    }
  }

}

export default ActivitiesHelper;
