import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class AccountConnectionModelSchema extends NovaSchema {

  constructor() {
    const attributes = {
      userGuid: new SchemaAttribute(),
      providerId: new SchemaAttribute(),
      accountConnectionEmail: new SchemaAttribute(),
    };
    super('accountConnection', attributes);
  }
}

export const AccountConnectionSchema = new AccountConnectionModelSchema();
