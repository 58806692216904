
import { css, LitElement } from 'lit';
import { TagListItemMixin } from '@brightspace-ui/core/components/tag-list/tag-list-item-mixin.js';

class NovaTagListItem extends TagListItemMixin(LitElement) {
  static get properties() {
    return {
      text: { type: String },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .tag-list-item-container {
          color: #004685;
          background-color: #c9e8fa;
          border: none;
          border-radius: 18px;
          box-shadow: unset;
          cursor: unset;
        }

        :host(:hover) .tag-list-item-container,
        :host(:focus-within) .tag-list-item-container {
          background-color: #c9e8fa;
        }

        :host(:hover) .tag-list-item-container {
          box-shadow: none;
        }

        .tag-list-item-content {
          padding: 6px 12.5px;
          font-weight: 400;
          font-size: 0.7rem;
          line-height: 1rem;
        }

        @media (max-width: 615px) {
          .tag-list-item-content {
            font-size: 0.6rem;
            line-height: 0.9rem;
          }
        }
`,
    ];
  }

  render() {
    return this._renderTag(this.text, {
      description: this.description,
      hasTruncationTooltip: true,
      plainText: this.text,
    });
  }
}

customElements.define('nova-tag-list-item', NovaTagListItem);
