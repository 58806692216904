import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/expand-collapse/expand-collapse-content.js';
import '@brightspace-ui/core/components/focus-trap/focus-trap.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/menu/menu-item-link.js';
import '@brightspace-ui-labs/navigation/d2l-navigation.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-main-header.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-dropdown-button-custom.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-link-icon.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-separator.js';

import './shared/components/users/profile-image.js';
import './app-link.js';

import { allowBodyScroll, preventBodyScroll } from '@brightspace-ui/core/components/backdrop/backdrop.js';
import { css, html, LitElement, nothing } from 'lit';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from './shared/mixins/localize-nova.js';
import { spaNav } from '../shared/methods.js';
// TODO: Remove spaNav from codebase

import { ALL_LANG_KEYS, ALL_LANGUAGES, SUPPORTED_LANG_KEYS } from '../shared/l10n/localize.js';

class AppHeader extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      _dialogActive: { type: Boolean, attribute: '_dialog_active', reflect: true },
      _imageUrl: { type: String },
      _isLanguageChangeOpened: { type: Boolean, attribute: false },
      _languageKeys: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      linkStyles,
      offscreenStyles,
      css`
        :host {
          --mobile-menu-close-size: 42px;
        }

        .header-button-text {
          flex: 0 1 auto;
          width: 100%;
          min-width: 40px;
          max-width: 200px;
          margin-right: 10px;
          margin-left: 10px;
          overflow: hidden;
          font-size: 0.7rem;
          line-height: 1.6;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .mobile-switcher-selected {
          color: var(--d2l-color-celestine);
        }

        .mobile-switcher-clicked {
          font-weight: bold;
        }

        .navigation-element {
          height: 100%;
        }

        .nav-link {
          margin: 0 10px 5px;
          font-size: 0.7rem;
        }

        .tenant-logo {
          height: 40px;
        }

        .profile-dialog-button {
          display: none;
        }

        .fullscreen-nav-container {
          position: absolute;
          top: 4px;  /* Don't overlap brand color top band */
          z-index: 10;
          width: 100%;
          height: calc(100% - 4px);
          overflow-x: hidden;
          scrollbar-width: none;  /* Moz scrollbar prevention */
        }

        .fullscreen-nav-container::-webkit-scrollbar {
          display: none;
        }

        :host(:not([_dialog_active])) .fullscreen-nav-container {
          visibility: hidden;
          transition: visibility 0s 0.3s;
        }

        .dialog-container {
          position: absolute;
          right: 0;
          z-index: 11;
          width: 70%;
          max-width: 400px;
          height: 100%;
          background-color: var(--d2l-wave-white);
          transform: translateX(100%);
          transition: transform 0.3s;
        }

        .dialog-slide-in {
          transform: translateX(0);
        }

        d2l-focus-trap {
          display: block;
        }

        .dialog-escape-container {
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: var(--d2l-color-ferrite);
          opacity: 0.7;
        }

        .user-profile-container {
          display: flex;
          align-items: center;
          padding: 1rem 0.4rem 1rem 1rem;
        }

        .dialog-profile {
          flex: 1 1 auto;
          align-self: flex-start;
          box-sizing: border-box;
          max-width: calc(100% - var(--mobile-menu-close-size));
        }

        .dialog-exit-button-container {
          flex: 0 0 var(--mobile-menu-close-size);
          align-self: flex-end;
        }

        .dialog-language-item-text {
          padding-left: 2rem;
        }

        .dialog-list-item-text {
          padding-right: 0.5rem;
          padding-left: 1rem;
        }

        .dialog-navigation-list {
          background-color: var(--d2l-wave-white);
        }

        .list-action {
          align-self: center;
          margin-left: auto;
          padding-right: 0.5rem;
        }

        .dialog-change-language {
          cursor: pointer;
        }

        .change-language-content {
          width: 100%;
        }

        .change-language-header {
          display: flex;
          align-items: center;
        }

        @media (max-width: 1024px) and (min-width: 767px) {
          .header-button-text {
            display: none;
          }
        }

        @media (max-width: 767px) {
          d2l-navigation-link-icon,
          d2l-dropdown,
          d2l-navigation-separator,
          .profile-dropdown-menu {
            display: none;
          }
          .profile-dialog-button {
            display: initial;
          }
        }

        @media (prefers-reduced-motion: reduce) {
          :host(:not([_dialog_active])) .fullscreen-nav-container,
          .dialog-container {
            transition: none;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._dialogActive = false;
    this._isLanguageChangeOpened = false;
    this._languageKeys = [];
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._toBrightspaceURL = this._toBrightspaceURL.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');

    if (this.session.hasFeature('showUnApprovedLanguages')) {
      this._languageKeys = ALL_LANG_KEYS;
    }
    else {
      this._languageKeys = SUPPORTED_LANG_KEYS;
    }

    window.addEventListener('resize', this._handleWindowResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this._handleWindowResize);
  }

  async firstUpdated() {
    if (this.session.tenantId) {
      const tenant = await this.client.fetchTenant(this.session.tenantId);
      this._imageUrl = tenant.imageUrl || 'assets/img/d2l-wave.svg';
      this.tenantName = tenant.name;
    }
  }

  render() {
    if (!this.session.loggedIn) return nothing;
    return html`
      <header>
        <d2l-navigation>
          <d2l-navigation-main-header>
            <div slot="left" class="d2l-navigation-header-left">
              ${this._tenantLogoTemplate}
            </div>
            <div slot="right" class="d2l-navigation-header-right">
              ${this._navigationLinksTemplate}
              <d2l-navigation-separator></d2l-navigation-separator>
              ${this._navigationDropdownTemplate}
              <profile-image class="profile-dialog-button" meta-attribute="none" .user=${this.session.user} @click=${this._handleDialogOpenClick}></profile-image>
            </div>
          </d2l-navigation-main-header>
        </d2l-navigation>
        ${this._navigationDialogTemplate}
      </header>
    `;
  }

  logout(e) {
    e.preventDefault();
    const logoutEvent = new CustomEvent('nova-logout', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(logoutEvent);
  }

  _toBrightspaceURL() {
    window.open(this.session.tenant.brightspaceURL, '_blank');
  }

  async setLang(e) {
    await this.client.setUserSetting('language', e.target.getAttribute('data-language'));
    window.location.reload();
  }

  get _changeLanguageIcon() {
    return this._isLanguageChangeOpened ? 'tier1:chevron-down' : 'tier1:chevron-right';
  }

  get _isAdmin() {
    return this.session?.user?.tenantType === 'admin';
  }

  get _isProvider() {
    return this.session?.user?.tenantType === 'provider';
  }

  get _isShowcase() {
    // return this.session?.user?.tenantDomain === 'dundermifflin.local.dev.brightspace.com';
    return this.session?.tenant?.hasFeature('marketing');
  }

  get _isSponsor() {
    return this.session?.user.hasEntitlement?.('sponsor');
  }

  get _isWatcher() {
    return this.session?.user.hasEntitlement?.('watcher');
  }

  get _navigationDialogTemplate() {
    return html`
      <div class="fullscreen-nav-container">
        <div class="dialog-container ${this._dialogActive ? 'dialog-slide-in' : ''}">
          <d2l-focus-trap ?trap=${this._dialogActive}>
            <div class="user-profile-container">
              <profile-image
                class="dialog-profile"
                metaAttribute="none"
                mobile-format
                .user=${this.session.user}>
              </profile-image>
              <span class="dialog-exit-button-container">
                <d2l-button-icon
                  @click=${this._handleDialogCloseClick}
                  class="close-nav-button"
                  icon="tier1:close-default"
                  text=${this.localize('app-header.navigation.exit')}>
                </d2l-button-icon>
              </span>
            </div>
            <d2l-list class="dialog-navigation-list">
              ${this.session.tenant.hasTag('brightspaceInstance') ? html`
              <d2l-list-item
                class="nav-list-item dialog-brightspace"
                href='javascript:void(0);'
                @d2l-list-item-link-click=${this._toBrightspaceURL}
                >
                <span class="dialog-list-item-text dialog-brightspace-text">${this.session.tenant.brightspaceName}</span>
                <d2l-icon class="list-action" icon="tier1:link"></d2l-icon>
              </d2l-list-item>
              ` : nothing}
              <d2l-list-item class="nav-list-item dialog-home" id="home-dialog" href='/'>
                <span class="dialog-list-item-text dialog-home-text">${this.localize('app-header.home')}</span>
                <d2l-icon class="list-action" icon="tier1:home"></d2l-icon>
              </d2l-list-item>
              <d2l-list-item class="nav-list-item dialog-programs" href='/activities'>
                <span class="dialog-list-item-text dialog-programs-text">${this.localize('app-header.programs')}</span>
                <d2l-icon class="list-action" icon="tier1:tile-view"></d2l-icon>
              </d2l-list-item>
              <d2l-list-item class="nav-list-item dialog-requests" href='/requests'>
                <span class="dialog-list-item-text dialog-requests-text">${this.localize('app-header.requests')}</span>
                <d2l-icon class="list-action" icon="tier1:checklist"></d2l-icon>
              </d2l-list-item>
              ${this._showAnalytics ? html`
              <d2l-list-item class="nav-list-item dialog-analytics" href='/analytics'>
                <span class="dialog-list-item-text dialog-analytics-text">${this.localize('app-header.analytics')}</span>
                <d2l-icon class="list-action" icon="tier1:reports"></d2l-icon>
              </d2l-list-item>
              ` : nothing}
              ${ this._showResources ? html`
              <d2l-list-item icon="tier1:help" class="nav-list-item dialog-resources" href='https://lighthouse.wave.d2l.com/'>
                <span class="dialog-list-item-text dialog-resources-text">${this.localize('app-header.resources')}</span>
                <d2l-icon class="list-action" icon="tier1:help"></d2l-icon></d2l-list-item>
              ` : nothing}
              <d2l-list-item class="nav-list-item dialog-change-language" @click=${this._handleLanguageClick}>
                <div class="change-language-content">
                  <div class="change-language-header">
                    <span class="dialog-list-item-text dialog-change-language-text ${this._isLanguageChangeOpened ? 'mobile-switcher-selected' : ''}">${this.localize('app-header.changeLanguage')}</span>
                    <d2l-icon icon="${this._changeLanguageIcon}" class="${this._isLanguageChangeOpened ? 'mobile-switcher-selected' : ''}"></d2l-icon>
                    <d2l-icon class="list-action" icon="tier1:browser"></d2l-icon>
                  </div>
                  <d2l-expand-collapse-content id="dialog-change-language-accordion" ?expanded=${this._isLanguageChangeOpened}>
                    ${repeat(this._languageKeys, key => key, key => html`
                      <p class="dialog-language-item-text" data-language="${key}" @click="${this._handleLanguageChangeClick}">${ALL_LANGUAGES[key]}</p>
                    `)}
                  </d2l-expand-collapse-content>
                </div>
              </d2l-list-item>
              ${this._isAdmin ? html`
                <d2l-list-item class="nav-list-item dialog-admin" href='/admin'>
                  <span class="dialog-list-item-text dialog-admin-text">${this.localize('general.admin')}</span>
                  <d2l-icon class="list-action" icon="tier1:access-special"></d2l-icon>
                </d2l-list-item>` : nothing}
                ${!this._isShowcase ? html`
                <d2l-list-item class="nav-list-item dialog-logout" @click=${this.logout} href='/'>
                <span class="dialog-list-item-text dialog-logout-text">${this.localize('app-header.logout')}</span>
                <d2l-icon class="list-action" icon="tier1:undo"></d2l-icon>
              </d2l-list-item>
              ` : nothing}
            </d2l-list>
          </d2l-focus-trap>
        </div>
        <div @click=${this._handleDialogCloseClick} class="dialog-escape-container" tabindex="-1">
        </div>
      </div>
    `;
  }
  get _navigationDropdownTemplate() {
    return html`
      <d2l-navigation-dropdown-button-custom class="profile-dropdown-menu">
        <profile-image slot="opener" meta-attribute="none" .user=${this.session.user}></profile-image>
        <span class="d2l-offscreen" slot="opener">${this.session?.user.getDisplayName?.()}</span>
        <d2l-dropdown-menu id="dropdown" max-width="210">
          <d2l-menu label="Profile">
            ${this._isAdmin ? html`
            <d2l-menu-item text=${this.localize('general.admin')} @click=${spaNav(this.navigate, '/admin')}></d2l-menu-item>
            ` : nothing}
            ${this.session.tenant.hasTag('brightspaceInstance') ? html`
            <d2l-menu-item-link
              text=${this.session.tenant.brightspaceName}
              href=${this.session.tenant.brightspaceURL}
              target="_blank"
              ></d2l-menu-item-link>
            ` : nothing}
            <d2l-menu-item text=${this.localize('app-header.changeLanguage')} >
              <d2l-menu>
                ${repeat(this._languageKeys, key => key, key => html`
                  <d2l-menu-item text=${ALL_LANGUAGES[key]} data-language="${key}" @click=${this.setLang}></d2l-menu-item>
                `)}
              </d2l-menu>
            </d2l-menu-item>
            ${!this._isShowcase ? html`
            <d2l-menu-item text=${this.localize('app-header.logout')} @click=${this.logout}></d2l-menu-item>
            ` : nothing}

          </d2l-menu>
        </d2l-dropdown-menu>
      </d2l-navigation-dropdown-button-custom>
    `;
  }

  get _navigationLinksTemplate() {
    return html`
      <d2l-navigation-link-icon id="home" icon="tier2:home" text=${this.localize('app-header.home')} class="nav-link navigation-element" href='/'></d2l-navigation-link-icon>
      <d2l-navigation-link-icon id="activities" icon="tier2:classes" text=${this.localize('app-header.programs')} class="nav-link navigation-element" href='/activities'></d2l-navigation-link-icon>
      <d2l-navigation-link-icon id="requests" icon="tier2:checklist" text=${this.localize('app-header.requests')} class="nav-link navigation-element" href='/requests'></d2l-navigation-link-icon>

      ${this._showAnalytics ? html`
        <d2l-navigation-link-icon id="analytics" icon="tier2:reporting" text=${this.localize('app-header.analytics')} class="nav-link navigation-element" href='/analytics'></d2l-navigation-link-icon>
      ` : nothing}
      ${ this._showResources ? html`
        <d2l-navigation-link-icon id="resources" icon="tier2:help" text=${this.localize('app-header.resources')} class="nav-link navigation-element" href='https://lighthouse.wave.d2l.com/'></d2l-navigation-link-icon>` : nothing}
    `;
  }

  get _showAnalytics() {
    return [
      this._isAdmin,
      this._isProvider,
      this._isSponsor,
      this._isWatcher,
    ].some(arg => arg);
  }

  get _showResources() {
    return this.session?.tenant.hasFeature('freshDeskResources') &&
      (this._isSponsor || this._isAdmin);
  }

  get _tenantLogoTemplate() {
    const imageAltText = this.localize('app-header.logo.altText', { tenantName: this.session.tenant.name });
    const linkAriaLabel = `${imageAltText}. ${this.localize('app-header.logo.ariaLabel')}`;
    return this._imageUrl
      ? html`
        <a class="tenant-logo" href="/" focus-styles .aria-label=${linkAriaLabel}>
          <img height="40px" src="${this._imageUrl}" alt=${imageAltText}>
        </a>`
      : nothing;
  }

  _handleDialogCloseClick() {
    this._dialogActive = false;
    allowBodyScroll(this._bodyScrollKey);
    this._bodyScrollKey = null;
  }

  _handleDialogOpenClick() {
    this._dialogActive = true;
    this._bodyScrollKey = preventBodyScroll();
    this.shadowRoot.getElementById('home-dialog').focus();
  }

  _handleLanguageChangeClick(e) {
    e.target.classList.add('mobile-switcher-selected', 'mobile-switcher-clicked');
    e.stopPropagation();
    this.setLang(e);
  }

  _handleLanguageClick() {
    this._isLanguageChangeOpened = !this._isLanguageChangeOpened;
  }

  _handleWindowResize() {
    const navLinks = this.shadowRoot.querySelectorAll('.nav-link');
    if (window.innerWidth >= 767 && window.innerWidth <= 1024) {
      navLinks.forEach(link => {
        link.setAttribute('text-hidden', true);
      });
    } else {
      navLinks.forEach(link => {
        link.removeAttribute('text-hidden');
      });
    }
  }
}

window.customElements.define('app-header', AppHeader);
