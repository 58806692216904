import '../../components/tooltip/nova-tooltip.js';

import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

class DetailList extends LitElement {

  static get properties() {
    return {
      data: { type: Array, reflect: false },
      keyAligned: { type: Boolean, attribute: 'key-aligned' },
      dialogSpacing: { type: Boolean, attribute: 'dialog-spacing' },
      margin: { type: Number, attribute: 'margin', default: '2rem' },
      noSeparator: { type: Boolean, attribute: 'no-sep', default: false },
    };
  }

  static get styles() {
    return [
      css`
        :host([key-aligned]) {
          display: grid;
          grid-column-gap: 10px;
          grid-template-columns: auto 1fr;
        }

        :host([dialog-spacing]) {
          display: grid;
          grid-column-gap: 35px;
          grid-template-columns: auto 1fr;
        }

        .detail-list-item {
          display: grid;
          grid-template-columns: 250px auto;
          margin-bottom: var(--detail-list-spacing, 0);
        }

        .detail-list-item:last-of-type {
          margin-bottom: 0;
        }

        .detail-list-item > .item-text {
          overflow: auto;
          overflow-wrap: break-word;
        }

        @media (max-width: 767px) {
          .detail-list-item {
            grid-template-columns: minmax(0, 1fr);
            margin-top: 10px;
          }
        }
`,
    ];
  }

  render() {
    return this.data?.length
      ? repeat(this.data, obj => this._templateBuilder(obj.key, obj.value, obj.opts))
      : nothing;
  }

  _ariaLabelFormatter(v, k) {
    if (typeof v === 'string') return v;

    if (k.includes('%')) return `${v.values[0]}%`;

    return v.values[0];
  }

  _templateBuilder(k, v, opts) {
    if (!k || !v) return nothing;
    if (opts?.tooltipText) {
      v = html`<nova-tooltip class="tooltip" .text="${v}">
        <span slot="tooltipText">${opts.tooltipText}</span>
      </nova-tooltip>`;
    }

    return this.keyAligned || this.dialogSpacing ? html`
      <span><b>${k}${this.noSeparator ? '' : ':'} </b></span>
      <span>${v}</span>
    ` : html`
      <div class="detail-list-item">
        <span><b>${k}${this.noSeparator ? '' : ':'} </b></span>
        <span aria-label="${this._ariaLabelFormatter(v, k)}" class="item-text">${v}</span>
      </div>
    `;
  }
}

window.customElements.define('detail-list', DetailList);
