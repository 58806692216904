/**
 * The abstract class for different model types.
 */
import { novaLocalize } from '../../l10n/localize.js';

/**
 * A class representing the configuration information for a model.
 */
export class SchemaAttribute {

  constructor(defaultValue) {
    this.defaultValue = defaultValue;
    this.formatters = [];
    this.allowEmptyTranslation = true;
  }

  addFormatter(func) {
    this.formatters.push(func);
    return this;
  }

  getDefaultValue(model) {
    return typeof this.defaultValue === 'function' ? this.defaultValue(model) : this.defaultValue;
  }

  setAllowEmptyTranslation(allow) {
    this.allowEmptyTranslation = allow;
    return this;
  }

  setPossibleValues(possibleValues) {
    this.possibleValues = possibleValues;
    return this;
  }

  /**
   * @param {class} type - class to use for instantiating value
   * @param {boolean} isArrayType - whether the type applies to an array attribute
   */
  setType(type, isArrayType = false) {
    this.Type = type;
    this.isArrayType = isArrayType;
    return this;
  }

}

/**
 * The generic model schema class. Implements functions all schemas support
 *
 * TODO: Add the ability to create an AJV schema for validation purposes.
 */
export class NovaSchema {

  constructor(type, attributes = [], modelAttributes = {}) {
    this.lang = 'en';
    this.type = type;
    this.attributes = attributes;
    this._modelAttributes = modelAttributes;
  }

  get attributes() {
    return this._attributes;
  }

  get attributeNames() {
    return Object.keys(this.attributes);
  }

  set attributes(attributes) {
    this._attributes = attributes;
  }

  // TODO: should we add modelAttributes to getAttribute and other functions?
  // These are set to be removed before being stored in the db in repo.js (toRecord() will call toJSON(false) -> which wipes the modelAttibutes)
  get modelAttributes() {
    return this._modelAttributes;
  }

  set modelAttributes(mAttributes) {
    this._modelAttributes = mAttributes;
  }

  /**
   * Gets the attribute with the given name. Returns undefined if the attribute doesn't exist.
   * @param attribute
   * @returns {*}
   */
  getAttribute(attribute) {
    return this.attributes[attribute];
  }

  setAllowExtraAttributes() {
    if (this.allowExtraAttributes) return;
    this.allowExtraAttributes = true;
    this.attributes.extraAttributes = new SchemaAttribute();
  }

  /**
   * Returns a list of possible values in the following format. Returns undefined if there are no possible values or the attribute doesn't exist.
   *
   * @example
   * {
   *   displayName: 'Some translated value', // Uses the same translation format as getTranslatedValue
   *   value: 'The value'.
   * }
   * @param attribute
   * @param language
   * @returns {undefined|*}
   */
  getPossibleValues(attribute, language) {
    if (!this.attributes[attribute]) return undefined;
    const possibleValues = this.attributes[attribute].possibleValues;
    if (!possibleValues) return undefined;
    return possibleValues.map(value => {
      return {
        displayName: this.getTranslatedValue(attribute, value, language),
        value,
      };
    });
  }

  /**
   * Returns the translated value for the attribute value. Assumes the translation key is of the format:
   * @example
   * `${this.schemaType}.${attribute}.${value}` -> activity.type.program
   *
   * @param attribute
   * @param value
   * @param language
   * @returns {string|*}
   */
  getTranslatedValue(attribute, value, language) {
    // TODO: this is a solution to enable us to have two "modelTypes" that map to the same lang terms
    // if we have more model schemas in the future that need to share lang terms, we should
    // come up with a better abstraction
    const schemaType = this.type === 'activityRelationship' || this.type === 'genericActivity' ? 'activity' : this.type;
    const translatedValue = novaLocalize(`${schemaType}.${attribute}.${value}`, {}, language);
    // If we allow empty translation - return the translated value no matter what
    const attr = this.getAttribute(attribute) || { allowEmptyTranslation: true };
    if (attr.allowEmptyTranslation) return translatedValue;
    // If we don't allow empty translation - return the value instead.
    return translatedValue || value;
  }

  /**
   * Returns true if all of the attributes passed in are valid.
   *
   * Ignores attributes that are not part of the schema
   *
   * @returns {undefined|*}
   * @param data
   */
  isValid(data) {
    const attributeNames = Object.keys(data);
    for (const attribute of attributeNames) {
      if (!this.attributes[attribute]) continue;
      const possibleValues = this.attributes[attribute].possibleValues;
      if (!possibleValues) continue;
      if (!possibleValues.some(val => val === data[attribute])) return false;
    }
    return true;
  }

}

