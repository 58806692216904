import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import { html, nothing } from 'lit';

import { formatCurrencyAsDecimalWithCode } from '../../../../../shared/helpers/number.js';
import { formatIsoDate } from '../../../../../shared/helpers/dateTime.js';
import { localizeStartDate } from '../../../../../shared/models/schema/activity/shared.js';
import { novaLocalize } from '../../../../../shared/l10n/localize.js';
import { PERSONAS } from '../../../../../shared/models/user-session.js';

const { EMPLOYER_EMPLOYEE, EMPLOYER_SPONSOR, EMPLOYER_WATCHER, PROVIDER_ADMIN, ADMIN_ADMIN } = PERSONAS;

const DEFAULT_STATUS_OPTIONS = [
  'pending',
  'approved',
  'declined',
  'registered',
  'completed',
  'failed',
  'cancelled',
  'withdrew',
];

const STRIPE_STATUS_OPTIONS = [
  'pending',
  'approved',
  'registrationSubmitted',
  'declined',
  'registered',
  'completed',
  'failed',
  'cancelled',
  'withdrew',
];

const _activityCellTemplate = application => {
  return {
    mainText: application.activity.title,
    subText: application.institution,
  };
};

const _approvedAmountTemplate = amount =>
  (amount ? html`
      <span>
        <span class="d2l-offscreen" id="am-label">${novaLocalize('application-table-row.approvedAmount')} <span aria-label="${amount}">${amount}</span></span>
        <span aria-hidden="true">
            ${amount}
        </span>
        <d2l-icon icon="tier1:check-circle" class="approved-check" aria-hidden="true"></d2l-icon>
      </span>
    ` : nothing);

const _idCellTemplate = application => {
  return {
    mainText: application.slug,
  };
};

const _isProvider = tenant => {
  return tenant?.type === 'provider';
};

const _employeeCellTemplate = application => {
  return {
    mainText: application.user.getDisplayName(),
    subText: application.user.title,
  };
};

/**
 * Given a langTermKey and number of days, form the localized subtext
 * for the submission date using the localizer
 */
const _localizedSubmittedSubText = langTermData => {
  if (!langTermData?.langTermKey || langTermData?.numDays === undefined)
    return '';

  const { langTermKey, numDays } = langTermData;
  return novaLocalize(langTermKey, { numDays });
};

const _paymentCellTemplate = application => {
  return {
    mainText: html`
      <span class="d2l-offscreen">${novaLocalize('application-table-row.paid')}</span>
      ${novaLocalize('application-table-row.paid')}
      <d2l-icon icon="tier1:check-circle" class="approved-check-align-text" aria-hidden="true"></d2l-icon>
    `,
    subText: formatCurrencyAsDecimalWithCode(application.postTaxActivityCost, application.providerCurrency),
  };
};

const _priceCellTemplate = application => {
  return {
    mainText: formatCurrencyAsDecimalWithCode(application.postTaxActivityCost, application.providerCurrency),
    subText: _approvedAmountTemplate(application.approvedAmountInProviderCurrency),
  };
};

const _setStatusOptions = (defaultOptions, isApprover) => {
  const options = [...defaultOptions];
  if (isApprover) {
    options.splice(1, 0, 'oneOfTwoApproved');
  }
  return options;
};

const _startDateCellTemplate = application => {
  return {
    mainText: application.nextSessionDateWhenPaid ? localizeStartDate(application.nextSessionDateWhenPaid) : application.activity.formattedStartDate,
  };
};

const _submittedDateCellTemplate = application => {
  const submittedDateSubText = _localizedSubmittedSubText(application.submittedTextLangTermData());
  return {
    mainText: formatIsoDate(application.applicationDate),
    subText: submittedDateSubText,
  };
};

const _tooltipTemplate = (application, forElemId) => {
  return html`
    <d2l-tooltip
      for="${forElemId}"
      show-truncated-only>
      ${novaLocalize(application.status.langKey)}
    </d2l-tooltip>
  `;
};

const _statusIndicatorCellTemplate = (application, tenant) => {
  const id = `rs-${application.slug}`;
  const stripeProviderAndPaidOnly =
    tenant.type === 'provider'
    && application.isPaidOnly;

  return {
    custom: html`
      ${_tooltipTemplate(application, id)}
      <div class="indicator-container" id="${id}">
        <d2l-status-indicator
          ?bold=${stripeProviderAndPaidOnly}
          ?is-one-of-two-approved=${application.status.label === 'oneOfTwoApproved'}
          state=${application.status.indicator.state}
          text=${novaLocalize(application.status.langKey)}
          tabindex="0">
        </d2l-status-indicator>
      </div>
    `,
  };
};

/**
 * Returns an array of cell template data which defines
 * what will show up in each application-table-row per role.
 */
const commonContent = (application, tenant) => {
  const paymentOrPriceCellTemplate = _isProvider(tenant)
    ? _paymentCellTemplate(application)
    : _priceCellTemplate(application);

  return [
    _idCellTemplate(application, tenant), // slug of application, shown as 'id' in front-end
    _employeeCellTemplate(application, tenant), // name and role of employee
    _activityCellTemplate(application, tenant), // name of activity and name of provider
    _submittedDateCellTemplate(application, tenant), // submission date with "X days ago" as subtext
    _startDateCellTemplate(application, tenant), // start date (no subtext)
    paymentOrPriceCellTemplate, // shows payment if viewing in provider tenant and using stripe, otherwise price
    _statusIndicatorCellTemplate(application, tenant), // application status indicator (registered, pending, etc...)
  ];

};

// Tenant is optional
const commonHeaders = tenant => {
  const headers = ['id', tenant.learnerTerminology, 'activity', 'submitted', 'nextSession', 'price', 'status'];
  // replace 'price' with 'payment' if tenant type is provider
  if (tenant && _isProvider(tenant)) {
    headers[headers.indexOf('price')] = 'payment';
  }
  return headers;
};

const templates = {
  [EMPLOYER_EMPLOYEE]: { // includes the employer manager persona
    headers: commonHeaders,
    content: commonContent,
    status: (stripeEnabled, isApprover) => (stripeEnabled ? _setStatusOptions(STRIPE_STATUS_OPTIONS, isApprover) : _setStatusOptions(DEFAULT_STATUS_OPTIONS, isApprover)),
  },
  [EMPLOYER_SPONSOR]: {
    headers: commonHeaders,
    content: commonContent,
    status: (stripeEnabled, isApprover) => (stripeEnabled ? _setStatusOptions(STRIPE_STATUS_OPTIONS, isApprover) : _setStatusOptions(DEFAULT_STATUS_OPTIONS, isApprover)),
  },
  [EMPLOYER_WATCHER]: {
    headers: commonHeaders,
    content: commonContent,
    status: stripeEnabled => (stripeEnabled ? STRIPE_STATUS_OPTIONS : DEFAULT_STATUS_OPTIONS),
  },
  [PROVIDER_ADMIN]: {
    headers: commonHeaders,
    content: commonContent,
    status: stripeEnabled => {
      if (stripeEnabled) {
        return ['registrationSubmitted', 'registered', 'completed', 'failed', 'cancelled', 'withdrew'];
      }
      return ['approved', 'registered', 'completed', 'failed', 'cancelled', 'withdrew'];
    },
  },
  [ADMIN_ADMIN]: {
    headers: commonHeaders,
    content: commonContent,
    status: stripeEnabled => (stripeEnabled ? STRIPE_STATUS_OPTIONS : DEFAULT_STATUS_OPTIONS),
  },
};

const getTableContent = (type, application, tenant) => {
  return templates[type]?.content(application, tenant) || [];
};

const getTableHeaders = (type, tenant) => {
  return templates[type]?.headers(tenant) || [];
};

export {
  getTableContent,
  getTableHeaders
};
