import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumbs.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel-summary-item.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/html-block/html-block.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/typography/typography.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui-labs/multi-select/attribute-picker.js';
import '@brightspace-ui-labs/multi-select/multi-select-input-text.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import DOMPurify from 'dompurify';
import { getDateTimeDescriptor } from '@brightspace-ui/intl/lib/dateTime.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../../../shared/components/activities/activity-features.js';
import '../../../shared/components/activities/activity-financial-details.js';
import '../../../shared/components/activities/activity-relationships.js';
import '../../../shared/components/general/in-place-dropdown.js';
import '../../../shared/components/activities/activity-skill-tagger.js';
import '../../components/general/suggest-activity.js';

import ActivitiesHelper from '../../../../shared/helpers/activities.js';
import Activity from '../../../../shared/models/activity/activity.js';
import { Application } from '../../../../shared/models/application/index.js';
import { formatIsoDate } from '../../../../shared/helpers/dateTime.js';
import { getStreamPath } from '../../../../shared/models/schema/activity/shared.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { novaCardStyles } from '../../../shared/styles/nova-card.js';
import Tenant from '../../../../shared/models/tenant/index.js';

import { MONTH_NAMES } from '../../../../shared/constants.js';

export function formatISODateInUserCalDescriptor(val) {
  return val ? formatIsoDate(val, false, 'short') : '';
}

export default class ViewActivity extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      _activity: { type: Object, attribute: false },
      _activityProvider: { type: Object, attribute: false },
      _savedToMyList: { type: Boolean, attribute: false },
      _tenant: { type: Object, attribute: false },
      _currentStartDateType: { type: String },
    };
  }

  static get styles() {
    return [
      super.styles,
      selectStyles,
      linkStyles,
      inputLabelStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      novaCardStyles,
      css`
        :host {
          display: block;
          min-height: 100vh;
        }

        d2l-button {
          width: fit-content;
          width: -moz-fit-content;
        }

        .calendar-icon {
          --d2l-icon-height: 0.8rem;
          --d2l-icon-width: 0.8rem;
          margin-right: 0.6rem;
          margin-left: 0.6rem;
        }

        .card-content .d2l-heading-1 {
          margin: 24px 0;
          line-height: 2rem;
        }

        .activity-header {
          height: 300px;
          background-image: var(--background);
          background-position: center;
          background-size: cover;
        }

        .activity-type {
          grid-column: none;
          width: fit-content;
          height: fit-content;
          height: -moz-fit-content;
        }

        .activity-status {
          display: grid;
          grid-template-columns: 3fr 1fr;
          margin-bottom: 24px;
        }

        .activity-myList {
          display: grid;
          grid-template-columns: 3fr 1fr;
          margin-bottom: 24px;
        }

        .myList-button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .myList-button-mobile {
          display: none;
        }

        .activity-content,
        .loading {
          display: grid;
          grid-column-gap: 50px;
          grid-template-rows: min-content min-content auto;
          grid-template-columns: 1.5fr 1fr;
          width: 1170px;
          margin: 0 auto;
          margin-top: -100px;
        }

        .html-block {
          margin-top: 0.5em;
        }

        .key-differentiator {
          font-weight: 600;
        }

        /*
        d2l-collapsible-panel {
          background-color: #fff;
        }
        */


        d2l-tag-list {
          display: block;
          margin: 15px 0;
        }

        .left-content-primary {
          grid-row: 1 / span 1;
          grid-column: 1 / span 1;
        }

        .left-content-secondary {
          grid-row: 2 / span 1;
          grid-column: 1 / span 1;
          /* addresses custom activity content overflowing */
          max-width: min(767px, calc(100vw - 18px * 2));
        }

        .right-content {
          display: grid;
          grid-row: 1 / span 3;
          grid-row-gap: 50px;
          grid-column: 2 / span 1;
          height: fit-content;
          height: -moz-fit-content;
        }

        .activity-overview,
        .requirements {
          padding: 0 20px;
        }

        .activity-overview > h2 {
          margin-bottom: 0;
        }

        .activity-overview > img {
          width: 100%;
        }

        .d2l-heading-3 {
          margin-top: 0;
          margin-bottom: 5px;
        }

        .select-wrapper {
          padding-bottom: 5%;
        }

        .date-select-wrapper {
          display: flex;
          padding-bottom: 5%;
        }

        .special-instructions {
          margin-top: 42px;
          margin-bottom: 24px;
          padding-top: 15px;
          border-top: 1px solid var(--d2l-color-mica);
        }

        .start-date {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }

        .start-date-unrequestable {
          margin-top: 20px;
        }

        .skill-tagger {
          display: block;
          margin-bottom: 3rem;
        }

        .start-date-icon {
          margin-top: -4px;
        }

        .start-date-text {
          padding: 0 6px 0 12px;
          font-weight: bold;
        }

        .suggest-form-input {
          padding-bottom: 1.2rem;
        }

        .request-approval-wrapper {
          display: grid;
          place-items: end;
        }

        .activity-footer {
          display: grid;
          grid-template-columns: auto auto;
        }

        .activity-link {
          font-size: 0.8rem;
        }

        @media (max-width: 1280px) {
          d2l-breadcrumbs {
            white-space: unset;
          }

          .activity-details {
            margin-bottom: 50px;
          }

          .activity-content {
            grid-template-columns: 1fr;
            width: unset;
            max-width: 767px;
          }

          .two-buttons {
            display: grid;
            row-gap: 10px;
          }

          .right-content {
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;
          }

          .left-content-secondary {
            grid-row-start: 3;
          }
        }

        @media (max-width: 767px) {
          .activity-content {
            padding: 0 18px;
          }

          .activity-footer {
            grid-template-columns: none;
            row-gap: 10px;
            justify-content: center;
          }

          .request-approval-wrapper {
            place-items: center;
          }

          .myList-button {
            display: none;
          }

          .myList-button-mobile {
            display: flex;
            justify-content: center;
            margin-top: 8px;
          }

          .non-requestable-course {
            margin-top: 20px;
          }
        }

        @media (max-width: 615px) {
          .activity-header {
            height: 180px;
          }

          .activity-content {
            grid-column-gap: 12px;
          }

          .activity-footer {
            grid-row-gap: 0.6rem;
            grid-template-columns: 1fr;
          }

          .activity-status {
            margin-bottom: 12px;
          }

          .activity-details {
            margin-bottom: 12px;
            border: 1px solid #e4e9f0;
            border-radius: 12px;
            box-shadow: 0 2px 10px #0000001a;
          }

          .activity-overview,
          .requirements {
            padding: 0;
          }

          .requirements > .d2l-heading-2 {
            margin-bottom: 0;
          }

          .card-content {
            padding: 18px;
          }

          .card-content img {
            max-width: 100%;
          }

          .card-content .d2l-heading-1 {
            margin: 6px 0 12px;
            font-weight: 900;
            font-size: 1rem;
            line-height: 1.2rem;
          }

          .card-footer {
            padding: 12px 18px 12px;
          }

          .right-content {
            grid-row-gap: 12px;
          }

          .start-date {
            margin: 0 auto;
          }

          .start-date-text {
            padding: 0 6px;
          }

          .non-requestable-course {
            margin-top: 12px;
          }

          d2l-button {
            width: 100%;
          }
        }

        .alert-with-button {
          margin-bottom: 18px;
        }
`,
    ];
  }

  constructor() {
    super();
    this._activity = new Activity();
    this._activityProvider = new Tenant();
    this._tenant = new Tenant();
    this._savedToMyList = false;
    this.tempActivitySkillsList = [];
    this._currentStartDateList = [];
  }

  get myListIcon() {
    return this._savedToMyList ? 'tier2:subscribe-filled' : 'tier2:subscribe-hollow';
  }

  get myListText() {
    return this._savedToMyList ? 'view-activity.removeFromMyList' : 'view-activity.addToMyList';
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  get _activityLoaded() {
    return !!this._activity.id;
  }

  render() {
    return html`
      <div class="activity-header"></div>
      <section class="activity-content">
        <div class="left-content-primary">
          ${this._activityDetailsTemplate}
        </div>
        <div class="left-content-secondary">
          ${this._activityOverviewTemplate}
          ${this._requirementsTemplate}
          ${this._specialInstructionsTemplate}
        </div>
        <div class="right-content">
          ${this._financialDetailsTemplate}
          <suggest-activity
            .activity="${this._activity}"
            suggestOrigin="viewActivity"
            context="found"
            disable-link>
          </suggest-activity>
          ${this._activityRelationshipsTemplate}
        </div>
      </section>
    `;
  }

  async updated(_changedProperties) {
    // If a course is clicked inside a program, the router doesn't reload. Instead it passes a new param value
    let paramChanged = false;
    for (const [propName] of _changedProperties) {
      if (propName === 'params') {
        paramChanged = true;
      }
    }
    if (paramChanged) await this.init();
  }

  async init() {
    if (!this.client || !this.session) return;
    this.skeleton = true;
    const [tenant, activity] = await Promise.all([
      this.client.fetchTenant(this.session.tenantId),
      this.client.fetchActivity(this.params.id),
    ]);

    const documentTitle = this.localize('view-activity.documentTitle.afterLoading', {
      activityTitle: activity.title,
    });
    this.client.setDocumentTitle(documentTitle);

    this._tenant = tenant;
    this._activity = activity;
    if (activity.startDate) {
      this._currentStartDateList = [...activity.startDate];
    }

    const urlParams = new URLSearchParams(window.location.search);
    const suggestId = urlParams.get('suggestId');

    if (suggestId) {
      if (this.session.tenant.hasFeature('marketing')) {
        // non-wave user going to a showcase view for a suggestion
        this.client.logEvent({
          eventType: 'activitySuggestionVisitedByNonWaveUser',
          activityId: this._activity.id,
          suggestId,
        });
      } else {
        this.client.logEvent({
          eventType: 'activitySuggestionVisited',
          activityId: this._activity.id,
          suggestId,
        });
      }
    }

    try {
      const existingApps = await this.client.getApplicationsForActivity(this._activity.id);
      this._existingApp = new Application(existingApps[0]);
    } catch (e) {
      if (e.status !== 404) {
        throw e;
      }
    }
    this._savedToMyList = this.session.user.getSetting('myList')?.includes(this._activity.id);
    this._currentStartDateType = this._activity.startDateType;

    const providerId = this._activity.provider;
    const [activityProvider, activityInstructions] = await Promise.all([
      this.client.fetchTenant(providerId),
      this.client.getCustomContent(providerId, 'activityInstructions'),
    ]);

    this._activityProvider = activityProvider;
    this._activityInstructions = activityInstructions?.isEnabled
      ? activityInstructions.value
      : nothing;

    this.style.setProperty('--background', `url(${this._activity.bannerImageUrl})`);
    this.skeleton = false;
    this._removeListSkills = [...this._activity.visibleSkills];
    this.client.logEvent({
      eventType: 'activityViewed',
      activityId: this._activity.id,
    });
  }

  get _activityDetailsFooterTemplate() {
    // TODO: this code was taken from the d2l-input-date component
    // There was no way of properly placing a date picker inside a dropdown so for now it is a normal
    // input styled like a date picker and validating that the input is the correct format.
    const shortDateFormat = (getDateTimeDescriptor().formats.dateFormats.short).toUpperCase();
    const inputTextWidth = 'calc( 133px + 0.75rem + 3px)'; // text and icon width + paddingRight + border width + 1
    const activityType = this._activity.type;

    const myListMobileTemplate = (extraClass = '') => {
      if (this._tenant.type === 'employer') {
        return html`
          <div class="myList-button-mobile ${extraClass}">
            <d2l-button-subtle
              @click=${this._updateMyList}
              icon=${this.myListIcon}
              text=${this.localize(this.myListText)}>
            </d2l-button-subtle>
          </div>
        `;
      }
      return nothing;
    };

    const courseNotRequestableTemplate = html`
      <div class="card-footer">
        <d2l-alert type="default">${this.localize('view-activity.activityMetadata.footer.notRequestableAlert')}</d2l-alert>
        ${myListMobileTemplate('non-requestable-course')}
      </div>`;

    const notificationMessage = this._activity.startDateType === 'comingSoon' || this._activity.formattedStartDate === this.localize('edit-activity.startDateType.comingSoon') ?
      this.localize('view-activity.activityMetadata.footer.notRequestableComingSoonAlert', { activityType })
      : this.localize('view-activity.activityMetadata.footer.unscheduledAlert', { activityType });

    const notScheduledTemplate = html`
      <div class="card-footer">
        <d2l-alert type="default" class="alert-with-button">${notificationMessage}</d2l-alert>
        <div class="activity-footer">
          ${this._startDateTemplate(shortDateFormat, inputTextWidth)}
          <div class="request-approval-wrapper">
            <d2l-button primary disabled @click=${this._applyActivity}>${this.localize('view-activity.activityMetadata.footer.requestApproval')}</d2l-button>
          </div>
        </div>
        ${myListMobileTemplate()}
      </div>`;

    const requestableTemplate = html`
      <div class="card-footer">
        ${this._activity.formattedStartDate === this.localize('activity.startDate.comingSoon') ? html`
        <d2l-alert type="default" class="alert-with-button">${this.localize('view-activity.activityMetadata.footer.notRequestableComingSoonAlert', { activityType })}</d2l-alert>
        ` : nothing}
        <div class="activity-footer">
          ${this._startDateTemplate(shortDateFormat, inputTextWidth)}
          <div class="request-approval-wrapper">
            <d2l-button primary ?disabled="${!this._activity.hasTag('active') || this._activity.formattedStartDate === this.localize('activity.startDate.comingSoon')}" @click=${this._applyActivity}>${this.localize('view-activity.activityMetadata.footer.requestApproval')}</d2l-button>
          </div>
          ${myListMobileTemplate()}
        </div>
      </div>`;

    const notRequestableComingSoonTemplate = html`<div class="card-footer">
      <d2l-alert type="default">${this.localize('view-activity.activityMetadata.footer.notRequestableComingSoonAlert', { activityType })}</d2l-alert>
      ${myListMobileTemplate('non-requestable-course')}
    </div>`;

    const activeChildren = this._activity?.children?.filter(child => child.hasTag('active')) || [];

    const programNotRequestableTemplate = html`
      <div class="card-footer ${this._tenant.type === 'employer' ? 'two-buttons' : ''}">
        <div class="request-approval-wrapper">
          <d2l-button primary ?disabled="${!this._activity.hasTag('active') || activeChildren.length === 0}" @click=${this._applyActivity}>${this.localize('view-activity.activityMetadata.footer.requestApproval')}</d2l-button>
        </div>
        ${myListMobileTemplate()}
      </div>`;

    if (this._notRequestableComingSoon()) return notRequestableComingSoonTemplate;
    if (this._courseNotRequestable()) return courseNotRequestableTemplate;
    if (this._programNotRequestable()) return programNotRequestableTemplate;
    if (this._isRequestable) return requestableTemplate;

    return notScheduledTemplate;
  }

  get _activityDetailsTemplate() {
    const statusIndicator = this._activity.hasTag('active') ?
      html`<d2l-status-indicator class="activity-type" state="none" text="${this.localize(`activity.type.${this._activity.type}`)}" bold></d2l-status-indicator>`
      : html`<d2l-status-indicator class="activity-type" state="alert" text="${this.localize('view-activity.inactive')}" bold></d2l-status-indicator>`;
    return html`
      <div class="activity-details card d2l-skeletize">
        <div class="card-content">
          <div class="activity-status">
            ${this._breadcrumbsTemplate}
            ${statusIndicator}
          </div>
          ${this._tenant.type === 'employer' ? html`
            <div class="activity-myList">
              <img alt="${this._activityProvider.name}" height="55px" src="${ifDefined(this._activityProvider.imageUrl)}">
              <div class="myList-button">
                <d2l-button-subtle
                  @click=${this._updateMyList}
                  icon=${this.myListIcon}
                  text=${this.localize(this.myListText)}>
                </d2l-button-subtle>
              </div>
            </div>` : html`
            <img alt="${this._activityProvider.name}" height="55px" src="${ifDefined(this._activityProvider.imageUrl)}">`}
          <h1 class="d2l-heading-1">${this._activity.title}</h1>
          <activity-features .activity=${this._activity}></activity-features>
        </div>
        ${this._activityDetailsFooterTemplate}
      </div>
    `;
  }

  get _skillTagger() {
    return this.shadowRoot.getElementById('skillTagger-dialog');
  }

  toggleDialog() {
    if (!this._canEdit()) return;
    if (this._skillTagger.opened === true && this._canEdit()) {
      this._skillTagger.opened = false;
      return;
    }
    this._skillTagger.opened = true;
    this._skillTagger.resize();
    this.requestUpdate();
  }

  _skillDialogClose() {
    this.tempActivitySkillsList = [...this._activity.skills];
  }

  _handleSkillsChanged(e) {
    this.tempActivitySkillsList = [...e.detail];
    this._skillTagger.resize();
  }

  skillsDialog() {
    const hasSkills = this._activityLoaded && this._canEdit();
    return hasSkills ?
      html`
        <d2l-dialog id="skillTagger-dialog"
        title-text="Edit Skills"
        @d2l-dialog-before-close=${this._skillDialogClose}>
          <activity-skill-tagger
            class="skill-tagger"
            id="skill-picker"
            .activity=${this._activity}
            @skills-changed=${this._handleSkillsChanged}>
          </activity-skill-tagger>
          <d2l-button slot="footer" @click=${this._saveSkills} primary data-dialog-action="done">${this.localize('general.button-text.save')}</d2l-button>
          <d2l-button slot="footer" data-dialog-action="Cancel">${this.localize('general.button-text.cancel')}</d2l-button>
        </d2l-dialog>
      ` : nothing;
  }

  _showEditSkills() {
    if (this._canEdit() === true) {
      return html`
        <d2l-button-subtle
          aria-haspopup="true"
          icon="tier1:edit"
          @click=${this.toggleDialog}
          text="${this.localize('view-activity.editSkills.button')}">
        </d2l-button-subtle>
      `;
    }
    return nothing;
  }

  skillList(skills) {
    return html`
      <d2l-tag-list description="${this.localize('view-activity.activityOverview.skillsDescription')}">
        ${skills.map(skill => html`<d2l-tag-list-item text=${skill.name}></d2l-tag-list-item>`)}
      </d2l-tag-list>
    `;
  }

  get _activityOverviewTemplate() {
    const skills = this._activity.visibleSkills;
    const activityLink = this.localize('view-application.activityOverview.activityLink', {
      providerName: this._activityProvider.name,
      activityTitle: this._activity.title,
      link: this.l10nTags.d2lLink(this._activity.registration),
    });
    return html`
      <div class="activity-overview">
        <p class="activity-link d2l-skeletize">
          ${activityLink} ${this._activityInstructions}
        </p>
        <h2 class="d2l-heading-2 d2l-skeletize">${this.localize('view-activity.activityOverview.header')}</h2>
        ${skills?.length > 0 ? this.skillList(skills) : nothing}
        ${this._showEditSkills()}
        ${this.skillsDialog(skills)}
        <div class="key-differentiator d2l-skeletize">${this._activity.keyDifferentiator}</div>
        <d2l-html-block class="html-block d2l-skeletize" html="${DOMPurify.sanitize(this._activity.description)}"></d2l-html-block>
      </div>
    `;
  }

  get _activityRelationshipsTemplate() {
    return html`
      <activity-relationships
        .activity=${this._activity}
        .providerCurrency=${this._activityProvider.operatingCurrency}
        .hideRequestability=${this._courseNotRequestable()}>
      </activity-relationships>
    `;
  }

  get _breadcrumbsTemplate() {
    if (this._activity.category?.isEmpty()) return nothing;

    const breadcrumbTag = this._activity.primaryCategoryTag;
    const suffix = getStreamPath(this._activity.primaryCategoryTag.value) || '';
    const path = `/activities/skill-stream/${suffix}`;

    return html`
      <d2l-breadcrumbs>
        <d2l-breadcrumb
          text=${this.localize('view-activity-category.programs')}
          href="/activities">
        </d2l-breadcrumb>
        <d2l-breadcrumb
          text="${breadcrumbTag.displayName}"
          href="${path}">
        </d2l-breadcrumb>
      </d2l-breadcrumbs>`;
  }

  get _financialDetailsTemplate() {
    return !this._courseNotRequestable()
      ? html`
          <financial-details
            .activity=${this._activity}
            .userGuid=${this.session.userGuid}
            .tenantId=${this.session.tenantId}
            .editable=${this._canEdit()}>
          </financial-details>`
      : nothing;
  }

  get _isRequestable() {
    return this._activity.type !== 'course' || (!this._courseNotRequestable() && this._activity.isScheduled() && this._activity.formattedStartDate !== this.localize('edit-activity.startDateType.comingSoon'));
  }

  get _requirementsTemplate() {
    return this._activity.prereq && this._activity.prereq !== 'None'
      ? html`
        <section class="requirements d2l-skeletize">
          <h2 class="d2l-heading-2">${this.localize('view-activity.requirements.header')}</h2>
          <p>${this._activity.prereq}</p>
        </section>`
      : nothing;
  }

  get _specialInstructionsTemplate() {
    return this._activity?.specialInstructions
      ? html`
        <p class="special-instructions d2l-skeletize">
          ${this._activity.specialInstructions}
        </p>`
      : nothing;
  }

  _applyActivity() {
    if (this._existingApp?.applicationDate && !this._existingApp.canReapply) {
      this.navigate(`/requests/${this._existingApp.uuid}`);
    } else {
      if (this.session.isShowcase) {
        this.session.readonlyDialog();
      } else {
        this.navigate(`/activities/${this._activity.id}/apply`);
      }
    }
  }

  _canEdit() {
    return this.session.user?.tenantType === 'admin'
      || this.session.user?.tenantType === 'provider';
  }

  _changed() {
    this._currentStartDateType = this.shadowRoot.getElementById('startDateType').value;
  }

  _courseNotRequestable() {
    return this._activity.type === 'course' && !this._activity.hasTag('allowRequest');
  }

  _notRequestableComingSoon() {
    return (this._activity.startDateType === 'comingSoon' || this._activity.formattedStartDate === this.localize('edit-activity.startDateType.comingSoon')) && !this._activity.hasTag('allowRequest');
  }

  _programNotRequestable() {
    return this._activity.type === 'program' && !this._activity.hasTag('allowRequest');
  }

  _omitSkillFromExtraction(ev) {
    const removedSkill = ev.detail.skill;
    const index = this._activity.skills.findIndex(s => s.id === removedSkill.id);
    this._activity.skills[index].omittedFromExtraction = true;
    this._activity = new Activity(this._activity);
  }

  async _saveDates() {
    const startDateElement = this.shadowRoot.getElementById('startDate');
    try {
      const updatedAttributes = {};
      updatedAttributes.startDateType = this.shadowRoot.getElementById('startDateType').value;
      if (updatedAttributes.startDateType === 'date') {
        // Validate date is proper format
        // const [year, month, day] = startDateElement.value.split('-');
        updatedAttributes.startDate = this._currentStartDateList;
        startDateElement.setAttribute('aria-invalid', false);
      }

      this.client.patchActivity(this._activity.id, updatedAttributes).then(activity => {
        this._activity = activity;
        this.resetDialogDateType();
      });
    } catch (err) {
      startDateElement.setAttribute('aria-invalid', true);
    } finally {
      this.toggleStartDateDialog();
    }
  }

  async _saveSkills() {
    const updatedAttributes = {
      skills: this.tempActivitySkillsList,
    };
    this._activity.skills = this.tempActivitySkillsList;
    this.client.patchActivity(this._activity.id, updatedAttributes).then(a => this._activity = a);
    this.session.toast({ type: 'default', message: this.localize('view-activity.editSkills.save'), noAutoClose: false });
    this.requestUpdate();
  }

  get _startDateDialog() {
    return this.shadowRoot.getElementById('startDate-dialog');
  }

  toggleStartDateDialog() {
    if (!this._canEdit()) return;
    if (this._startDateDialog.opened === true) {
      this._startDateDialog.opened = false;
    } else {
      this._startDateDialog.opened = true;
      this._startDateDialog.resize();
      this.requestUpdate();

    }
  }

  resetDialogDateType() {
    this._currentStartDateType = this._activity.startDateType;
    this._currentStartDateList = [...this._activity.startDate];
    this.requestUpdate();
  }

  startDateDialog() {
    const hasDate = this._activityLoaded && this._canEdit();
    return hasDate ?
      html`
        <d2l-dialog id="startDate-dialog" title-text=${this.localize('view-activity.editStartDate.button')} @d2l-dialog-close=${this.resetDialogDateType}>
          <div style="min-height: 575px;">
            <div class="select-wrapper">
              <label for="startDateType" class="d2l-input-label d2l-input-label-required">${this.localize('common.startDateType')}</label>
              <select
                id="startDateType"
                name="startDateType"
                @change=${this._changed}
                .value="${this._currentStartDateType}"
                class="d2l-input-select"
                aria-label="${this.localize('common.startDateType')}">
                <option value="anytime">${this.localize('view-activity.startDateType.anytime')}</option>
                <option value="unknown">${this.localize('view-activity.startDateType.unknown')}</option>
                <option value="comingSoon">${this.localize('view-activity.startDateType.comingSoon')}</option>
                <option value="date">${this.localize('view-activity.startDateType.date')}</option>
              </select>
            </div>
            ${this._sessionDateTemplate()}
          </div>
          <d2l-button slot="footer" @click=${this._saveDates} primary data-dialog-action="done">${this.localize('general.button-text.save')}</d2l-button>
          <d2l-button slot="footer" data-dialog-action="Cancel">${this.localize('general.button-text.cancel')}</d2l-button>
        </d2l-dialog>
      ` : nothing;
  }

  _sessionDateTemplate() {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const isoToday = today.toISOString();
    const sanitizedDateList = this._currentStartDateList.filter(date => date >= isoToday);
    const nextSessionDate = ActivitiesHelper.getNextSessionDateAsString(this._currentStartDateList);

    return this._currentStartDateType === 'date' ? html`
    <div class="date-select-wrapper">
      <span style="padding-right: 5%;">${this.localize('view-activity.editStartDate.date.list')}</span>
      <d2l-input-date
        id="startDate"
        label="${this.localize('view-activity.startDate')}"
        @change=${this._handleDateSelection}>
      </d2l-input-date>
    </div>
    <d2l-list>
      ${repeat(sanitizedDateList, startDate => startDate, (startDate, index) => html`
        <d2l-list-item .value=${startDate}>
          <d2l-list-item-content>
            <div>${`${MONTH_NAMES[new Date(startDate).getUTCMonth()]} ${new Date(startDate).getUTCDate()}, ${new Date(startDate).getFullYear()}`}</div>
            ${ sanitizedDateList[index] === nextSessionDate ? html`<div slot="supporting-info"><d2l-status-indicator state="default" text="Current Date"></d2l-status-indicator></div>` : nothing}
          </d2l-list-item-content>
          <div slot="actions">
            <d2l-button-icon id=${index} icon="tier1:close-default" @click=${
  // eslint-disable-next-line lit/no-template-arrow
  () => this._handleDateDeletion(startDate)}>
            </d2l-button-icon>
          </div>
        </d2l-list-item>
      `)}
    </d2l-list>
    ` : nothing;
  }

  _handleDateDeletion(date) {
    const index = this._currentStartDateList.indexOf(date);
    this._currentStartDateList.splice(index, 1);
    this.requestUpdate();
  }

  _handleDateSelection() {
    const startDateElement = this.shadowRoot.getElementById('startDate');
    const dateString = new Date(startDateElement.value).toISOString();
    if (!this._currentStartDateList.some(ele => ele === dateString)) {
      this._currentStartDateList.push(dateString);
      this._currentStartDateList.sort();
      this.requestUpdate();

    }
  }

  _startDateTemplate(unrequestable = false) {
    return html`
      <div class="${unrequestable ? 'start-date-unrequestable' : 'start-date'}">
        <d2l-icon class="start-date-icon" icon="tier1:calendar"></d2l-icon><span class="start-date-text">${this.localize('view-activity.activityMetadata.footer.nextSession')}</span>
        <span>${this._activity.formattedStartDate}</span>
        ${ this._canEdit() ? html`
          <d2l-button-icon icon="tier1:edit" class="start-date-icon" text="${this.localize('view-activity.editStartDate.button')}" @click=${this.toggleStartDateDialog}></d2l-button-icon>
        ` : nothing }
        ${this.startDateDialog()}
      </div>
    `;
  }

  async _updateMyList() {
    try {
      await this.client.toggleMyListActivity(this._activity.id);
      this._savedToMyList = !this._savedToMyList;
    } catch (e) {
      if (e?.status !== 405) {
        console.error(e);
        this.session.toast({ type: 'critical', message: this.localize('view-activity.myListError') });
      }
      return;
    }
    const message = this._savedToMyList ? this.localize('view-activity.toast.added') : this.localize('view-activity.toast.removed');
    this.session.toast({ type: 'default', message, noAutoClose: false });
  }
}

window.customElements.define('view-activity', ViewActivity);

