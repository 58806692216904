import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';

import { css, html, LitElement } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';

import { LocalizeNova } from '../../mixins/localize-nova.js';

class ActivityFinancialDetailsCoverage extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      learnerTerminology: { type: String, reflect: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      css`
        :host {
          display: inline-block;
          background-color: var(--d2l-color-sylvite);
          border-radius: 0.3rem;
        }
        div {
          padding: 0.6rem;
        }
        .coverage-text {
          margin: 0.3rem;
          color: var(--d2l-color-tungsten);
        }
        d2l-icon {
          display: inline-block;
          color: var(--d2l-color-galena);
        }

        @media (max-width: 615px) {
          div {
            padding: 0.3rem;
          }
          d2l-icon {
            display: none;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.learnerTerminology = 'employee';
  }

  render() {
    return html`
      <div class="d2l-body-small">
        <d2l-icon icon="tier1:tag" aria-hidden="true"></d2l-icon>
        <span class="coverage-text">${this.localize(`activity-fees.coverage.${this.learnerTerminology}`)}</span>
      </div>
    `;
  }
}

window.customElements.define('financial-details-coverage', ActivityFinancialDetailsCoverage);
