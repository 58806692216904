const isEmpty = obj => {
  if (obj === 0 || obj === false) return false; // If it's 0, or the value false it's not empty
  if (obj instanceof Object && Object.keys(obj).length <= 0) return true; // if it's an object and there are no keys - it's empty
  if (Array.isArray(obj) && obj.length <= 0) return true; // if it's an empty array
  return !obj;
};

const SPECIAL_ATTRIBUTES = ['h', 'r', 'g1h', 'g1r', 'g2h', 'g2r', 'g3h', 'g3r', 'g4h', 'g4r', '_modelType', 'ignoreUpdates'];

/**
 * The base class for all Model instances.
 */
export class NovaModel {

  constructor(modelType, base) {
    this._modelType = modelType;
    const attributeNames = Object.keys({ ...this.modelType.attributes, ...this.modelType.modelAttributes });
    // Set the base attributes
    for (const attrName of attributeNames) {
      if (base[attrName] !== undefined) this[attrName] = base[attrName];
    }
    // Apply the default values. Doing it after all the base attributes are set in case the default value is set through
    // a function(data will be prepopulated).
    for (const attrName of attributeNames) {
      const schemaAttribute = this.modelType.attributes[attrName] || this.modelType.modelAttributes[attrName];
      const attributeValue = this[attrName] ?? schemaAttribute.getDefaultValue(this);
      if (schemaAttribute.Type && Array.isArray(attributeValue) && schemaAttribute.isArrayType) {
        // if the attribute value is an array and is an array type, instantiate each element in the array to that type
        this[attrName] = attributeValue.map(v => new schemaAttribute.Type(v));
      } else if (schemaAttribute.Type && !schemaAttribute.isArrayType) {
        // if the attribute has a type and it's not an array type, instantiate the value to that type
        this[attrName] = new schemaAttribute.Type(attributeValue);
      } else if (attributeValue !== undefined) {
        this[attrName] = attributeValue;
      }
    }
    // If the schema allows extra attributes, create a proxy to handle them
    if (this.getSchema().allowExtraAttributes) {
      // Whatever attributes don't exist in the schema, add them to the extraAttributes object
      for (const key of Object.keys(base)) {
        if (this.isExcludedAttribute(key)) {
          this.setExtraAttributes(key, base[key]);
        }
      }
      return new Proxy(this, {
        get(target, property) {
          return target.getter(property);
        },
        set(target, property, value) {
          target.setter(target, property, value);
          return true;
        },
      });
    }
  }

  setExtraAttributes(key, value) {
    if (!this.extraAttributes) this.extraAttributes = {};
    this.extraAttributes[key] = value;
  }

  getter(property) {
    const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), property);
    if (descriptor && typeof descriptor.get === 'function') {
      return descriptor.get.call(this);
    }
    if (this[property] !== undefined) {
      return this[property];
    }
    return this.extraAttributes?.[property];
  }

  setter(target, property, value) {
    if (this.isExcludedAttribute(property) && typeof value !== 'function') {
      this.setExtraAttributes(property, value);
    } else {
      target[property] = value;
    }
  }

  isExcludedAttribute(attribute) {
    const allAttrs = [...this.getSchema().attributeNames, ...Object.keys(this.getSchema().modelAttributes)];
    if (!SPECIAL_ATTRIBUTES.includes(attribute) && !allAttrs.includes(attribute)) return true;
  }

  get modelName() {
    return this._modelType;
  }

  get modelType() {
    return this.getSchema();
  }

  getFormattedValue(attribute, extraFormatters = []) {
    const schemaAttribute = this.modelType.getAttribute(attribute);
    const formatters = [...schemaAttribute.formatters].concat(extraFormatters);
    let ret = this[attribute];
    try {
      for (const format of formatters) {
        ret = format(this, ret);
      }
    } catch (err) {
      // Ignore the response - return the last result of the chain that doesnt error.
    }
    return ret;
  }

  getPossibleValues(attribute) {
    return this.modelType.getPossibleValues(attribute);
  }

  getSchema() {
    throw new Error('Schema undefined'); // This should never happen. If a model doesn't define it's schema it's impossible to construct
  }

  getTranslatedValue(attribute, lang) {
    return this.modelType.getTranslatedValue(attribute, this[attribute], lang);
  }

  /**
   * Prepares the model to be persisted to the database. Calls toJSON on any objects that have that function
   */
  toJSON(includeModelAttributes = true) {
    const attributeNames = includeModelAttributes
      ? Object.keys({ ...this.modelType.attributes, ...this.modelType.modelAttributes })
      : Object.keys(this.modelType.attributes);

    const ret = {};
    for (const attributeName of attributeNames) {
      const attribute = this.modelType.attributes[attributeName] || this.modelType.modelAttributes[attributeName];
      const newVal = this[attributeName] && this[attributeName].toJSON ? this[attributeName].toJSON() : this[attributeName];
      if (!isEmpty(newVal) || newVal !== attribute.getDefaultValue(this)) ret[attributeName] = newVal;
    }
    ret._modelType = this._modelType;
    if (ret === { _modelType: this._modelType }) undefined;
    return ret;
  }

}
