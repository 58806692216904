import { dedupeMixin } from '@open-wc/dedupe-mixin';

import * as lottie from 'lottie-web-light'; // Comment this import to use with `npm run demo`
// The above import isn't working for some reason on the demo page. Issue will be made.
// import 'lottie-web-light'; // Uncomment this import to use with `npm run demo`.

export const novaLottie = dedupeMixin(superclass => class extends superclass {

  static get properties() {
    return {
      animationUrl: { type: String, reflect: false },
      autoPlay: { type: Boolean, reflect: false },
      loop: { type: Boolean, reflect: false },
      container: { type: Object, reflect: false },
    };
  }

  constructor() {
    super();
    this.animationUrl = '';
    this.autoPlay = false;
    this.loop = false;
  }

  firstUpdated() {
    this.anim = lottie.loadAnimation({
      container: this.container,
      renderer: 'svg',
      loop: this.loop,
      autoplay: this.autoPlay,
      path: this.animationUrl,
    });
  }

  playAnimation() {
    if (this.autoPlay) return;
    lottie.stop();
    lottie.play();
  }

  pauseAnimation() {
    if (this.autoPlay) return;
    lottie.pause();
  }
});
