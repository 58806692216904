import { linkStyles } from '@brightspace-ui/core/components/link/link.js';

import { css, html } from 'lit';
import { dedupeMixin } from '@open-wc/dedupe-mixin';

export const ActiveLinkMixin = dedupeMixin(superclass => class extends superclass {

  static get properties() {
    return {
      /**
       * If this link is active.
       */
      active: { type: Boolean, attribute: 'active', reflect: true },
      /**
       * If this link is being hovered
       */
      hover: { type: Boolean, attribute: 'hover', reflect: true },
      /**
       * This links href location
       */
      href: { type: String, attribute: 'href', reflect: true },
      /**
       * This links target
       */
      target: { type: String, attribute: 'target', reflect: true },
      /**
       * The aria-label for this link
       */
      ariaLabel: { type: String, attribute: 'aria-label', reflect: true },
      /**
       * Whether this component is disabled
       */
      disabled: { type: Boolean, attribute: 'disabled', reflect: true },
      /**
       * Apply default active styling
       *
       * Size of the user text
       * @type {'default'|'none'}
       * @default "default"
       */
      activeStyle: { type: String, attribute: 'active-style', reflect: true },
    };
  }

  static get styles() {
    const styles = [
      linkStyles,
      css`
        :host {
          position: relative;
          display: block;
          border-radius: 6px;
          transition: transform 300ms ease-out 50ms, box-shadow 0.2s;
        }

        :host([active][active-style=default]),
        :host([active][active-style=default]:hover) {
          border-color: transparent;
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--d2l-color-celestine);
        }

        a {
          color: inherit;
          text-decoration: inherit;
        }

        a:focus {
          outline: none;
        }


        .active-link {
          display: block;
          width: 100%;
          height: 100%;
          outline: none;
        }

        @media (prefers-reduced-motion: reduce) {
          :host {
            transition: none;
          }
        }
`,
    ];
    super.styles && styles.unshift(super.styles);
    return styles;
  }

  constructor() {
    super();
    this.active = false;
    this.hover = false;
    this.target = '_self';
    this.activeStyle = 'default';
  }

  wrapWithLink(template, useNewTab = false) {
    if (this.disabled) return template;
    const href = this.href || 'javascript:';
    return html`
      <a class="active-link"
          href=${href}
          target=${useNewTab ? '_blank' : this._target}
          @blur="${this._onLinkBlur}"
          @focus="${this._onLinkFocus}"
          @mouseenter="${this._onLinkMouseEnter}"
          @mouseleave="${this._onLinkMouseLeave}"
          .ariaLabel="${this.ariaLabel}"
          rel="alternate"
          type="text/html"
          fill-container
      >
        ${template}
      </a>`;
  }

  _onLinkBlur() {
    this.active = false;
  }

  _onLinkFocus() {
    this.active = true;
  }

  _onLinkMouseEnter() {
    this.hover = true;
  }

  _onLinkMouseLeave() {
    this.hover = false;
  }
});
