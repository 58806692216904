/* eslint-disable sort-imports */
/* eslint-disable sort-keys */
import en from "./en.js";
import fr from "./fr.js";
/*
----
NOTE:
Those languages are commented to reduce the size of serverless packages (as they are not being supported yet)
To include the language into the package, uncomment import & export and include it in serverless.yml
----

import ar from "./ar.js";
import cy from "./cy.js";
import da from "./da.js";
import de from "./de.js";
import esEs from "./es-es.js";
import es from "./es.js";
import frFr from "./fr-fr.js";
import frOn from "./fr-on.js";
import hiIn from "./hi-in.js";
import hi from "./hi.js";
import ja from "./ja.js";
import ko from "./ko.js";
import nl from "./nl.js";
import pt from "./pt.js";
import sv from "./sv.js";
import tr from "./tr.js";
import zhTw from "./zh-tw.js";
import zh from "./zh.js";
*/

export default {
  en,
  fr,
  /*
  ar,
  cy,
  da,
  de,
  "es-es": esEs,
  es,
  "fr-fr": frFr,
  "fr-on": frOn,
  "hi-in": hiIn,
  hi,
  ja,
  ko,
  nl,
  pt,
  sv,
  tr,
  "zh-tw": zhTw,
  zh,
  */
};
