import { DEFAULT_LANG } from './localizers/localizer.js';
import { DefaultLocalizer } from './localizers/default-localizer.js';

let localizer = new DefaultLocalizer(DEFAULT_LANG);

export const novaLocalize = (key, params, language) => {
  if (!localizer) return 'loading';
  return localizer.localize(key, params, language);
};

export const setLocalizer = (lang, Localizer) => {
  localizer = new Localizer(lang);
};

export const ALL_LANGUAGES = {
  'en': 'English',
  'fr': 'Français (Canada)',
  'ar': 'العربية',
  'cy': 'Cymraeg',
  'da': 'dansk',
  'de': 'Deutsch',
  'es-es': 'español (España)',
  'es': 'español',
  'fr-fr': 'français (France)',
  'ja': '日本語',
  'ko': '한국어',
  'nl': 'Nederlands',
  'pt': 'Português',
  'sv': 'svenska',
  'tr': 'Türkçe',
  'zh-tw': '中文(台灣)',
  'zh': '中文',
};

export const SUPPORTED_LANGUAGES = [
  {
    key: 'en',
    displayNameLangKey: 'general.language.english',
  },
  {
    key: 'fr',
    displayNameLangKey: 'general.language.french',
  },
];
export const SUPPORTED_LANG_KEYS = SUPPORTED_LANGUAGES.map(langData => langData.key);

/**
 * returns a sorted list of language keys order alphabatized, split by roman and non-roman charactered languages
 *
 */
function sortLanguages(languages) {
  // regex pattern to identify non-roman characters
  const reg = new RegExp('[^\u0020-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]');

  const ROMAN_LANG_KEYS = [];
  const NON_ROMAN_LANG_KEYS = [];

  Object.keys(languages).forEach(key => {
    if (SUPPORTED_LANG_KEYS.includes(key)) {
      return;
    }
    if (languages[key].match(reg)) {
      NON_ROMAN_LANG_KEYS.push(key);
    } else {
      ROMAN_LANG_KEYS.push(key);
    }
  });

  return (ROMAN_LANG_KEYS.sort()).concat(NON_ROMAN_LANG_KEYS.sort());
}

export const ALL_LANG_KEYS = SUPPORTED_LANG_KEYS.concat(sortLanguages(ALL_LANGUAGES));

/**
 *
 * Allow adding of translation values programatically
 */
export const addTranslations = translations => {
  localizer.addTranslations(translations);
};

/**
 * This will return the promise awaiting resources being loaded. We await this promise to ensure the translations
 * have been loaded.
 *
 */
export const localizerReady = () => {
  return localizer.constructor.__resourcesLoadedPromise;
};
