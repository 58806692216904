import ActivityImport from './admin/views/import/activity-import.js';
import ApplyActivity from './main/views/activities/apply-activity.js';
import ContactSupport from './main/views/general/contact-support.js';
import EditActivity from './admin/views/edit-activity/index.js';
import EditTenant from './admin/views/tenants/edit-tenant.js';
import Home from './shared/views/view-home.js';
import LoginCollectDetails from './shared/components/login/login-collect-details.js';
import NotFound from './shared/views/errors/view-not-found.js';
import ReviewApplication from './main/views/applications/review-application.js';
import ReviewAppReviewSubmitOwnRequestApplicationlication from './main/views/applications/review-submit-own-request-application.js';
import SubmitOwnRequest from './main/views/applications/submit-own-request.js';
import ViewAccountConnection from './main/views/applications/view-account-connection.js';
import ViewActivitiesDashboard from './main/views/activities/view-activities-dashboard.js';
import ViewActivity from './main/views/activities/view-activity.js';
import ViewActivityCategory from './main/views/activities/view-activity-category.js';
import ViewAdmin from './admin/views/tenants/view-admin.js';
import ViewApplication from './main/views/applications/view-application.js';
import ViewApplicationConfirmation from './main/views/applications/view-application-confirmation.js';
import ViewApplications from './main/views/applications/view-applications.js';
import ViewErrorLoading from './shared/views/errors/view-error-loading.js';
import ViewMagicLink from './shared/views/redirect-magic-link.js';
import ViewReportDashboard from './shared/views/report/view-report-dashboard.js';

export default [
  {
    name: 'home',
    pattern: '',
    data: {
      View: Home,
      layout: 'full-width',
      documentTitleLangKey: 'app-nova.documentTitle',
    },
  },
  {
    name: 'activities',
    pattern: 'activities',
    data: {
      View: ViewActivitiesDashboard,
      documentTitleLangKey: 'view-activities.documentTitle',
    },
  },
  {
    name: 'activities-by-career',
    pattern: 'activities/skill-stream/career/:careerId',
    data: {
      View: ViewActivityCategory,
      documentTitleLangKey: 'view-activity-category.documentTitle.whileLoading',
    },
  },
  {
    name: 'activities-by-skill-stream',
    pattern: 'activities/skill-stream/:category',
    data: {
      View: ViewActivityCategory,
      documentTitleLangKey: 'view-activity-category.documentTitle.whileLoading',
    },
  },
  {
    name: 'add-activity',
    pattern: 'activities/add',
    data: {
      View: EditActivity,
      documentTitleLangKey: 'edit-activity.documentTitle.addActivity',
    },
  },
  {
    name: 'import-activity',
    pattern: 'activities/import',
    data: {
      View: ActivityImport,
      documentTitleLangKey: 'activity-import.documentTitle.stream',
    },
  },
  {
    name: 'edit-activity',
    pattern: 'activities/:id/edit',
    data: {
      View: EditActivity,
      documentTitleLangKey: 'edit-activity.documentTitle',
    },
  },
  {
    name: 'view-activity',
    pattern: 'activities/:id',
    data: {
      View: ViewActivity,
      layout: 'full-width',
      documentTitleLangKey: 'view-activity.documentTitle.whileLoading',
    },
  },
  {
    name: 'magic-link',
    pattern: 'login/magic',
    data: {
      View: ViewMagicLink,
      documentTitleLangKey: 'login.magiclink.documentTitle',
    },
  },
  {
    name: 'apply-activity',
    pattern: 'activities/:id/apply',
    data: {
      View: ApplyActivity,
      documentTitleLangKey: 'apply-activity.documentTitle',
    },
  },
  {
    name: 'review-application',
    pattern: 'activities/:original_id/:selected_id/apply/review',
    data: {
      View: ReviewApplication,
      documentTitleLangKey: 'review-application.documentTitle',
    },
  },
  {
    name: 'review-submit-own-request-application',
    pattern: 'activities/apply/review-submit-own-request',
    data: {
      View: ReviewAppReviewSubmitOwnRequestApplicationlication,
      documentTitleLangKey: 'review-application.documentTitle',
    },
  },
  {
    name: 'view-admin',
    pattern: 'admin',
    data: {
      View: ViewAdmin,
      documentTitleLangKey: 'view-admin.documentTitle',
    },
  },
  {
    name: 'add-tenant',
    pattern: 'tenants/add/:type',
    data: {
      View: EditTenant,
      documentTitleLangKey: 'edit-tenant.documentTitle.addTenant',
    },
  },
  {
    name: 'edit-tenant',
    pattern: 'tenants/:id/edit',
    data: {
      View: EditTenant,
      documentTitleLangKey: 'edit-tenant.documentTitle',
    },
  },
  {
    name: 'analytics',
    pattern: 'analytics',
    data: {
      View: ViewReportDashboard,
      documentTitleLangKey: 'view-report-dashboard.documentTitle',
    },
  },
  {
    name: 'view-applications',
    pattern: 'requests',
    data: {
      View: ViewApplications,
      documentTitleLangKey: 'view-applications.documentTitle',
    },
  },
  {
    name: 'submit-own-request',
    pattern: 'requests/submit-own-request',
    data: {
      View: SubmitOwnRequest,
      documentTitleLangKey: 'submit-own-request.documentTitle',
    },
  },
  {
    name: 'review-application',
    pattern: 'requests/submit-own-request/review',
    data: {
      View: ReviewApplication,
      documentTitleLangKey: 'review-application.documentTitle',
    },
  },
  {
    name: 'view-application',
    pattern: 'requests/:uuid',
    data: {
      View: ViewApplication,
      documentTitleLangKey: 'view-application.documentTitle',
    },
  },
  {
    name: 'view-application-account-connection',
    pattern: 'requests/:uuid/account-connection',
    data: {
      View: ViewAccountConnection,
      documentTitleLangKey: 'view-application.documentTitle',
    },
  },
  {
    name: 'contact-support',
    pattern: 'contact-support',
    data: {
      View: ContactSupport,
      documentTitleLangKey: 'contact-support.documentTitle',
    },
  },
  {
    name: 'login-details',
    pattern: 'login-details',
    data: {
      View: LoginCollectDetails,
      layout: 'splash',
      documentTitleLangKey: 'view-login-details.documentTitle',
    },
  },
  {
    name: 'not-found',
    pattern: '*',
    data: {
      View: NotFound,
      documentTitleLangKey: 'view-not-found.documentTitle',
    },
  },
  {
    name: 'error',
    pattern: 'error',
    data: {
      View: ViewErrorLoading,
      documentTitleLangKey: 'view-error-loading.documentTitle',
    },
  },
  {
    name: 'view-application-confirmation',
    pattern: 'requests/:uuid/confirmation',
    data: {
      View: ViewApplicationConfirmation,
      documentTitleLangKey: 'view-application.documentTitle',
    },
  },
];
