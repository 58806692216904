import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/form/form.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import LoginMagicLink from '../components/login/login-magic-link.js';
import LoginMarketing from '../components/login/login-marketing.js';
import LoginMulti from '../components/login/login-multi.js';
import LoginSaml from '../components/login/login-saml.js';

import { novaCardStyles } from '../styles/nova-card.js';

export default class LoginView extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      _showcase: { type: Boolean, attribute: '_showcase', reflect: true },
    };
  }

  static get styles() {
    return [
      novaCardStyles,
      css`
        :host {
          display: block;
          height: 100vh;
          overflow-y: auto;
        }

        .splash {
          display: grid;
          box-sizing: border-box;
          width: 100%;
          min-height: 100%;
          padding: 1rem;
          background: #f7fafe;
          background-image: url(../../assets/img/bg-waves.svg);
          background-position: 0 0;
          background-size: contain;
          border-top: solid 6px var(--d2l-branding-primary-color);
          place-items: center;
        }

`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this._showcase = this.session.hasFeature('marketing');
  }

  render() {
    return html`
      <div class="splash">
        ${this._getLoginContent()}
      </div>
    `;
  }

  _getLoginContent() {
    const { loginType } = this.session.appDetails;
    if (this.session.hasFeature('marketing')) {
      return new LoginMarketing();
    } else if (loginType === 'saml') {
      return new LoginSaml();
    } else if (loginType === 'magic-link') {
      return new LoginMagicLink();
    } else if (loginType === 'multi') {
      return new LoginMulti();
    }
  }

}

window.customElements.define('view-login', LoginView);
