
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { heading3Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '../../../shared/components/tooltip/nova-tooltip.js';

import { css, html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import isEqual from 'lodash.isequal';
import { navigator as nav } from 'lit-element-router';

import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { NovaFormMixin } from '../../../shared/mixins/nova-form-mixin.js';
import { SUPPORTED_LANGUAGES } from '../../../../shared/l10n/localize.js';

export default class ManageActivityInstructions extends NovaFormMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      tenant: { type: Object, reflect: false },
      _activityInstructions: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      heading3Styles,
      labelStyles,
      css`
        .activity-instructions-container {
          display: flex;
          flex-direction: column;
        }

        .submit-button {
          margin-top: 3rem;
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._changedLangKeys = new Set();
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);

    // The observer is used so that the API calls are done only when the component is in view
    this.intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this._getActivityInstructions();
      }
    });
    this.intersectionObserver.observe(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  // Get activity instructions for selected lang
  async _getActivityInstructions() {

    const activityInstructionPromises = {};
    SUPPORTED_LANGUAGES.forEach(lang => {
      const { key } = lang;
      activityInstructionPromises[key] = this.client.getCustomContent(this.tenant.id, 'activityInstructions', key, true);
    });
    const resultsArray = await Promise.all(Object.values(activityInstructionPromises));
    const results = {};
    SUPPORTED_LANGUAGES.forEach((lang, index) => {
      const { key } = lang;
      if (resultsArray[index]) {
        results[key] = resultsArray[index];
      } else {
        results[key] = { // initialize with stub of custom content
          lang: key,
          isEnabled: false,
          value: '',
        };
      }
    });

    this._savedActivityInstructions = JSON.parse(JSON.stringify(results));
    this._activityInstructions = results;
  }

  async _handleSaveButtonClick() {
    const promises = [];
    for (const key of this._changedLangKeys) {
      const activityInstructionData = this._activityInstructions[key];
      const value = activityInstructionData?.value ?? '';
      const enabled = activityInstructionData?.isEnabled ?? false;
      if (!isEqual(activityInstructionData, this._savedActivityInstructions[key])) {
        promises.push(this._saveActivityInstructions(this.tenant.id, key, value, enabled));
      }
    }
    await Promise.all(promises);
  }

  // Save activity instructions for selected lang
  async _saveActivityInstructions(providerId, lang, activityInstructionsText, isEnabled) {
    const toast = { message: 'Tenant activity instructions saved', type: 'default' };

    try {
      const customContentData = {
        type: 'activityInstructions',
        value: activityInstructionsText?.trim(),
        tenantId: providerId,
        isEnabled,
        lang,
      };
      await this.client.setCustomContent(providerId, customContentData);
    } catch (e) {
      toast.message = await e.text();
      toast.type = 'critical';
    }

    this.session.toast(toast);
  }

  _activityInstructionsInputHtml() {
    return SUPPORTED_LANGUAGES.map(langData => {
      const instructionData = this._activityInstructions[langData.key];
      return html`
        <h2 class="d2l-heading-3">${this.localize(langData.displayNameLangKey)} (${langData.key})</h2>
        <div class="activity-instructions-container">
          <d2l-input-checkbox
            ?checked=${instructionData?.isEnabled}
            value=${langData.key}
            @change=${this._toggleActivityInstructionsEnabled}>
            ${this.localize('general.enabled')}
          </d2l-input-checkbox>
          <d2l-input-textarea
            id=${`activityInstructions-${langData.key}`}
            data-lang-key=${langData.key}
            value=${ifDefined(instructionData?.value?.trim())}
            label=${this.localize('general.value')}
            rows=1
            max-rows=5
            @change=${this._handleActivityInstructionsChanged}>
          </d2l-input-textarea>
        </div>
      `;
    });
  }

  _handleActivityInstructionsChanged(e) {
    const inputElem = e.currentTarget;
    const langKey = inputElem.getAttribute('data-lang-key');
    this._activityInstructions[langKey].value = inputElem.value;
    this._changedLangKeys.add(langKey);
  }

  _toggleActivityInstructionsEnabled(e) {
    const checkbox = e.currentTarget;
    const langKey = checkbox.value;
    this._activityInstructions[langKey].isEnabled = checkbox.checked;
    this._changedLangKeys.add(langKey);
  }

  render() {
    if (!this._activityInstructions) return nothing;
    return html`
      <d2l-form>
        ${this._activityInstructionsInputHtml()}
        <div id="submit-activity-instructions" class="submit-button">
          <d2l-button @click=${this._handleSaveButtonClick} primary>
            ${this.localize('manage-general.buttonSave')}
          </d2l-button>
        </div>
      </d2l-form>
    `;
  }
}

window.customElements.define('manage-activity-instructions', ManageActivityInstructions);
