import accountConnection from './account-connection.js';
import activity from './activity/activity.js';
import activityRelationship from './activity/activity-relationship.js';
import { Application as application } from './application/index.js';
import { Audit as audit } from './audit.js';
import { Cost as cost } from './cost.js';
import genericActivity from './activity/generic-activity.js';
import scriptExecution from './script-execution.js';
import tenant from './tenant/index.js';
import tenantCustomContent from './tenant/custom-content.js';
import tenantSSO from './tenant/sso.js';
import userSession from './user-session.js';

import { CustomAttribute as customAttribute } from './custom-attribute.js';
import { LocalizedValue as localizedValue } from './localized-value.js';

export const MODELS = { accountConnection, activity, activityRelationship, application, audit, cost, genericActivity, scriptExecution, tenant, tenantSSO, tenantCustomContent, userSession, customAttribute, localizedValue };

const _isNovaModel = obj => {
  return typeof obj === 'object' && obj._modelType;
};

/**
 * Wraps the json object in the corresponding model. If not a model just returns the json object.
 *
 * @param model
 * @returns {*}
 * @private
 */
const _createModel = model => {
  const Model = MODELS[model._modelType];
  return Model ? new Model(model) : model;
};

/**
 * Factory function for turning a json object into the corresponding model. Also supports wrapping array elements and
 * 1 level of attributes in the json object.
 *
 * @param model
 * @returns {*}
 */
export const createModel = model => {
  if (!model) return model;
  if (model.constructor === Array) return model.map(_createModel);
  if (_isNovaModel(model)) return _createModel(model);

  if (typeof model === 'object') {
    for (const key of Object.keys(model)) {
      if (model[key] && (model[key].constructor === Array || _isNovaModel(model[key]))) {
        model[key] = _createModel(model[key]);
      }
    }
  }
  return model;
};
