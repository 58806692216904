import { denormalizedActivityAttributes, VALID_TAGS } from './shared.js';
import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';
import ActivityRelationship from '../../activity/activity-relationship.js';
import { AdmissionRequirements } from '../../activity/admission-requirements.js';

const VALID_ACTIVITY_FORMAT = ['selfPaced', 'instructorLed'];

const VALID_ACTIVITY_LANGUAGE = ['en', 'fr'];

const VALID_ACTIVITY_TIMECOMMITMENT = ['lessThanFive', 'fiveToTen', 'tenToTwenty', 'twentyPlus'];

const VALID_CERTIFICATE_TYPES = ['certificate', 'degree', 'diploma', 'microcredential', 'course'];

const VALID_CONTENT_FORMATS = ['videos', 'readings', 'slides'];

const VALID_FEEDBACK_TYPES = ['discussions', 'quizzes', 'assignments', 'instructorReviews', 'peerReviews'];

class ActivityModelSchema extends NovaSchema {

  constructor() {
    super('activity');
    this.attributes = {
      ...denormalizedActivityAttributes,
      activeDate: new SchemaAttribute(),
      bannerImageUrl: new SchemaAttribute(),
      certificateType: new SchemaAttribute().setPossibleValues(VALID_CERTIFICATE_TYPES),
      children: new SchemaAttribute().setType(ActivityRelationship, true),
      code: new SchemaAttribute(),
      contentFormats: new SchemaAttribute().setPossibleValues(VALID_CONTENT_FORMATS),
      coursesRequired: new SchemaAttribute(),
      credits: new SchemaAttribute(),
      feedbackTypes: new SchemaAttribute().setPossibleValues(VALID_FEEDBACK_TYPES),
      format: new SchemaAttribute().setPossibleValues(VALID_ACTIVITY_FORMAT),
      parents: new SchemaAttribute().setType(ActivityRelationship, true),
      instructionLang: new SchemaAttribute('en').setPossibleValues(VALID_ACTIVITY_LANGUAGE),
      keyDifferentiator: new SchemaAttribute(),
      lastUpdated: new SchemaAttribute(),
      maxLearnerDistance: new SchemaAttribute(100),
      numberElectives: new SchemaAttribute(),
      postalCode: new SchemaAttribute(),
      prereq: new SchemaAttribute(),
      project: new SchemaAttribute().setPossibleValues([true, false]),
      registration: new SchemaAttribute(),
      specialInstructions: new SchemaAttribute(),
      tags: new SchemaAttribute().setPossibleValues(VALID_TAGS),
      weeklyTimeCommitment: new SchemaAttribute().setPossibleValues(VALID_ACTIVITY_TIMECOMMITMENT),
      admissionRequirements: new SchemaAttribute().setType(AdmissionRequirements),
    };
  }

}

export const ActivitySchema = new ActivityModelSchema();
