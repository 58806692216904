import { CostSchema } from './schema/cost.js';
import { Currency } from '../drivers/currency.js';
import { NovaModel } from './nova-model.js';

import { formatCurrencyAsDecimalNoSymbols, formatCurrencyAsDecimalWithCode, formatCurrencyAsInt, formatCurrencyAsIntWithCode } from '../helpers/number.js';

export class Cost extends NovaModel {

  constructor(base = {}) {
    super('cost', base);
    if (base.dollars || base.dollars === 0) {
      this.setInDollars(base.dollars);
    } else if (base.cents || base.cents === 0) {
      this.setInCents(base.cents);
    }
  }

  getSchema() {
    return CostSchema;
  }
  /**
 * Creates a clone of 'this', applies the tax rate
 * @param rate
 * @returns {Cost}
 */
  applyRate(rate) {
    if (this.isEmpty()) return new Cost(this);
    return new Cost({
      cost: Math.round(this.cost * rate),
      currency: this.currency,
      originalCurrency: this.originalCurrency,
      conversionRate: this.conversionRate,
    });
  }

  /**
 * Returns the cost in cents. Throws an error if trying to retrieve the cost in a different currency.
 *
 * @param taxRate the tax rate to apply to the cost
 * @param inOriginalCurrency if true, the cost will be converted to the original currency(it will ignore the currency parameter)
 * @returns {number}
 */
  inCents({ taxRate = 0, inOriginalCurrency = false } = {}) {
    if (taxRate < 1) taxRate += 1;
    const amount = Math.round(this.cost * taxRate);
    return Math.round(inOriginalCurrency ? amount * this.conversionRate : amount);
  }

  /**
   * If a conversion rate is specified it will return synchronously do the conversion, otherwise it will return a promise to do the conversion.
   * @param currency
   * @param conversionRate
   * @returns {Promise<Cost>|Cost}
   */
  toCurrency(currency, conversionRate) {
    if (this.isEmpty()) return new Cost({ ...this, currency, conversionRate });
    if (conversionRate) {
      return this._toCurrencySync(currency, conversionRate);
    } else {
      return this._toCurrencyAsync(currency);
    }
  }

  /**
 * Returns this cost in the given currency. Will use the stored rate first if available, otherwise will fetch the rate from the currency service.
 *
 * @param currency
 * @returns {Promise<Cost>}
 */
  async _toCurrencyAsync(currency = this.currency) {
    let conversionRate = 1;
    if (currency === this.currency) {
      conversionRate = 1;
    } else if (currency === this.originalCurrency) {
      conversionRate = this.conversionRate;
    } else {
      conversionRate = await Currency.getRate(this.currency, currency);
    }
    return new Cost({
      cost: this.cost,
      currency,
      originalCurrency: this.originalCurrency,
      conversionRate: 1 / conversionRate,
    }).applyRate(conversionRate);
  }

  /**
   * Synchronously applies a currency conversion with the given rate.
   * @param currency
   * @param conversionRate
   * @returns {Cost}
   * @private
   */
  _toCurrencySync(currency = this.originalCurrency, conversionRate = this.conversionRate) {
    return new Cost({
      cost: this.cost,
      currency,
      originalCurrency: this.currency,
      conversionRate: 1 / conversionRate,
    }).applyRate(conversionRate);
  }

  inOriginalCurrency() {
    return this._toCurrencySync();
  }

  /**
 * Returns the cost in dollars. Throws an error if trying to retrieve the cost in a different currency.
 *
 * @param taxRate the tax rate to apply to the cost
 * @param inOriginalCurrency if true, the cost will be converted to the original currency(it will ignore the currency parameter)
 * @returns {number|undefined}
 */
  inDollars({ taxRate = 0, inOriginalCurrency = false } = {}) {
    const inCents = this.inCents({ taxRate, inOriginalCurrency });
    return parseFloat((inCents / 100).toFixed(2));
  }

  /**
 * Sets the cost in dollars
 * @param dollars
 * @returns {Cost}
 */
  setInDollars(dollars) {
    this.cost = Math.round(dollars * 100);
    return this;
  }

  setInCents(cents) {
    this.cost = Math.round(cents);
    return this;
  }

  /**
 * Combines the two costs using the given operation - it first will convert it to the same currency if needed
 *
 * @param other
 * @param operation
 * @returns {Cost}
 * @private
 */
  _combine(other, operation) {
    let thisCost = new Cost(this);
    let otherCost = new Cost(other);
    if (this.currency !== other.currency) {
      if (this.currency === other.originalCurrency) {
        otherCost = other.inOriginalCurrency();
        thisCost.originalCurrency = otherCost.originalCurrency;
        thisCost.conversionRate = otherCost.conversionRate;
      } else if (this.originalCurrency === other.currency) {
        thisCost = this.inOriginalCurrency();
      } else {
        throw new Error('Cannot combine costs with different currencies');
      }
    }
    if (operation === 'add') {
      thisCost.setInCents(thisCost.cost + otherCost.cost);
    } else {
      thisCost.setInCents(thisCost.cost - otherCost.cost);
    }
    return thisCost;
  }

  /**
 * Adds the two costs
 * @param cost
 * @returns {Cost}
 */
  add(cost) {
    if (this.isEmpty() && cost.isEmpty()) return new Cost();
    if (this.isEmpty()) return new Cost(cost);
    if (cost.isEmpty()) return new Cost(this);
    return this._combine(cost, 'add');
  }

  /**
 * Subtracts the two costs
 * @param cost
 * @param min
 * @returns {Cost}
 */
  subtract(cost, min = 0) {
    if (this.isEmpty() && cost.isEmpty()) return new Cost();
    const left = this.isEmpty() ? new Cost({ ...cost, cost: 0 }) : this;
    const right = cost.isEmpty() ? new Cost({ ...this, cost: 0 }) : cost;
    const ret = left._combine(right, 'subtract');
    ret.cost = Math.max(ret.cost, min);
    return ret;
  }

  /**
   * Formats the cost as a decimal with the currency code
   *
   * @param lang
   * @param includeCurrencyCode
   * @returns {string|*}
   */
  formatAsDecimal(lang = 'en', includeCurrencyCode = true) {
    if (this.isEmpty()) return '---';
    const ret = includeCurrencyCode ?
      formatCurrencyAsDecimalWithCode(this.inDollars(), this.currency, lang) :
      formatCurrencyAsDecimalNoSymbols(this.inDollars());
    // check if ret contains NaN
    if (ret.includes('NaN')) {
      // eslint-disable-next-line
      console.log('Cost.formatAsDecimal: NaN detected', this);
      return '---';
    }
    return ret;
  }

  /**
   * Formats the cost as an int with the currency code
   *
   * @param lang
   * @param includeCurrencyCode
   * @returns {string|*}
   */
  formatAsInt(lang = 'en', includeCurrencyCode = true) {
    if (this.isEmpty()) return '---';
    const ret = includeCurrencyCode ?
      formatCurrencyAsIntWithCode(this.inDollars(), this.currency, lang) :
      formatCurrencyAsInt(this.inDollars(), this.currency, lang);
    // check if ret contains NaN
    if (ret.includes('NaN')) {
      return '---';
    }
    return ret;
  }

  isEmpty() {
    return !this.cost && this.cost !== 0;
  }

  /**
   * Returns the minimum of the two costs. If one of the costs is empty, return an empty cost
   * @param cost1
   * @param cost2
   * @returns {*}
   */
  static min(cost1, cost2) {
    return Cost._compare(cost1, cost2, 'min');
  }

  /**
   * Returns the maximum of the two costs. If one of the costs is empty, return an empty cost
   * @param cost1
   * @param cost2
   * @returns {*}
   */
  static max(cost1, cost2) {
    return Cost._compare(cost1, cost2, 'max');
  }

  /**
   * Compares the values and returns either the min or max depending on the operation
   *
   * @param cost1
   * @param cost2
   * @param operation
   * @returns {*|Cost}
   * @private
   */
  static _compare(cost1, cost2, operation) {
    if (cost1.isEmpty() || cost2.isEmpty()) return new Cost();
    // Check if they are the same currency
    if (cost1.currency !== cost2.currency) {
      throw new Error('Cannot compare costs with different currencies');
    }
    if (operation === 'min') {
      return cost1.cost <= cost2.cost ? cost1 : cost2;
    } else if (operation === 'max') {
      return cost1.cost >= cost2.cost ? cost1 : cost2;
    } else {
      throw new Error('Invalid operation');
    }
  }

}
