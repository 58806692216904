import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui-labs/pagination/pagination.js';

import { css, html, LitElement } from 'lit';
import Fuse from 'fuse.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { AuditSchema } from '../../../../shared/models/schema/audit.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { novaTableStyles } from '../../../main/components/applications/application-table-styles.js';
import { optionsTemplate } from '../../../shared/helpers/generic-templates.js';
import { TIMEFRAME_TYPES } from '../../../../shared/constants.js';

/**
 * Shows a list of the courses
 */
export default class AuditTable extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
      tenant: { type: String },
      _audit: { type: Array, attribute: false },
      _search: { type: Array, attribute: false },
      _filteredAudit: { type: Array, attribute: false },
      _pageNumber: { type: Number, attribute: false },
      _auditPerPage: { type: Number, attribute: false },
      _selectedAudit: { type: Object, attribute: false },
      _tenantType: { type: String, attribute: false },
      _timeframe: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      novaTableStyles,
      selectStyles,
      css`
        .submit-buttons {
          padding-top: 10px;
        }

        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .action-filter {
          margin-bottom: 10px;
        }

        .message {
          height: 200px;
        }

        .table-row, .table-header {
          grid-template-columns: repeat(4, 1fr);
        }

        .initiator, .subject {
          width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
`,
    ];
  }

  constructor() {
    super();
    this._auditPerPage = 10;
    this._pageNumber = 1;
    this._timeframe = 'lastDay';
    this._search = '';
  }

  get auditDialog() {
    this._auditDialog = this._auditDialog || this.shadowRoot.getElementById('audit-dialog');
    return this._auditDialog;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    const tenantObject = await this.client.fetchTenant(this.tenant);
    this._tenantType = tenantObject.type;
  }

  render() {
    if (!this._filteredAudit) return html`Loading`;
    const audit = this._filteredAudit.slice(this._pageStartIndex, this._pageEndIndex);
    return html`
      <select class='action-filter d2l-input-select' .value=${this._timeframe} @change=${this._timeframeChange}>
        ${repeat(TIMEFRAME_TYPES, tf => tf.tf, tf => html`
        <option .selected="${this._timeframe === tf.value}" .value=${tf.value}>${tf.displayName}</option>
        `)}
      </select>
      <select class='action-filter d2l-input-select' .value=${this._actionFilter} @change=${this._actionChange}>
        <option value="" selected>Filter by action</option>
        ${optionsTemplate(AuditSchema.getFilteredActions(this._tenantType))}
      </select>
      <d2l-input-text @change=${this._handleSearchChange} id="search" label="Search"></d2l-input-text>
      <div class="nova-table" role="table">
        <div class="table-header d2l-skeletize" role="row">
          <div class="table-item date" role="columnheader">Date</div>
          <div class="table-item action" role="columnheader">Action</div>
          <div class="table-item initiator" role="columnheader">Initiator</div>
          <div class="table-item subject" role="columnheader">Subject</div>
        </div>
        ${audit.map(a => html`
        <div class="table-row d2l-skeletize" role="row" @click=${this._clickAuditRow(a)}>
          <div class="table-item date" role="cell">${a.formattedDate}</div>
          <div class="table-item action" role="cell">${a.getTranslatedValue('action')}</div>
          <div class="table-item initiator" role="cell">${a.initiator}</div>
          <div class="table-item subject" role="cell">${a.subjectDisplay}</div>
        </div>
        `)}
      </div>
      <d2l-labs-pagination
        id="pagination"
        @pagination-page-change=${this._pageChanged}
        .pageNumber=${this._pageNumber}
        max-page-number=${this._maxPageNumber}
        selected-count-option="20"></d2l-labs-pagination>
      ${this._auditDialogTemplate()}
    `;
  }

  async updated(_changedProperties) {
    for (const [propName, oldValue] of _changedProperties) {
      if (propName === 'tenant' && this.tenant && oldValue !== this.tenant) {
        // eslint-disable-next-line no-await-in-loop
        await this._updateTable();
      }
    }
  }

  get _maxPageNumber() {
    return this._totalAuditRecords > 0 ? Math.ceil(this._totalAuditRecords / this._auditPerPage) : 1;
  }

  get _pageEndIndex() {
    const end = this._pageNumber * this._auditPerPage;
    return end > this._totalAuditRecords ? this._totalAuditRecords : end;
  }

  get _pageStartIndex() {
    return (this._pageNumber - 1) * this._auditPerPage;
  }

  get _totalAuditRecords() {
    return this._audit.length;
  }

  _actionChange(e) {
    this._actionFilter = e.target.value;
    this._updateTable();
  }

  _auditDialogTemplate() {
    const audit = this._selectedAudit || {};
    return html`
      <d2l-dialog id="audit-dialog" title-text="Audit - ${audit.action} - ${audit.formattedDate}">
        <div class="dialog-attributes">
          <pre>${JSON.stringify(audit.data, undefined, 2)}</pre>
        </div>
      </d2l-dialog>
    `;
  }

  _clickAuditRow(audit) {
    return e => {
      this._openDialog(e, audit);
    };
  }

  _handleSearchChange(e) {
    this._search = e.target?.value;
    this._filteredAudit = this._search ? this._fuse.search(this._search).map(r => r.item) : this._audit;
    this._pageNumber = 1;
  }

  _openDialog(e, audit) {
    this._selectedAudit = audit;
    this.auditDialog.opened = true;
  }

  async _pageChanged(e) {
    this._pageNumber = e.detail.page;
  }

  _timeframeChange(e) {
    this._timeframe = e.target.value;
    this._updateTable();
  }

  async _updateTable() {
    this.skeleton = true;
    this._audit = await this.client.getAudit(this.tenant, this._actionFilter, this._timeframe);
    this._pageNumber = 1;
    this.skeleton = false;
    this._fuse = new Fuse(this._audit, {
      keys: ['date', 'formattedDate', 'subjectDisplay', 'formattedAction', 'initiator'],
    });
    this._filteredAudit = this._search ? this._fuse.search(this._search).map(r => r.item) : this._audit;
  }
}

window.customElements.define('audit-table', AuditTable);
