import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading2Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';

class ApplicationTermsCheckboxes extends SkeletonMixin(LocalizeNova(LitElement)) {

  static get properties() {
    return {
      customCondition: { type: Object },
      employerName: { type: String, attribute: 'employer-name' },
      language: { type: String },
      providerName: { type: String, attribute: 'provider-name' },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading2Styles,
      labelStyles,
      linkStyles,
      css`
        :host {
          display: block;
        }

        .tos {
          width: 100%;
          height: 580px;
        }

        .terms-button {
          display: inline;
          padding: 0;
          font-size: inherit;
          font-family: 'Lato', sans-serif;
          letter-spacing: inherit;
          background: none;
          border: none;
        }
`,
    ];
  }

  constructor() {
    super();

    this.customCondition = null;
    this.employerName = this.localize('apply-activity.employer');
    this.language = 'en';
    this.providerName = this.localize('apply-activity.provider');

    this._isAccepted = false;

    this._acceptTermsChecked = false;
    this._customConditionChecked = false;
    this._policyComplianceChecked = false;
    this._shareDataChecked = false;
    this._shareLearnerDataChecked = false;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      <div class="conditions-checkbox-container">
        <h2 id="terms-title" class="d2l-skeletize d2l-heading-2">
          ${this.localize('apply-activity.agree.title')}
        </h2>
        ${this._standardConditionsTemplate()}
        ${this._customConditionTemplate()}
      </div>
      ${this._tosDialogTemplate()}
    `;
  }

  /**
   * @param {string} id - checkbox id for state management
   * @param {string} stringId - used to find corresponding string in lang files
   * @param {boolean} isTosButton - true if term is to be wrapped with a button to open the terms and conditions
   * @returns {import('lit').TemplateResult}
   */
  _checkboxTemplateFactory(id, stringId, isTosButton = false) {
    const labelText = this.localize(stringId, { employer: this.employerName, provider: this.providerName });
    const checked = `_${id}Checked`;

    const buttonLabel = html`
      ${labelText}
      <button class="d2l-skeletize d2l-link terms-button" @click=${this._openDialog}>
        ${this.localize('apply-activity.terms.link')}
      </button>
    `;

    return html`
      <d2l-input-checkbox
        id=${id}
        @change=${this._termsCheckboxChanged}
        .checked=${this[checked]}
        .skeleton=${this.skeleton}
        required>
        ${isTosButton ? buttonLabel : labelText}
      </d2l-input-checkbox>
    `;
  }

  _closeDialog() {
    this.showTOS.opened = false;
  }

  _checkboxesAccepted() {
    return this._acceptTermsChecked
      && (!this.customCondition?.isEnabled || this._customConditionChecked)
      && this._policyComplianceChecked
      && this._shareDataChecked
      && this._shareLearnerDataChecked;
  }

  /**
   * @returns {import('lit').TemplateResult}
   */
  _customConditionTemplate() {
    if (!this.customCondition?.isEnabled) return nothing;
    return html`
      <p class="d2l-label-text d2l-skeletize">
        ${this.localize('apply-activity.customCondition')}
      </p>
      <d2l-input-checkbox
        id="customCondition"
        @change=${this._termsCheckboxChanged}
        ?skeleton=${this.skeleton}
        .checked=${this._customConditionChecked}>
        ${this.customCondition.value}
      </d2l-input-checkbox>
    `;
  }

  _dispatchChangeEvent(termsAccepted) {
    document.dispatchEvent(new CustomEvent('nova-application-terms-changed', {
      detail: { termsAccepted: termsAccepted },
      bubbles: true,
      composed: false,
    }));
  }

  get showTOS() {
    if (!this._showTOS) this._showTOS = this.shadowRoot.getElementById('show-tos-dialog');
    return this._showTOS;
  }

  _openDialog() {
    this.showTOS.opened = true;
  }

  _standardConditionsTemplate() {
    const terms = [
      { id: 'shareData', stringId: 'apply-activity.consent' },
      { id: 'shareLearnerData', stringId: 'apply-activity.consent.learner' },
      { id: 'policyCompliance', stringId: 'apply-activity.compliance' },
      { id: 'acceptTerms', stringId: 'apply-activity.terms.agree', anchored: true },
    ];
    return terms.map(term => this._checkboxTemplateFactory(term.id, term.stringId, term?.anchored));
  }

  _termsCheckboxChanged(e) {
    const targetKey = `_${e.target.id}Checked`;
    this[targetKey] = !this[targetKey];

    const newAccept = this._checkboxesAccepted() && !this._isAccepted;
    const newDecline = !this._checkboxesAccepted() && this._isAccepted;

    if (newAccept || newDecline) {
      this._dispatchChangeEvent(newAccept);
    }
    this._isAccepted = this._checkboxesAccepted();
  }

  _tosDialogTemplate() {
    return html`
      <d2l-dialog id="show-tos-dialog" title-text="${this.localize('apply-activity.tos')}">
        <iframe
          src="../../assets/tos/terms-of-use-${this.language === 'fr' ? 'fr' : 'en'}.html"
          frameborder="0"
          scrolling="auto"
          aria-live="assertive"
          class="tos">
        </iframe>
        <d2l-button
          slot="footer"
          @click=${this._closeDialog}
          data-dialog-action>
          ${this.localize('apply-activity.buttonClose')}
        </d2l-button>
      </d2l-dialog>
    `;
  }
}

window.customElements.define('application-terms-checkboxes', ApplicationTermsCheckboxes);
