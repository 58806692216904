import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dropdown/dropdown-button-subtle.js';
import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui-labs/pagination/pagination.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/menu/menu';
import '@brightspace-ui/core/components/menu/menu-item';

import './application-table-card.js';
import './application-table-header.js';
import './application-table-row.js';
import '../../../shared/components/general/no-results.js';
import './application-filter.js';
import '../general/nova-button-subtle.js';

import {
  bodySmallStyles,
  heading2Styles,
  labelStyles
} from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { novaLottie } from '../../../shared/mixins/nova-lottie.js';

import ApplicationHelper from '../../helpers/application.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { novaTableStyles } from './application-table-styles.js';

const mediaQueryList = window.matchMedia('(max-width: 767px)');

class ApplicationTable extends LocalizeNova(SkeletonMixin(RequesterMixin(novaLottie(nav(LitElement))))) {

  static get properties() {
    return {
      applications: { type: Array },
      pageNumber: { type: Number, attribute: false },
      applicationsPerPage: { type: Number, attribute: false },
      totalApplications: { type: Number, attribute: false },
      heading: { type: String },
      _searchString: { type: String, attribute: false },
      _suggestions: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      labelStyles,
      bodySmallStyles,
      heading2Styles,
      offscreenStyles,
      novaTableStyles,
      css`
        :host {
          display: block;
        }

        .missing-results {
          color: var(--d2l-color-ferrite);
          font-weight: bold;
          font-size: 19px;
        }

        .heading-wrapper {
          display: grid;
          grid-template-columns: 1fr minmax(200px, auto);
          width: 100%;
        }

        .search-filter-export-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
          padding-bottom: 24px;
          border-bottom: 1px solid var(--d2l-color-gypsum);
        }

        .filter-export-wrapper {
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
        }

        .header-title {
          margin: 0.8rem 0;
        }

        .nova-table {
          margin-top: 1rem;
        }

        .header-btns-wrapper {
          display: flex;
          align-items: center;
          justify-content: end;
        }

        .submit-own-request-btn {
          margin-right: -0.6rem;
        }

        #search {
          width: 35vw;
          max-width: 340px;
        }

        .search-label {
          display: inline-block;
          max-width: 340px;
          padding-bottom: 12px;
        }

        .export-wrapper > d2l-button,
        .export-wrapper > d2l-button-subtle {
          width: fit-content;
        }

        application-filter {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        @media (max-width: 767px) {

          .activity-search {
            display: grid;
            width: 100%;
            margin-bottom: 12px;
          }

          .header-btns-wrapper {
            align-self: center;
            justify-self: end;
          }

          .search-filter-export-wrapper {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 0;
            border-bottom: none;
          }

          .filter-export-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
          }

          .nova-table {
            margin-top: 10px;
          }

          #search {
            width: 100%;
            max-width: none;
          }

          .search-wrapper {
            z-index: auto;
            width: 100%;
          }

          d2l-button-subtle {
            display: unset;
          }

          application-filter {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.applications = [];
    this.aggregations = {};
    this.applicationsPerPage = 10;
    this.totalApplications = 0;
    this.pageNumber = 1;
    this.skeleton = true;
    this._suggestions = ['me'];
    this._searchString = '';
    this.appliedRequestFilters = {};
    this.appHelper = new ApplicationHelper();
    this._handleResize = this._handleResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    if (mediaQueryList.addEventListener) mediaQueryList.addEventListener('change', this._handleResize);
  }

  disconnectedCallback() {
    if (mediaQueryList.removeEventListener) mediaQueryList.removeEventListener('change', this._handleResize);
    super.disconnectedCallback();
  }

  firstUpdated() {
    this._updateTable();
    this.skeleton = false;
    this._suggestions = this._refreshSuggestions();
    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = '../../assets/img/csv-export-animation.json';
    this.loop = true;
    this.autoPlay = true;
    super.firstUpdated();
  }

  render() {
    const persona = this.session?.user?.getPersona();
    return html`
      <div class="heading-wrapper">
        <div class="app-table-header">
          <h2 class="d2l-heading-2 header-title">${this.heading}</h2>
        </div>
        ${this._headerButtonsTemplate}
      </div>
      <label class="d2l-body-small search-label">${this.localize(`application-table.${this.session.tenant.learnerTerminology}.search.label`)}</label>
      <div class="search-filter-export-wrapper">
        <div class="filter-export-wrapper">
          <application-filter
            ?disabled="${this.totalApplications <= 0}"
            .aggregations=${this.aggregations}
            .persona=${persona}
            @page-changed="${this._pageChanged}"
            @filter-changed=${this._applyDropdownFilters}>
          </application-filter>
        </div>
      </div>
      <div class="nova-table" role="table">
        ${this._tableHeaderTemplate}
        ${this._tableContentTemplate}
      </div>
      ${this._paginationTemplate}
    `;
  }

  get _applications() {
    return this.applications?.slice(0, this.applicationsPerPage) || [];
  }

  get _hasContentTemplate() {
    const persona = this.session.user.getPersona();
    const tenant = this.session.tenant;
    return html`${repeat(this._applications,
      application => application.id,
      application => html`
        <application-table-row ?skeleton=${this.skeleton} .application=${application} .persona=${persona} .tenant=${tenant}></application-table-row>
        <application-table-card ?skeleton=${this.skeleton} .application=${application} .persona=${persona} .tenant=${tenant}></application-table-card>
      `
    )}`;
  }

  get _headerButtonsTemplate() {
    const isAllowedSubmitOwnRequest = this.session?._tenant?.tags?.tagValues.includes('ownRequestSubmit');
    const submitOwnRequestBtn = isAllowedSubmitOwnRequest ? html`
      <d2l-button-subtle
        class="submit-own-request-btn"
        text="${this.localize('application-table.submitOwn')}"
        @click=${this._goToSubmitOwnRequest}>
      </d2l-button-subtle>
  ` : nothing;
    const desktopTemplate = html`
      <div class="header-btns-wrapper">
        ${submitOwnRequestBtn}

      </div>
    `;

    return !isAllowedSubmitOwnRequest ? desktopTemplate : (!mediaQueryList.matches ? desktopTemplate : this._mobileHeaderButtonsTemplate);
  }

  get _maxPageNumber() {
    return Math.ceil(this.totalApplications / this.applicationsPerPage);
  }

  get _mobileHeaderButtonsTemplate() {
    return html`
      <div class="header-btns-wrapper">
        <d2l-dropdown-button-subtle text="More" mobile-tray="right">
          <d2l-dropdown-menu>
            <d2l-menu label="header menu">
              <d2l-menu-item text="${this.localize('application-table.submitOwn')}" @click=${this._goToSubmitOwnRequest}></d2l-menu-item>
            </d2l-menu>
          </d2l-dropdown-menu>
        </d2l-dropdown-button-subtle>
      </div>
    `;
  }

  get _noContentTemplate() {
    if (this._searchString) {
      return html`
        <no-results id="empty-requests" image-type="list">
          ${this.localize('activities.view.noResults.prompt.1')}
          <div class="missing-results">${this._searchString}</div>
          ${this.localize('application-table.table.noResults')}
        </no-results>
      `;
    }

    return html`
      <div class="d2l-skeletize table-row-empty" role="row" id="empty-requests">
        <div class="table-item" role="cell">${this.localize('application-table.table.empty')}</div>
      </div>`;
  }
  get _paginationTemplate() {
    return this._maxPageNumber > 1
      ? html`
        <d2l-labs-pagination
          id="pagination"
          .skeleton=${this.skeleton}
          @pagination-page-change=${this._pageChanged}
          .pageNumber=${this.pageNumber}
          max-page-number=${this._maxPageNumber}
          selected-count-option="20">
        </d2l-labs-pagination>`
      : nothing;
  }

  get _tableContentTemplate() {
    return this.totalApplications > 0
      ? this._hasContentTemplate
      : this._noContentTemplate;
  }

  get _tableHeaderTemplate() {
    const persona = this.session.user.getPersona();
    const tenant = this.session.tenant;
    return html`
      <application-table-header .persona=${persona} .tenant=${tenant} @request-sort-event=${this._applySort}></application-table-header>
    `;
  }

  _applyDropdownFilters(e) {
    let activities = [], statuses = [], employers = [], providers = [];
    if (e && e.target) {
      activities = e.detail.courses;
      statuses = e.detail.appStatus;
      employers = e.detail.employer;
      providers = e.detail.provider;
      this._searchString = e.detail.searchString;
    }
    this.appliedRequestFilters = { activities, statuses, employers, providers, searchString: this._searchString };

    const ce = new CustomEvent('application-table-filter-change', {
      detail: {
        filters: this.appliedRequestFilters,
      },
    });
    this.dispatchEvent(ce);
    this._updateTable();
  }

  _pageChanged(e) {
    this.pageNumber = e.detail.page;
    const ce = new CustomEvent('application-table-page-change', {
      detail: {
        pageNumber: e.detail.page,
      },
    });
    this.dispatchEvent(ce);
  }

  _applySort(e) {
    if (e && e.detail) {
      this.currentSortingColumn = e.detail;
    }
    const { colName, order } = this.currentSortingColumn;

    this.pageNumber = 1;
    const ce = new CustomEvent('application-table-sort', {
      detail: {
        sort: {
          colName,
          order,
        },
      },
    });
    this.dispatchEvent(ce);
  }

  _autocompleteFilter(value, filter) {
    return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  }

  _goToSubmitOwnRequest() {
    this.navigate('requests/submit-own-request');
  }

  _handleResize() {
    this.requestUpdate();
  }

  _refreshSuggestions() {
    const suggestions = new Set();
    for (const application of this.applications) {
      if (application.user.getDisplayName()) suggestions.add(application.user.getDisplayName());
      if (application.slug) suggestions.add(application.slug);
      if (application.activity.title) suggestions.add(application.activity.title);
    }
    const result = [];
    suggestions.forEach(value => result.push({ value }));
    return result;
  }

  _updateTable() {
    this.pageNumber = 1;
  }
}

window.customElements.define('application-table', ApplicationTable);
