import { css } from 'lit';

export default css`
  :host {
    display: block;
  }
  skill-chip-list {
    display: block;
  }
  .main-heading {
    margin-top: 0;
  }
  #edit-form > *:not(:last-child) {
    margin-bottom: 18px;
  }
  #edit-form > :first-child {
    margin-top: 18px;
  }
  #provider {
    width: 100%;
  }
  .select-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }
  .select-wrapper > label {
    width: 100%;
  }
  .checkbox-fieldset {
    margin: 0;
    padding: 0 6px;
    border: none;
  }
  .checkbox-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
  }
  .fieldset-error {
    color: var(--d2l-color-cinnabar);
    border: 1px solid;
    border-radius: var(--d2l-input-border-radius, 0.3rem);
  }
  .fieldset-error > p {
    margin: 2px 0;
    font-size: 14px;
  }
  .inlineCheckbox {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .course-select {
    max-width: 400px;
  }
  .course-item {
    width: 100%;
  }
  .skills-wrapper h5,
  .skills-wrapper p {
    margin-bottom: 6px;
  }
  .skills-wrapper hr {
    margin-top: 32px;
  }
  .skills-wrapper h4 {
    margin-bottom: 12px;
  }
  d2l-floating-buttons {
    margin-top: 24px;
  }
  .date-list {
    max-height: 40vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  .admission-requirements-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .admission-alert {
    max-width: fit-content;
  }

  .admission-alert span {
    font-size: 0.8rem;
  }

  .admission-requirements-container {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding-bottom: 18px;
  }

  .admission-requirement-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 18px;
    background-color: var(--d2l-color-sylvite);
    border: 1px solid var(--d2l-color-mica);
    border-radius: 8px;
  }

  .admission-panel-summary {
    margin-top: 0;
  }

  .admission-inputs {
    display: flex;
    gap: 18px;
  }
  .admission-input {
    max-width: 224px;
  }
  .changable-label {
    display: flex;
    align-items: center;
  }

  .changable-label h4 {
    margin: 0;
  }
  .admission-cancel-button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
