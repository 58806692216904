import '@brightspace-ui/core/components/alert/alert-toast.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-percent.js';

import '../../../shared/components/tooltip/nova-tooltip.js';

import { css, html, LitElement, nothing } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { APPROVAL_MODELS, BUDGET_CONFIGURATIONS } from '../../../../shared/models/schema/tenant/index.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { NovaFormMixin } from '../../../shared/mixins/nova-form-mixin.js';
import { SUPPORTED_BUDGET_HOLDERS } from '../../../../shared/constants.js';

const { CORPORATE_POLICY, CASE_BY_CASE, PERCENT_COVERAGE } = BUDGET_CONFIGURATIONS;

export default class ManageBudget extends NovaFormMixin(LocalizeNova(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      tenant: { type: Object },
      _buttonText: { type: String, attribute: false },
      _buttonDisabled: { type: Boolean, attribute: false },
      _confirmationHidden: { type: Boolean, attribute: false },
      _initialPercentCoverage: { type: Number, attribute: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      buttonStyles,
      radioStyles,
      selectStyles,
      inputLabelStyles,
      css`
        d2l-input-date {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }

        #submit {
          padding-top: 0.9rem;
          padding-bottom: 2.4rem;
        }

        d2l-input-number {
          padding-bottom: 1.5rem;
        }

        #percentCoveragePerRequest {
          --d2l-input-text-align: end;
        }

        .select-wrapper {
          margin: 1.5rem 0;
        }
`,
    ];
  }

  constructor() {
    super();
    this._buttonText = 'manage-budget-approvals.saveButton';
    this._buttonDisabled = false;
    this._confirmationHidden = true;
  }

  get operatingBudgetHolderTemplate() {
    const holdersToShow = SUPPORTED_BUDGET_HOLDERS;

    const holdersKeys = Object.keys(holdersToShow);
    const sortedHoldersKeys = holdersKeys.sort((a, b) => {
      return holdersToShow[a] > holdersToShow[b] ? 1 : -1;
    });

    if (!this.tenant.budget.budgetHolder) {
      this.tenant.budget.budgetHolder = Object.keys(SUPPORTED_BUDGET_HOLDERS)[0];
      this._dispatchUpdateTenant();
    }

    return html`
      <div class="select-wrapper">
        <label for="budgetHolder" class="d2l-input-label d2l-input-label-required">Budget holder</label>
        <select
          id="budgetHolder"
          name="budgetHolder"
          class="d2l-input-select"
          required>
          ${repeat(sortedHoldersKeys, holderKey => html`
            <option
              id="${holderKey}"
              value="${holderKey}"
              ?selected=${this.tenant.budget.budgetHolder === holderKey}>
              ${holdersToShow[holderKey]}
            </option>`)}
        </select>
      </div>`;
  }

  get operatingCurrencyTemplate() {
    const currenciesToShow = this.tenant.visibleCurrencies;
    const currencyKeys = Object.keys(currenciesToShow);
    const sortedCurrencyKeys = currencyKeys.sort((a, b) => {
      return currenciesToShow[a] > currenciesToShow[b] ? 1 : -1;
    });

    return html`
      <div class="select-wrapper">
        <label for="operatingCurrency" class="d2l-input-label d2l-input-label-required">
          Operating Currency
        </label>
        <select
          id="operatingCurrency"
          name="operatingCurrency"
          class="d2l-input-select"
          required>
          ${repeat(sortedCurrencyKeys, currencyCode => html`
            <option
              id="${currencyCode}"
              value="${currencyCode}"
              ?selected=${this.tenant.showableOperatingCurrency === currencyCode}>
              ${currencyCode} (${currenciesToShow[currencyCode]})
            </option>`)}
        </select>
      </div>`;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  firstUpdated() {
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = true;

    if (!this.tenant?.budget) {
      console.error('manage-budget-approvals component: "tenant" property with a "budget" is required to use this component.');
      return;
    }

    this.tenant.budget.percentCoveragePerRequest = this.tenant.budget.percentCoveragePerRequest ?? 100;
    this._initialPercentCoverage = this.tenant.budget.percentCoveragePerRequest;
  }

  render() {
    return html`
      <h3>${this.localize('manage-budget-approvals.budget.title.label')}</h3>
      <p class="d2l-body-standard">${this.localize('manage-budget-approvals.subtitle')}</p>
      <d2l-form @change=${this._changeValue}>
        <label class="d2l-input-radio-label d2l-input-radio-label-required">
          <input
            type="radio"
            name="budgetSetting"
            id="${CORPORATE_POLICY}"
            required
            ?checked=${this.tenant.budget.type === CORPORATE_POLICY}>
          ${this.localize(`manage-budget-approvals.corporatePolicy.${this.tenant.learnerTerminology}`)}
        </label>

        <label class="d2l-input-radio-label d2l-input-radio-label-required">
          <input
            type="radio"
            name="budgetSetting"
            id="${PERCENT_COVERAGE}"
            required
            ?checked=${this.tenant.budget.type === PERCENT_COVERAGE}>
              ${this.localize('manage-budget-approvals.percentCoverage')}
        </label>
        <d2l-input-number
          id="percentCoveragePerRequest"
          input-width="12rem"
          .value="${this._initialPercentCoverage}"
          min="0"
          max="100"
          unit="%"
          label=${this.localize('manage-budget-approvals.percentCoverage.label')}
          ?hidden="${!(this.tenant.budget.type === PERCENT_COVERAGE)}"
          ?required="${this.tenant.budget.type === PERCENT_COVERAGE}"></d2l-input-number>

        <label class="d2l-input-radio-label d2l-input-radio-label-required">
          <input
            type="radio"
            name="budgetSetting"
            id="${CASE_BY_CASE}"
            required
            ?checked=${this.tenant.budget.type === CASE_BY_CASE}>
          ${this.localize('manage-budget-approvals.caseByCase')}
        </label>
        <d2l-input-date
          id="fiscalYearStart"
          aria-required="true"
          required
          .value="${this.tenant.budget.fiscalYearStart}"
          label="${this.localize('manage-budget-approvals.fiscalStartDate.label')}"></d2l-input-date>
        <d2l-input-number
          id="allowableCredit"
          type="number"
          min="0"
          min-exclusive
          input-width="12rem"
          .value="${this.tenant.budget.allowableCredit}"
          label="${this.localize(`manage-budget-approvals.${this.tenant.learnerTerminology}.annualCredit.label`)}"
          ?hidden="${!(this.tenant.budget.type === CORPORATE_POLICY)}"
          ?required="${this.tenant.budget.type === CORPORATE_POLICY}"></d2l-input-number>
        <h4>${this.localize('manage-budget-approvals.approvals.title.label')}</h4>
        <d2l-input-fieldset
            id="approvalModel"
            label="${this.localize('manage-budget-approvals.approvals.title.label')}">
          ${repeat(Object.keys(APPROVAL_MODELS), approvalModel => html`
            <label class="d2l-input-radio-label">
              <input
                type="radio"
                name="approval-model-group"
                .value=${approvalModel}
                ?checked=${this.tenant.approvalModel === approvalModel}>
              ${this.localize(`tenant.approvalModel.${approvalModel}`)}
            </label>
          `)}
        </d2l-input-fieldset>

        ${this.tenant.approvalModel === 'hybrid' && this.tenant.budget.type !== CORPORATE_POLICY ? this.operatingBudgetHolderTemplate : nothing }
        ${this.operatingCurrencyTemplate}
      </d2l-form>
      <div id="submit-general" class="submit-buttons">
        <d2l-button id="submit" ?disabled=${this._buttonDisabled} @click=${this._saveBudgetApprovalsConfig} primary>${this.localize(this._buttonText)}</d2l-button>
        <p id="confirmation" class="d2l-body-small" ?hidden=${this._confirmationHidden}>${this.localize('manage-budget-approvals.caseByCase.confirmation')}</p>
      </div>
    `;
  }

  _changeValue(e) {
    this._buttonDisabled = false;
    this._buttonText = 'manage-budget-approvals.saveButton';
    this._confirmationHidden = true;

    if (['fiscalYearStart', 'allowableCredit', 'percentCoveragePerRequest', 'budgetHolder'].includes(e.target.id)) {
      this.tenant.budget[e.target.id] = e.target.value;
    } else if (['operatingCurrency'].includes(e.target.id)) {
      this.tenant[e.target.id] = e.target.value;
    } else if (e.target.name === 'approval-model-group') {
      this.tenant.approvalModel = e.target.value;
    } else {
      this.tenant.budget = { ...this.tenant.budget, type: e.target.id };
    }
    this.updateFormErrorSummary();
    this._dispatchUpdateTenant();
  }

  _dispatchUpdateTenant() {
    this.dispatchEvent(new CustomEvent('update-tenant', { detail: { tenant: this.tenant } }));
  }

  async _saveBudgetApprovalsConfig() {
    const isFormValidated = await this.isFormValidated();
    if (!isFormValidated) return;

    let type = 'default';
    let message = this.localize('manage-budget-approvals.savedToast');

    try {
      await this.client.updateBudget(this.tenant);
    } catch (e) {
      type = 'critical';
      message = await e.text();
      this.session.toast({ type, message });
      return;
    }

    this._buttonDisabled = true;
    this._buttonText = 'manage-budget-approvals.saveButton.saved';

    if (this.tenant.budget.type === CASE_BY_CASE) {
      this._confirmationHidden = false;
    }

    this.session.toast({ type, message });
  }

}

window.customElements.define('manage-budget-approvals', ManageBudget);
