import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';

import { css, html, LitElement } from 'lit';
import { LocalizeNova } from '../../mixins/localize-nova.js';

class InPlaceDropdown extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      editable: { type: Boolean },
      saving: { type: Boolean },
      submitDisabled: { type: Boolean },
      buttonLabel: { type: String, attribute: 'button-label', reflect: true },
    };
  }

  static get styles() {
    return [
      css`
        .content-editable {
          display: inline-block;
          border: 1px solid transparent;
          cursor: pointer;
        }

        .content-editable:hover {
          border: 1px solid black;
          border-radius: 6px;
        }

        .spinner-wrapper {
          display: grid;
          place-items: center;
        }

        #saveCost {
          margin-top: 10px;
        }
`,
    ];
  }

  constructor() {
    super();
    this.saving = false;
    this.submitDisabled = false;
    this.buttonLabel = this.localize('in-place-dropdown.buttonSave');
  }

  render() {
    if (!this.editable) return html`<slot name="content"></slot>`;
    return html`
      <d2l-dropdown id="in-place-dropdown" class="amount amount-edit" @click="${this._stopPropagation}">
        <slot role="button" class="content-editable" name="content" @click="${this._handleClick}"></slot>
        <d2l-dropdown-content id="dropdown" class="dropdown-content">
        ${this.saving ? html`
          <div class="spinner-wrapper"><d2l-loading-spinner></d2l-loading-spinner></div>
        ` : html`
          <slot name="edit-form"></slot>
          <d2l-button id="saveCost" @click=${this._saveClicked} .disabled="${this.submitDisabled}">${this.buttonLabel}</d2l-button>
        `}
        </d2l-dropdown-content>
      </d2l-dropdown>
    `;
  }
  async save(promise) {
    this.toggleSaving();
    await promise;
    this.toggleSaving();
  }

  toggleSaving() {
    this.saving = !this.saving;
    if (!this.saving) this.shadowRoot.getElementById('in-place-dropdown').toggleOpen();
  }

  _handleClick(e) {
    e.preventDefault();
    this.shadowRoot.getElementById('in-place-dropdown').toggleOpen();
  }

  _saveClicked(e) {
    if (!this.editable) return;
    e.stopPropagation();
    e.preventDefault();
    const saveEvent = new CustomEvent('nova-in-place-save', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(saveEvent);
  }

  _stopPropagation(e) {
    e.stopPropagation();
  }

}

window.customElements.define('in-place-dropdown', InPlaceDropdown);
