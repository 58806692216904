import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/expand-collapse/expand-collapse-content.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { LocalizeNova } from '../../mixins/localize-nova.js';

class FAQList extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      faqs: { type: Array },
    };
  }

  static get styles() {
    return [
      linkStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      css`
        .faq {
          margin: 4rem auto 0 auto;
        }

        .faq-title {
          width: 70vw;
          color: var(--d2l-color-ferrite);
          font-weight: bold;
        }

        .faq-panel {
          margin-top: 0.5rem;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  get defaultFAQs() {
    const defaultFaqs = [];
    const links = {
      'dpa-link': this.l10nTags.d2lLink('https://www.d2l.com/legal/data-processing-addendum/', false),
      'privacy-link': this.l10nTags.d2lLink('https://www.d2l.com/legal/privacy/', false),
    };

    let i = 1;
    while (this.localize(`defaultFAQs.q${i}`)) {
      let answer = this.localize(`defaultFAQs.a${i}`, links);
      if (answer.length === 0) answer = this.localize(`defaultFAQs.a${i}.${this.session.tenant.learnerTerminology}`, links);
      defaultFaqs.push({
        question: this.localize(`defaultFAQs.q${i}`),
        answer: answer,
      });

      i++;
    }
    return defaultFaqs;
  }

  render() {
    const faqs = this.faqs || this.defaultFAQs;
    return html`
      <div class="faq">
        <h2 class="faq-title">${this.localize('faqs.title')}</h2>
        ${repeat(faqs, faq => faq.question, faq => html`
        <d2l-collapsible-panel class="faq-panel" panel-title="${faq.question}">
          <p>${unsafeHTML(faq.answer)}</p>
        </d2l-collapsible-panel>
        `)}
      </div>
    `;
  }
}

window.customElements.define('faq-list', FAQList);
