import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui-labs/multi-select/attribute-picker.js';

import { css, html, LitElement, nothing } from 'lit';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import {
  TAXABLE_CA_PROVINCES,
  TAXABLE_COUNTRIES,
  TAXABLE_US_STATES
} from '../../../shared/constants.js';

import { LocalizeNova } from '../../shared/mixins/localize-nova.js';

export default class TaxNexusInput extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      taxNexus: { type: Object, reflect: false },
      _selectedRegistration: { type: String, reflect: false },
      _editingRegistration: { type: String, reflect: false },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      selectStyles,
      css`
        .tax-registration-list {
          width: 100%;
        }
        .submit-button {
          padding-top: 10px;
        }

        .canada-extra-regs {
          margin-top: 1rem;
        }
        .us-extra-regs {
          height: 200px;
          margin-bottom: 3rem;
        }
        .tax-reg-country {
          font-weight: bold;
        }
        .tax-registration-item-content {
          display: grid;
          grid-template-columns: 150px 1fr;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  get taxRegistrationDialog() {
    return this.shadowRoot.getElementById('tax-registration-dialog');
  }

  _removeTaxRegistration(e) {
    this._selectedRegistration = null;
    this._editingRegistration = null;
    delete this.taxNexus.registrations[e.target.id];
    this.update();
  }

  _editTaxRegistration(e) {
    if (!this.taxNexus.registrations[e.target.id]) return;
    this._selectedRegistration = e.target.id;
    const jurisdictions = this.taxNexus.registrations[this._selectedRegistration].jurisdictions;
    this._editingRegistration = { jurisdictions: [...jurisdictions] };
    this.taxRegistrationDialog.opened = true;
  }

  _addTaxRegistration() {
    this._selectedRegistration = '';
    this._editingRegistration = {
      jurisdictions: [],
    };
    this.taxRegistrationDialog.opened = true;
  }

  _statesChanged(e) {
    this._editingRegistration.jurisdictions = e.detail.attributeList.map(a => a.value);
    this.taxRegistrationDialog.resize();
  }

  _checkboxChanged(e) {
    const province = e.target.id;
    if (e.target.checked) {
      this._editingRegistration.jurisdictions.push(province);
    } else {
      this._editingRegistration.jurisdictions = this._editingRegistration.jurisdictions.filter(j => j !== province);
    }
  }

  _applyRegistrationChanges() {
    this.taxNexus.registrations[this._selectedRegistration] = this._editingRegistration;

    const sortedRegistrations = {};
    for (const cc of Object.keys(this.taxNexus.registrations).sort()) {
      sortedRegistrations[cc] = this.taxNexus.registrations[cc];
    }
    this.taxNexus.registrations = sortedRegistrations;
    this._dispatchUpdateEvent();
    this.update();
  }

  _dispatchUpdateEvent() {
    document.dispatchEvent(new CustomEvent('nova-tax-nexus-updated', {
      detail: { taxNexus: this.taxNexus },
      bubbles: true,
      composed: false,
    }));
  }

  _countryChanged(e) {
    this._selectedRegistration = e.target.value;
    this._editingRegistration = {
      jurisdictions: this._selectedRegistration === 'CA' ? ['Canada'] : [], // For canadian registrations, we need to add the federal jurisdiction of 'Canada' to the list
    };
    this.taxRegistrationDialog.resize();
  }

  _registrationDialogClose() {
    this._selectedRegistration = null;
    this._editingRegistration = null;
  }

  get _registrationDialogTemplate() {
    const editMode = this.taxNexus.registrations[this._selectedRegistration] ? 'edit' : 'add';
    // Set the template for each o
    let stateProvinceTemplate = nothing;
    if (this._selectedRegistration === 'CA') {
      stateProvinceTemplate = html`
        <label class="d2l-input-label canada-extra-regs">
          ${this.localize('admin.tax-nexus.other-registrations')}
        </label>
        <div class="province-checkboxes" @change="${this._checkboxChanged}">
          ${TAXABLE_CA_PROVINCES.map(province => html`
            <d2l-input-checkbox id=${province.value} ?checked="${this._editingRegistration.jurisdictions.find(j => j === province.value)}">${province.name}</d2l-input-checkbox>
            `)}
        </div>
      `;
    } else if (this._selectedRegistration === 'US') {
      stateProvinceTemplate = html`
        <div class="us-extra-regs">
          <label for="tax-registration-state" class="d2l-input-label">
            ${this.localize('admin.tax-nexus.other-registrations')}
          </label>
            <d2l-labs-attribute-picker
              id="tax-registration-state"
              @d2l-attributes-changed="${this._statesChanged}"
              .attributeList="${this._editingRegistration.jurisdictions.map(value => ({ name: value, value }))}"
              .assignableAttributes="${TAXABLE_US_STATES}">
            </d2l-labs-attribute-picker>
        </div>
      `;
    }

    const countryOptions = Object.keys(TAXABLE_COUNTRIES).map(country => {
      const disabled = this.taxNexus.registrations[country] && this._selectedRegistration !== country;
      return html`<option value="${country}" ?disabled="${disabled}">${this.localize(TAXABLE_COUNTRIES[country])}</option>`;
    });

    return html`
      <d2l-dialog
        id="tax-registration-dialog"
        title-text="Tax registration"
        @d2l-dialog-close="${this._registrationDialogClose}"
      >
        <label for="tax-registration-country" class="d2l-input-label">
            ${this.localize('admin.tax-nexus.registration-country.label')}
        </label>
        <select
          id="tax-registration-country"
          name="tax-registration-country"
          @change="${this._countryChanged}"
          ?disabled="${editMode === 'edit'}"
          .value="${this._selectedRegistration}"
          class="d2l-input-select">
          ${countryOptions.map(option => html`${option}`)}
        </select>
        ${stateProvinceTemplate}
        <div slot="footer">
          <d2l-button id="applyButton" @click="${this._applyRegistrationChanges}" primary data-dialog-action="apply">${this.localize('general.button-text.apply')}</d2l-button>
          <d2l-button id="cancelButton" data-dialog-action>${this.localize('general.button-text.cancel')}</d2l-button>
        </div>
      </d2l-dialog>`;
  }

  async _registrationListActionClicked(e) {
    if (e.target.name === 'add') {
      this._addTaxRegistration();
    }
  }

  get _providerTaxTemplate() {
    if (!this.taxNexus) return nothing;
    const registrations = Object.keys(this.taxNexus.registrations);
    const allRegistrationsApplied = registrations.length === Object.keys(TAXABLE_COUNTRIES).length;
    const tagListTemplate = html`
      <d2l-list
        class="tax-registration-list"
        @d2l-selection-action-click="${this._registrationListActionClicked}">
          <d2l-list-controls slot="controls"
                             no-selection>
            ${allRegistrationsApplied ? nothing : html`
            <d2l-selection-action name="add"
                                  icon="tier1:plus-large"
                                  text="${this.localize('admin.tax-nexus.add-registration.button')}">`}
            </d2l-selection-action>
          </d2l-list-controls>
        ${repeat(registrations, key => html`
          <d2l-list-item label="${this.localize(TAXABLE_COUNTRIES[key])}" key="${key}">

              <d2l-list-item-content>
                <div class="tax-registration-item-content">
                  <div class="tax-reg-country">${this.localize(TAXABLE_COUNTRIES[key])}</div>
                  <div class="tax-reg-jurisdictions">${this.taxNexus.registrations[key].jurisdictions.join(', ')}</div>
                </div>
              </d2l-list-item-content>
              <div class="actions" slot="actions">
                <d2l-button-icon icon="tier1:edit" @click="${this._editTaxRegistration}" id="${key}"></d2l-button-icon>
                <d2l-button-icon  icon="tier1:delete" @click="${this._removeTaxRegistration}" id="${key}"></d2l-button-icon>
              </div>
          </d2l-list-item>
        `)}
      </d2l-list>
    `;

    return html`
      <label class="d2l-input-label">${this.localize('admin.tax-nexus.tax-registration.label')}</label>
      ${tagListTemplate}
      ${this._registrationDialogTemplate}
    `;
  }

  render() {
    return this._providerTaxTemplate;
  }

}

window.customElements.define('tax-nexus-input', TaxNexusInput);
