import './activity-small.js';

import { css, html, LitElement } from 'lit';
import { navigator as nav } from 'lit-element-router';

class ActivitySmallList extends nav(LitElement) {

  static get properties() {
    return {
      activities: { type: Array },
      removeable: { type: Boolean },
    };
  }

  static get styles() {
    return [
      css`
        .activity-small-list-wrapper {
          position: relative;
          width: 100%;
        }

        .pipe {
          position: absolute;
          top: 0;
          left: 50px;
          z-index: 100;
          display: block;
          width: 5px;
          height: 100%;
          background-color: var(--d2l-color-tungsten);
        }

        .course-item {
          position: relative;
          z-index: 200;
          display: block;
          width: 100%;
          cursor: pointer;
        }

        @media (max-width: 615px) {
          .pipe {
            left: 40px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activities = [];
  }

  render() {
    if (!this.activities) {
      return html`Loading activities`;
    }
    return html`
      <div class="activity-small-list-wrapper">
        <div class="pipe"></div>
        <slot name="course-item" class="course-item"></slot>
      </div>
    `;
  }

  remove(activity) {
    this.dispatchEvent(new CustomEvent('course-removed', {
      detail: { activity },
      bubbles: true,
      composed: true,
    }));
  }
}

window.customElements.define('activity-small-list', ActivitySmallList);
