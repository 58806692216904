import '@brightspace-ui/core/components/card/card-content-meta.js';
import '@brightspace-ui/core/components/card/card.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/dropdown/dropdown-more.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/button/button-icon.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodySmallStyles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';

import { Cost } from '../../../../shared/models/cost.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';

const getCostFromInvoiceItem = (invoiceItem, application) => {
  return new Cost({
    ...application.tempApprovedAmount,
    cost: invoiceItem.amount,
    currency: invoiceItem.currency.toUpperCase(),
  });
};

class TransactionDetailsComponent extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      application: { type: Object },
      providerCurrency: { type: String },
      invoiceItems: { type: Array },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading4Styles,
      super.styles,
      css`
        :host {
          display: inline-block;
          border-radius: 6px;
          cursor: pointer;
          transition: width 0.5s;
        }

        nova-card {
          width: 100%;
          height: 100%;
        }

        div[slot="content"] {
          margin: -0.6rem -0.2rem;
        }

        .section-header {
          margin-bottom: 0;
        }

        .main-header {
          margin-top: 0;
        }

        .stripe-session-id-container {
          display: flex;
          justify-content: flex-start;
        }

        .location-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .tax-rates-container {
          display: flex;
        }
`,
    ];
  }

  constructor() {
    super();
    this.application = null;
    this.invoiceItems = null;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.invoiceItems = await this.loadInvoiceItems();
  }

  get _header() {
    return html`
      <h3 class="d2l-heading-3 main-header">${this.localize('application-transaction-overview.header')}</h3>
    `;
  }

  get invoicedState() {
    return this.invoiceItems && this.application.transaction.employer && this.application.transaction.provider && this.application.transaction.revShare;
  }

  _copyAndShowToast(clipboardValue, toastLocalizationKey) {
    return async() => {
      await navigator.clipboard.writeText(clipboardValue);
      this.session.toast({ type: 'default', message: this.localize(toastLocalizationKey) });
    };
  }

  get _locationTaxDetails() {
    const taxData = Object.keys(this.application.transaction.tax).map(t => {
      return { key: t, value: this.application.transaction.tax[t] };
    }).filter(obj => obj.key !== 'rates');

    let taxRatesSection;

    const taxRates = this.application.transaction.tax['rates'];

    if (taxRates && taxRates.length) {

      const mergedRates = taxRates.join(' ');

      const dataRates = [{
        key: 'rates',
        value: this.truncateString(mergedRates, 15),
      }];

      taxRatesSection = html`
      <div class="tax-rates-container">
      <detail-list .data=${dataRates} key-aligned></detail-list>
      <d2l-button-icon
        icon="tier2:copy"
        @click=${this._copyAndShowToast(mergedRates, 'admin.tax-details.rates.copied')}
        text="Copy Tax Rates">
      </d2l-button-icon>
      </div>
      `;
    }

    const taxSection = this.application.taxable ? html`
      <div>
        <h4 class="section-header">Tax Details</h4>
        <detail-list .data=${taxData} key-aligned></detail-list>
        ${taxRatesSection ? taxRatesSection : nothing}
      </div>
    ` : nothing;

    const locationData = Object.keys(this.application.transaction.location).map(t => {
      return { key: t, value: this.application.transaction.location[t] };
    });

    return html`
      <div class="location-section">
        <div>
          <h4 class="section-header">Location Details</h4>
          <detail-list .data=${locationData} key-aligned></detail-list>
        </div>
        ${taxSection}
      </div>
    `;
  }

  get _costPaymentDetails() {
    const activityCost = this.application.getActivityCost();
    const taxAmount = this.application.taxAmount();
    const lang = this.session.user.getSetting('language');

    const data = [
      {
        key: 'Price (before tax)',
        value: activityCost.formatAsDecimal(lang),
      },
      {
        key: 'Tax Amount',
        value: taxAmount.formatAsDecimal(lang),
      },
      {
        key: 'Price (after tax)',
        value: activityCost.add(taxAmount).formatAsDecimal(lang),
      },
    ];

    if (this.invoicedState) {
      const invoiceItemCost = getCostFromInvoiceItem(this.invoiceItems.employer, this.application);
      const paidData = [
        {
          key: 'Employer Coverage',
          value: invoiceItemCost.inOriginalCurrency().formatAsDecimal(lang),
        },
        {
          key: 'Paid by Employee',
          value: this.application.getActivityCostWithTax().subtract(this.application.tempApprovedAmount).formatAsDecimal(lang),
        },
        {
          key: 'Payment Date',
          value: this.application.transaction.paymentDate,
        },
      ];
      data.push(...paidData);
    }

    if (this.application.refundPct) {
      data.push({
        key: 'Refund Percent',
        value: this.application.refundPct,
      });
    }

    return html`
        <h4 class="section-header">Payment Details</h4>
        <detail-list .data=${data} key-aligned></detail-list>
    `;
  }

  getInvoiceData(invoiceItem) {
    if (!invoiceItem) return [];
    const invoiceItemCost = getCostFromInvoiceItem(invoiceItem, this.application);
    const data = [
      {
        key: 'Invoice Item ID',
        value: invoiceItem.id,
      },
      {
        key: 'Amount',
        value: invoiceItemCost.formatAsDecimal(this.session.settings.language),
      },
    ];
    if (invoiceItemCost.currency !== invoiceItemCost.originalCurrency) data.push({
      key: 'Conversion Rate Used',
      value: invoiceItemCost.conversionRate,
    });

    return data;
  }

  get _invoiceDetails() {
    return html`
        <h4 class="section-header">Invoice Details</h4>
        ${this._stripSessionId}
        <h4 class="section-header">Employer</h4>
        <detail-list .data=${this.getInvoiceData(this.invoiceItems.employer)} key-aligned></detail-list>
        <h4 class="section-header">Provider</h4>
        <detail-list .data=${this.getInvoiceData(this.invoiceItems.provider)} key-aligned></detail-list>
        <h4 class="section-header">Provider RevShare</h4>
        <detail-list .data=${this.getInvoiceData(this.invoiceItems.revShare)} key-aligned></detail-list>
    `;
  }

  get _invoiceRefundDetails() {
    return html`
      <h4 class="section-header">Invoice Refund Details</h4>
      <h4 class="section-header">Employer Refund</h4>
      <detail-list .data=${this.getInvoiceData(this.invoiceItems?.employerRefund)} key-aligned></detail-list>
      <h4 class="section-header">Provider Refund</h4>
      <detail-list .data=${this.getInvoiceData(this.invoiceItems?.providerRefund)} key-aligned></detail-list>
      <h4 class="section-header">Provider RevShare Refund</h4>
      <detail-list .data=${this.getInvoiceData(this.invoiceItems?.revShareRefund)} key-aligned></detail-list>
    `;
  }

  truncateString(string = '', maxLength = 30) {
    return string.length > maxLength
      ? `${string.substring(0, maxLength)}…`
      : string;
  }

  get _stripSessionId() {
    const stripeSessionId = this.application.transaction?.stripeCheckoutSessionId;
    if (!stripeSessionId) return nothing;
    const sessionIdObj = [{
      key: 'Stripe Session ID',
      value: this.truncateString(stripeSessionId),
    }];

    return html`
    <div class='stripe-session-id-container'>
      <detail-list .data=${sessionIdObj} key-aligned></detail-list>
      <d2l-button-icon
        icon="tier2:copy"
        @click=${this._copyAndShowToast(this.application.transaction.stripeCheckoutSessionId, 'admin.transaction-details.strip-session-id.copied')}
        text="Copy Strip Session ID">
      </d2l-button-icon>
    </div>
    `;
  }

  render() {
    if (this.session.tenant.type !== 'admin') return nothing;
    return html`
      <nova-card>
        <div slot="primary" class="d2l-skeletize">
          ${this._header}
          ${this._locationTaxDetails}
          ${this._costPaymentDetails}
          ${this.invoicedState ? html`${this._invoiceDetails}` : nothing}
          ${this.hasRefundInvoice() ? html`${this._invoiceRefundDetails}` : nothing}
        </div>
      </nova-card>
    `;
  }

  hasRefundInvoice() {
    const hasRefundInvoice = !!this.application.transaction.employerRefund;
    return hasRefundInvoice;
  }

  async loadInvoiceItems() {
    let employer, provider, revShare, employerRefund, providerRefund, revShareRefund, invoiceItems = null;
    const hasInvoice = !!this.application.transaction.employer;
    if (hasInvoice) {
      employer = await this.client.getInvoiceItem(this.application.transaction.employer);
      provider = await this.client.getInvoiceItem(this.application.transaction.provider);
      revShare = await this.client.getInvoiceItem(this.application.transaction.revShare);

      invoiceItems = {
        employer: employer.invoiceItem,
        provider: provider.invoiceItem,
        revShare: revShare.invoiceItem,
      };

      if (this.hasRefundInvoice()) {
        employerRefund = await this.client.getInvoiceItem(this.application.transaction.employerRefund);
        providerRefund = await this.client.getInvoiceItem(this.application.transaction.providerRefund);
        revShareRefund = await this.client.getInvoiceItem(this.application.transaction.providerRevShareRefund);

        invoiceItems.employerRefund = employerRefund.invoiceItem;
        invoiceItems.providerRefund = providerRefund.invoiceItem;
        invoiceItems.revShareRefund = revShareRefund.invoiceItem;
      }
    }
    return invoiceItems;
  }

}

window.customElements.define('transaction-details', TransactionDetailsComponent);
