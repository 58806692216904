import './profile-image.js';
import '../general/nova-card.js';
import '../../../app-link.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';

import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { sortApplication } from '../../../../shared/models/application/index.js';

class UserSummary extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      user: { type: Object },
      tenantId: { type: String },
      employerCurrency: { type: String },
      excludeId: { type: String },
      _fiscalYearApps: { type: Array, attribute: false },
      _previousYearApps: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      heading3Styles,
      linkStyles,
      css`
        nova-card {
          display: block;
        }

        .employee-heading {
          margin: 0;
        }

        .user-summary-header {
          margin: 0;
          padding-bottom: 0;
        }

        .d2l-heading-5 {
          margin-top: 10px;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .application-list {
          margin-top: 0;
          padding: 0;
          list-style: none;
        }

        .app-link {
          display: inline-block;
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
        }

        .cost {
          display: inline-block;
          width: 118px;
          margin-right: 10px;
          text-align: right;
        }

        .profile {
          display: inline-block;
          max-width: 100%;
        }

        .previous-requests {
          margin-bottom: 10px;
        }

        .no-requests {
          margin-top: 0;
          margin-bottom: 0;
          word-wrap: break-word;
        }

        @media (max-width: 615px) {
          .application-list > li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .app-link {
            width: unset;
          }

          .cost {
            margin-right: 0;
          }

          .employee-heading {
            margin-bottom: 6px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._fiscalYearApps = [];
    this._previousYearApps = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    if (!this.user || !this.excludeId) {
      return nothing;
    }

    const userDisplayName = this.user.getDisplayName();
    return html`
      <nova-card>
        <div slot="header">
          <h3 class="employee-heading d2l-heading-3 d2l-skeletize">${this.localize(`general.${this.session.tenant.learnerTerminology}`)}</h3>
          <profile-image class="profile d2l-skeletize" .user=${this.user} mobile-format></profile-image>
        </div>
        <div slot="primary">
          <h4 class="user-summary-header d2l-skeletize">${this.localize('user-summary.header')}</h4>
          ${this._fiscalYearApps.length + this._previousYearApps.length === 0 ? html`
          <p class="no-requests d2l-body-compact d2l-skeletize">${this.localize('user-summary.noRequests', { userDisplayName })}</p>
          ` : html`
          <h5 class="d2l-heading-5 d2l-skeletize">${this.localize('user-summary.currentFiscalYear.header')}</h5>
          ${this._fiscalYearApps.length === 0 ? html`
          <p class="no-requests d2l-body-compact d2l-skeletize">${this.localize('user-summary.currentFiscalYear.noRequests', { userDisplayName })}</p>
          ` : html`
          <div class="previous-requests d2l-body-compact">
            <ul class="application-list d2l-skeletize">
            ${this._fiscalYearApps.map(application => html`
              <li>
                <app-link
                  d2l-link
                  title=${application.activity.title}
                  href=${`/requests/${application.uuid}`}
                  class="app-link">
                  ${application.activity.title}
                </app-link>
                <div class="cost" aria-label="${application.cost}">${application.cost}</div>
              </li>
            `)}
            </ul>
          </div>
          `}
          <h5 class="d2l-heading-5 d2l-skeletize">${this.localize('user-summary.previousFiscalYears.header')}</h5>
          ${this._previousYearApps.length === 0 ? html`
          <p class="no-requests d2l-body-compact d2l-skeletize">${this.localize('user-summary.previousFiscalYears.noRequests', { userDisplayName })}</p>
          ` : html`
          <div class="previous-requests d2l-body-compact">
            <ul class="application-list d2l-skeletize">
            ${this._previousYearApps.map(application => html`
              <li>
                <app-link
                  d2l-link
                  title=${application.activity.title}
                  href=${`/requests/${application.uuid}`}
                  class="app-link">
                  ${application.activity.title}
                </app-link>
                <div class="cost" aria-label="${application.cost}">${application.cost}</div>
              </li>
            `)}
            </ul>
          </div>
          `}
          `}
        </div>
      </nova-card>
    `;
  }

  async updated(_changedProperties) {
    let needsUpdate = false;
    for (const [propName] of _changedProperties) {
      if ((propName === 'user' || propName === 'excludeId') && this.user && this.user.guid) {
        needsUpdate = true;
      }
    }
    if (needsUpdate) await this._init();
  }

  async _init() {
    this.skeleton = true;
    this._fiscalYearApps = [];
    this._previousYearApps = [];
    const userTenant = this.session.tenant;
    const applications = await this.client.getApplications(this.user.guid);

    for (const application of applications) {
      if (application.id === this.excludeId) continue;

      if (application.isDeclined) {
        application.cost = this.localize('user-summary.approvedAmount.declined');
      } else if (application.isWithdrawn) {
        application.cost = this.localize('user-summary.approvedAmount.withdrawn');
      } else if (application.isCancelled) {
        application.cost = this.localize('user-summary.approvedAmount.cancelled');
      } else if (application.isApproved) {
        application.cost = application.tempApprovedAmount
          .inOriginalCurrency()
          .formatAsDecimal(this.session.settings.language);
      } else {
        application.cost = this.localize('user-summary.approvedAmount.pending');
      }

      const fromThisFiscalYear = !userTenant.budget?.fiscalYearStart || application.isFromThisFiscalYear(userTenant.budget.fiscalYearStart);

      if (fromThisFiscalYear) {
        this._fiscalYearApps.push(application);
      } else {
        this._previousYearApps.push(application);
      }
    }
    this._fiscalYearApps.sort(sortApplication());
    this._previousYearApps.sort(sortApplication());
    this.skeleton = false;
  }
}

window.customElements.define('user-summary', UserSummary);
