import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';

import '../../shared/components/general/home-custom-content.js';
import '../../shared/components/general/faqs.js';

import { bodyStandardStyles, heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { styleMap } from 'lit/directives/style-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { LocalizeNova } from '../mixins/localize-nova.js';
import { novaCardStyles } from '../styles/nova-card.js';
import { spaNav } from '../../../shared/methods.js';

export default class ViewHome extends SkeletonMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      tenant: { type: Object },
      _providerLogos: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyStandardStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      novaCardStyles,
      css`
        :host {
          display: block;
        }

        .d2l-centerer {
          max-width: 1230px;
          margin: 0 auto;
        }

        .d2l-gutters {
          position: relative;
          padding-right: 3.439%;
          padding-left: 3.439%;
        }

        /* Overview Template */
        .overview-container-wide {
          margin: 2rem 0;
        }

        .overview-container-narrow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .overview-content {
          flex: 0 0 60%;
          padding: 2rem 0;
        }

        .overview-title {
          font-weight: 900;
          font-size: 3.1rem;
          line-height: 4rem;
          letter-spacing: -0.05rem;
        }

        .overview-text {
          font-weight: normal;
        }

        .overview-img {
          flex: 0 2 40%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        /* Academic Partners Template */
        .academic-partners-wide {
          padding: 4.5rem 0;
          background-color: var(--d2l-color-regolith);
        }

        .academic-partners-narrow {
          display: flex;
          flex-direction: column;
          row-gap: 2.5rem;
        }

        .academic-title {
          margin: 0;
          font-weight: bold;
          text-align: center;
        }

        .logo-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
        }

        .logo-container {
          display: flex;
          flex: 2.5rem;
          align-items: center;
          margin: 0 2rem;
        }

        .logo-img {
          display: block;
          width: 100%;
          max-width: 100%;
          height: auto;
          max-height: 70px;
          object-fit: contain;
        }

        /* Help Template */
        .help-wide {
          margin-top: 120px;
          padding: 3.5rem 0;
          background-color: var(--d2l-color-regolith);
        }

        .help-narrow {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .help-img {
          width: auto;
          height: 110px;
        }

        .help-title {
          margin-bottom: 0;
          font-weight: bold;
          text-align: center;
        }

        .help-text {
          text-align: center;
        }

        .brightspace-bttn {
          margin-top: 6px;
        }

        .help-button {
          width: fit-content;
          margin-top: 1.1rem;
        }

        @media (max-width: 767px) {
          .d2l-gutters {
            padding-right: 15px;
            padding-left: 15px;
          }

          .overview-title {
            font-size: revert;
            line-height: 2rem;
            letter-spacing: -0.03rem;
          }

          .overview-container-wide {
            margin: 0;
          }

          .academic-partners-narrow,
          .logo-row {
            row-gap: 1.5rem;
          }

          .logo-row {
            flex-direction: column;
            align-items: center;
          }

          .help-wide {
            margin-top: 4rem;
          }
        }

        @media (min-width: 1230px) {
          .d2l-gutters {
            padding-right: 40px;
            padding-left: 40px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.homePageContent = {};
    this.faqs = {};
    this._providerLogos = [];
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    if (this.session.tenantId) {
      const promises = [
        this.client.getCustomHomepageContent(this.session.tenantId),
        this.client.getCustomFAQContent(this.session.tenantId),
        this.client.getVisibleProviders(this.session.tenantId),
      ];
      [this.homePageContent, this.faqs, this.visibleProviders] = await Promise.all(promises);

      // Randomize and display only 12 provider logos, sort selected logos alphabetically by provider name
      this._providerLogos = this._shuffle(this.visibleProviders).slice(0, 20);
    }
    this.skeleton = false;
  }

  get _hasBrightspaceInstance() {
    return !!this.session.tenant.hasTag('brightspaceInstance');
  }

  get _brightspaceNavButton() {
    const brightspaceName = this.session.tenant.brightspaceName;
    return html`
    <d2l-button
      class="brightspace-bttn"
      @click=${this.toBrightspaceURL}>
      ${this.localize('home.brightspace.button', { BrightspaceName: brightspaceName })}
    </d2l-button>
    `;
  }

  toBrightspaceURL() {
    const brightspaceURL = this.session.tenant.brightspaceURL;
    window.location = brightspaceURL;
  }

  render() {
    const defaultImage = this.session.tenant?.hasFeature('marketing') ? 'd2l-wave-mouse-lightbulb-green.png' : 'd2l-wave-mouse-lightbulb.png';
    const overview = this.homePageContent?.value?.find(content => content.overview) ?? {};
    const {
      header = this.localize('home.welcome.header'),
      copy = this.localize('home.welcome.copy'),
      imageURL = `../../assets/img/${defaultImage}`,
    } = overview;

    const overviewTemplate = html`
      <div class="overview-container-wide">
        <div class="d2l-centerer d2l-skeletize">
          <div class="d2l-gutters">
            <div class="overview-container-narrow">
              <span class="overview-content">
                <h1 class="overview-title d2l-heading-1">${header}</h1>
                <p class="overview-text d2l-heading-3">${unsafeHTML(copy)}</p>
                <d2l-button id="mainContent" primary @click=${spaNav(this.navigate, '/activities')}>${this.localize('home.welcome.button')}</d2l-button>
                ${ this._hasBrightspaceInstance ? this._brightspaceNavButton : nothing }
              </span>
              <span class="overview-img" role="presentation" style=${styleMap(this._imageMap(imageURL))}></span>
            </div>
          </div>
        </div>
      </div>
    `;

    const logoImages = localLogos => {
      // ensures that there are no single orphan logos in the bottom row
      const numberOfLogosInRow = localLogos.length % 4 === 1 ? 3 : 4;
      const logoTemplates = [];
      while (localLogos.length) {
        logoTemplates.push(html`
          <div class="logo-row">
            ${repeat(localLogos.splice(0, numberOfLogosInRow), ({ subjectDetails }) => html`
                <div class="logo-container">
                  <img alt=${subjectDetails?.name} class="logo-img" src=${subjectDetails?.imageUrl}>
                </div>
              `)}
          </div>
        `);
      }
      return logoTemplates;
    };

    const academicPartnersTemplate = html`
      <div class="academic-partners-wide">
        <div class="d2l-centerer d2l-skeletize">
          <div class="d2l-gutters">
            <div class="academic-partners-narrow">
              <h2 class="academic-title d2l-heading-2">${this.localize('home.academic.title')}</h2>
              ${this._providerLogos.length > 0 ? html`
                ${logoImages(this._providerLogos)}
              ` : nothing}
            </div>
          </div>
        </div>
      </div>
    `;

    const helpTemplate = html`
      <div class="help-wide">
        <div class="d2l-centerer">
          <div class="d2l-gutters">
            <div class="help-narrow">
              <img alt=${this.localize('image.alt.helpingHand')}
                class="help-img"
                src="../../assets/img/helping-hand.png"
                srcset="../../assets/img/helping-hand.svg">
              <h2 class="help-title d2l-heading-2">${this.localize('home.help.title')}</h2>
              <p class="help-text d2l-body-standard">${this.localize('home.help.text')}</p>
              <d2l-button class="help-button" @click=${spaNav(this.navigate, '/contact-support')}>${this.localize('home.help.button')}</d2l-button>
            </div>
          </div>
        </div>
      </div>
    `;

    const faqTemplate = () => {
      const faqContent = this.faqs?.hasContent ? this.faqs.value : undefined;
      return html`
        <div class="faq-wide">
          <div class="d2l-centerer d2l-skeletize">
            <div class="d2l-gutters">
              <faq-list .faqs=${faqContent}></faq-list>
            </div>
          </div>
        </div>
      `;
    };

    return html`
      ${overviewTemplate}
      ${academicPartnersTemplate}
      <home-custom-content ?skeleton=${this.skeleton} .customContent=${this.homePageContent?.value || []}></home-custom-content>
      ${this.session.tenant.learnerTerminology === 'member' ? nothing : faqTemplate()}
      ${helpTemplate}
    `;
  }

  _imageMap(image) {
    return {
      backgroundImage: `url('${image}')`,
    };
  }

  /**
   * @description Modern Fisher-Yates shuffle
   * @param {Array} arr
   * @return {Array}
   */
  _shuffle(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const swap = arr[i];
      arr[i] = arr[j];
      arr[j] = swap;
    }
    return arr;
  }

}

window.customElements.define('view-home', ViewHome);
