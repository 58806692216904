/**
 * Given a date and the fiscal year start date - calculate the date that the current fiscal year started
 *
 */
const getFiscalYearDate = (fiscalDate, endDate) => {
  const endDateObj = new Date(endDate);

  const dates = fiscalDate.split('-').map(p => parseInt(p));
  const [, fiscalMonth, fiscalDay] = dates;

  const fiscalDateObj = new Date(endDateObj.getFullYear(), fiscalMonth - 1, fiscalDay, 0, 0, 0, 0);

  if (endDateObj.getTime() < fiscalDateObj.getTime()) {
    fiscalDateObj.setFullYear(endDateObj.getFullYear() - 1);
  }

  return fiscalDateObj;
};

/**
 * Calculates the start date of the given timeframe relative to today
 *
 */
const calculateStartDate = (timeframe, fiscalYearStart) => {
  let date = new Date();
  if (timeframe === 'lastDay') {
    date.setDate(date.getDate() - 1);
  } else if (timeframe === 'lastWeek') {
    date.setDate(date.getDate() - 7);
  } else if (timeframe === 'lastMonth') {
    date.setMonth(date.getMonth() - 1);
  } else if (timeframe === 'lastThreeMonths') {
    date.setMonth(date.getMonth() - 3);
  } else if (timeframe === 'lastYear') {
    date.setFullYear(date.getFullYear() - 1);
  } else if (timeframe === 'sinceFiscalYear') {
    date = getFiscalYearDate(fiscalYearStart, date);
  } else {
    date.setTime(0);
  }
  return date.toISOString();
};

/**
 * Takes a user and returns the display name from the attributes set in the user.
 *
 * Precedence: displayName -> {firstName} {lastName} -> userId
 *
 */

const getDisplayName = user => {
  if (!user) return null;
  if (user.displayName) return user.displayName;
  if (user.firstName && user.lastName) return [user.firstName, user.lastName].join(' ');
  if (user.email) return user.email;
  if (user.userId) return user.userId;
  return null;
};

const spaNav = (navigate, route) => {
  return () => navigate(route);
};

/**
 * Turns an base10 identifier to a base36 string. The start of the string will be padded with 0's to make the length at least 4.
 *
 */
const idToSlug = id => {
  const idAsNum = parseInt(id);
  return isNaN(idAsNum) ? null : idAsNum.toString(36).padStart(4, '0').toUpperCase();
};

/**
 * Turns a base36 string to a decimal.
 *
 * NOTE: It will turn any string into a decimal - we shoud probably fix that but since it's used a lot we've put this off
 *
 */
const slugToId = slug => {
  if (!slug) return null;
  // TODO: Verify that the slug is a legitimate base36 string. Right now it turns any string into a number
  return parseInt(slug, 36);
};

/**
 * Takes an object array and turns it into a map using the 'objectKey' as the identifier.
 *
 */
const objectArrayToMap = (objectArray, objectKey) => new Map(objectArray.map(i => [i[objectKey], i]));

/**
 * Takes an array and turns it into a map. It uses the 'id' value in each element as the identifier unless otherwise specified.
 *
 * Optionally can also perform a transformation on each row.
 * *Note* Elements that don't have the identifier defined will be skipped.
 *
 */
const mapify = (array, identifier = 'id', transform = item => item) => {
  const ret = {};
  if (!array) return ret;
  for (const item of array) {
    if (item && item[identifier]) ret[item[identifier]] = transform(item);
  }
  return ret;
};

const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

// Checks if a string is a string that can be parsed to JSON
const isJsonString = string => {
  if (typeof string !== 'string') {
    return false;
  }

  try {
    const parsedJson = JSON.parse(string);
    return (typeof parsedJson === 'object');
  } catch (error) {
    return false;
  }
};

export {
  calculateStartDate,
  idToSlug,
  isJsonString,
  getDisplayName,
  getFiscalYearDate,
  mapify,
  sleep,
  slugToId,
  objectArrayToMap,
  spaNav
};
