export default class ActivityFilter {

  constructor(base = {}) {
    this.activeState = base.activeState || [];
    this.hasCareerFilter = base.hasCareerFilter ?? false;
    this.jobs = base.jobs || [];
    this.lots = base.lots || [];
    this.category = base.category || '';
    this.certificateType = base.certificateType || [];
    this.duration = base.duration || [];
    this.delivery = base.delivery || '';
    this.format = base.format || [],
    this.instructionLang = base.instructionLang || [];
    this.provider = base.provider || [];
    this.searchCriteria = base.searchCriteria || '';
    this.skills = base.skills || [];
    this.tags = base.tags || [];
    this.type = base.type || '';
    this.title = base.title || '';
    this.weeklyTimeCommitment = base.weeklyTimeCommitment || [];

    if (base.validProviders) {
      this.provider = this.provider.filter(item => base.validProviders.some(({ id }) => item === id));
    }
  }

  get eventData() {
    return {
      activeState: this.activeState,
      certificateTypeFilter: this.certificateType,
      durationFilter: this.duration,
      formatFilter: this.format,
      instructionLangFilter: this.instructionLang,
      providerFilter: this.provider,
      timeFilter: this.weeklyTimeCommitment,
      tags: this.tags,
    };
  }

  get _otherFilterList() {
    return [
      this.activeState,
      this.category,
      this.certificateType,
      this.duration,
      this.format,
      this.hasCareerFilter && this.skills,
      this.instructionLang,
      this.provider,
      this.type,
      this.weeklyTimeCommitment,
      this.tags,
    ];
  }

  get populatedOthersCount() {
    return this._otherFilterList.reduce((total, val) => {
      if (Array.isArray(val)) {
        return total + val.length;
      } else {
        return total;
      }
    }, 0);
  }

  get populatedOthersTypeCount() {
    return this._otherFilterList.filter(val => val && (Array.isArray(val) ? val.length !== 0 : true)).length;
  }

  get populatedType() {
    let type = '';
    if (this.searchCriteria) {
      type += 'text';
    }
    if ([this.activeState,
      this.certificateType,
      this.jobs,
      this.lots,
      this.duration,
      this.format,
      this.instructionLang,
      this.provider,
      this.weeklyTimeCommitment,
      this.skills,
      this.tags].some(val => val.length > 0) ||
      [this.type,
        this.category,
      ].some(val => val)
    ) {
      type += 'others';
    }
    switch (type) {
      case 'text':
        return 1;
      case 'others':
        return 2;
      case 'textothers':
        return 3;
      default:
        return 0;
    }
  }
}
