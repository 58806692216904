import { formatCurrencyAsDecimal, formatNumber, formatNumberAsPercent } from '../../../shared/helpers/number.js';

export class ReportWidget {
  constructor(base = {}) {
    this.header = new ReportWidget.Header(base.header);
    this.content = base.content ? base.content.map(c => new ReportWidget.Content(c)) : [];
    this.displaySettings = base.displaySettings;
  }
}

ReportWidget.Content = class {
  constructor(base = {}) {
    Object.assign(this, base);
    this.title = base.title || '';
    this.value = !isNaN(base.value) ? base.value : '-';
    this.formatType = base.formatType;
  }

  get formattedValue() {
    if (!isNaN(this.value)) {
      switch (this.formatType) {
        case 'currency':
          return formatCurrencyAsDecimal(this.value);
        default:
          return formatNumber(this.value);
      }
    }
    return this.value;
  }
};

ReportWidget.Header = class extends ReportWidget.Content {
  constructor(base = {}) {
    super(base);
    this.pctChange = base.pctChange;
  }

  get formattedPctChange() {
    if (this.pctChange === undefined || this.pctChange === null) return '';
    const formattedValue = formatNumberAsPercent(this.pctChange);
    return this.pctChange > 0 ? `+${formattedValue}` : formattedValue;
  }
};
