import { css, html, LitElement } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../mixins/localize-nova.js';

/**
 * This view should never really be visible. It's here to redirect to the correct deep link once the magic link is verified.
 */
export default class RedirectMagicLink extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      heading3Styles,
      css`
        :host {
          display: block;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    const relayState = this._isValidRelayState(this.session.relayState) ? this.session.relayState : '/';
    this.navigate(relayState);
  }

  render() {
    return html`${this.localize('login.magiclink.loading')}`;
  }

  _isValidRelayState(relayState) {
    return relayState && !relayState.startsWith('/login/magic');
  }
}

window.customElements.define('redirect-magic-link', RedirectMagicLink);
