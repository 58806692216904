import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';
import { localizeStartDate as _localizeStartDate } from '../activity/shared.js';
import { Cost } from '../../cost.js';
import { novaLocalize } from '../../../l10n/localize.js';

export const localizeStartDate = (application, startDate) => {
  return novaLocalize(startDate) || _localizeStartDate(startDate);
};

class ApplicationTransactionModelSchema extends NovaSchema {
  constructor() {
    super('applicationTransaction');
    this.attributes = {
      uuid: new SchemaAttribute(),
      provider: new SchemaAttribute(),
      revShare: new SchemaAttribute(),
      employer: new SchemaAttribute(),

      costToEmployee : new SchemaAttribute({}).setType(Cost),

      // properties: enabled, percent, rates, country, postalCode
      tax: new SchemaAttribute({}),
      location: new SchemaAttribute({}),

      paymentDate: new SchemaAttribute().addFormatter(localizeStartDate),
      stripeCheckoutSessionId: new SchemaAttribute(),

      // refund invoice ID's
      providerRefund: new SchemaAttribute(),
      employerRefund: new SchemaAttribute(),
      providerRevShareRefund: new SchemaAttribute(),
    };
  }

}

export const ApplicationTransactionSchema = new ApplicationTransactionModelSchema();
