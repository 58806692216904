import { css, html, LitElement } from 'lit';
import { flattenMap } from '@brightspace-ui/core/components/form/form-helper.js';

import '@brightspace-ui/core/components/alert/alert.js';

class ErrorList extends LitElement {
  static get properties() {
    return {
      errors: { type: Array },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          margin-bottom: 24px;
        }
`,
    ];
  }

  constructor() {
    super();
    this.errors = [];
  }

  render() {
    if (!this.errors) return html``;
    const errors = [...flattenMap(this.errors)]
      .filter(([, eleErrors]) => eleErrors.length > 0);
    return html`
      <d2l-alert type="critical">
        ${errors.map(error => html`${error[1]}`)}
      </d2l-alert>
    `;
  }
}
customElements.define('nova-error-list', ErrorList);
