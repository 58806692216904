import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import { css, html, LitElement } from 'lit';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl/rtl-mixin.js';

import { calculateDistance } from '../../../../shared/helpers/util.js';
import { ISO_3166_COUNTRY_CODES } from '../../../../shared/constants.js';
import { LocalizeNova } from '../../mixins/localize-nova.js';

class NovaPostalCodeInput extends LocalizeNova(FormElementMixin(RequesterMixin(RtlMixin(LitElement)))) {

  static get properties() {
    return {
      id: { type: String },
      label: { type: String },
      labelledBy: { type: String, attribute: 'labelled-by' },
      radius: { type: String },
      originPostalCode: { type: String },
      country: { type: String },
      shouldValidateDistance: { type: Boolean },
      value: { type: String },
      required: { type: Boolean },
      disabled: { type: Boolean },
    };
  }

  static get styles() {
    return [labelStyles, buttonStyles,
      css`
        :host {
          display: block;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
  }

  get _element() {
    return this.shadowRoot?.getElementById(this.id);
  }

  get targetValue() {
    return this._element?.value;
  }

  /**
   * Gets a tuple of the user and provider coordinates for the current state. If the user coordinates are invalid, it will return undefined
   *
   * @returns {Promise<undefined|{userCoordinates: *, providerCoordinates: *}>}
   */
  async getPostalCodeCoordinates() {
    const userCoordinates = await this.client.fetchCoordinatesFromPostalCodes(this.targetValue, ISO_3166_COUNTRY_CODES[this.country]);
    const ret = {};
    if (this.coordinatesValid(userCoordinates)) {
      ret.userCoordinates = userCoordinates;
    } else {
      return undefined;
    }
    if (this.originPostalCode) {
      const providerCoordinates = await this.client.fetchCoordinatesFromPostalCodes(this.originPostalCode);
      if (this.coordinatesValid(providerCoordinates)) ret.providerCoordinates = providerCoordinates;
    }
    return ret;
  }

  /**
   * Validates the postal code and sets the validity state
   * If the postal code is valid, it will also calculate the distance from the provider(And the range and
   * @returns {Promise<void>}
   */
  async validatePostalCode() {
    if (!this.country) return;

    const coords = await this.getPostalCodeCoordinates();
    if (!coords) {
      this.setValidity({ patternMismatch: true });
    } else if (this.shouldValidateDistance && this.radius && this.originPostalCode) {
      const { userCoordinates, providerCoordinates } = coords;
      const { lat: lat1, lng: lng1 } = userCoordinates.coordinates;
      const { lat: lat2, lng: lng2 } = providerCoordinates.coordinates;
      this._distanceFromProviders = calculateDistance(lat1, lng1, lat2, lng2);
      if (this._distanceFromProviders > this.radius) {
        this.setValidity({ rangeOverflow: true });
        this.outsideProviderRange = true;
      } else {
        this.setValidity({});
      }
    } else if (!this.shouldValidateDistance) {
      const { userCoordinates } = coords;
      if (!userCoordinates) {
        this.setValidity({ patternMismatch: true });
      } else {
        this.setValidity({});
      }
    } else {
      this.setValidity({ badInput: true });
    }
    this.requestValidate(true);
  }

  async validate() {
    const errors = await Promise.all([this._element.validate()]);
    await this.validatePostalCode();
    const ret = [...errors[0]];
    if (this.validationMessage) ret.push(this.validationMessage);
    return ret;
  }

  get validationMessage() {
    if (this.validity.patternMismatch) {
      return this.localize('apply-activity.personalInfo.postalCode.invalid');
    } else if (this.validity.badInput) {
      return this.localize('apply-activity.personalInfo.postalCode.invalidOptions');
    } else if (this.validity.rangeOverflow) {
      return this.localize('apply-activity.personalInfo.postalCode.tooFar');
    }
    return super.validationMessage;
  }

  coordinatesValid(coordinates) {
    return coordinates && coordinates.locationStatus !== 'requires_valid_location';
  }

  _handlePostalCodeChange(e) {
    this.dispatchEvent(new CustomEvent('change',
      {
        target: e.target,
        bubbles: true,
        composed: true,
        detail: {
          value: e.target.value,
        },
      }));
  }

  render() {
    const tooltip = this.validationError && this.childErrors.size === 0
      ? html`<d2l-tooltip align="start" state="error">${this.validationError}</d2l-tooltip>`
      : null;
    return html`
			  <d2l-input-text
          id="${this.id}"
          .forceInvalid=${this.invalid}
          ?required="${this.required}"
          ?disabled="${this.disabled}"
          @change="${this._handlePostalCodeChange}"
          value="${this.value}"
          ></d2l-input-text>
        ${tooltip}
		`;
  }

}

customElements.define('nova-postal-code-input', NovaPostalCodeInput);
