import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';

import { css, html, LitElement, nothing } from 'lit';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';

class SkillChip extends RtlMixin(LitElement) {

  static get properties() {
    return {
      skill: { type: Object },
      removable: { type: Boolean, reflect: true },
      text: { type: String },
      truncate: { type: Boolean, reflect: true },
      titleText: { type: String },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          --skill-chip-padding: 10px;

          display: inline-block;
          padding: 0 var(--skill-chip-padding);
          font-size: 14px;
          background-color: var(--d2l-color-sylvite);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
        }

        :host([removable]) {
          padding-right: calc(var(--skill-chip-padding) / 3);
        }

        div {
          display: grid;
          grid-template-columns: auto min-content;
        }

        .skill-text {
          grid-column: 1;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        :host([truncate]) .skill-text {
          max-width: 120px;
        }

        button {
          grid-column: 2;
          align-self: center;
          width: calc(0.7rem + 0.5rem);
          height: calc(0.7rem + 0.5rem);
          margin: 0;
          margin-left: calc(var(--skill-chip-padding) / 2);
          padding: 0;
          font-family: inherit;
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 0.3rem;
          cursor: pointer;
        }

        d2l-icon {
          /* Var re-assignment/hijacking like this is a way to facilitate shadow style penetration
          * the vars aren't really patterned as hook-ups for use like this. May not be future proof */
          --d2l-icon-height: 0.7rem;
          --d2l-icon-width: 0.7rem;
          color: var(--d2l-color-galena);
        }

        button:hover {
          background-color: var(--d2l-color-mica);
          border-color: var(--d2l-color-chromite);
        }

        button:hover d2l-icon {
          color: var(--d2l-color-ferrite);
        }

        :host([dir="rtl"]) button {
          margin: 0;
          margin-right: calc(var(--skill-chip-padding) / 2);
        }

        :host([dir="rtl"][removable]) {
          padding: 0 var(--skill-chip-padding);
          padding-left: calc(var(--skill-chip-padding) / 3);
        }

        @media (max-width: 767px) {
          :host {
            --skill-chip-padding: 6px;
            font-size: 12px;
            border-radius: 4px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.skill = null;
    this.removable = false;
    this.text = '';
    this.truncate = false;
  }

  render() {
    if (!this.skill && !this.text) {
      return nothing;
    }
    return this._chipTemplate(this.text || this.skill.name);
  }

  get _removeButtonTemplate() {
    return this.removable
      ? html`
          <button
            aria-label="Remove skill"
            @click=${this._handleRemoveClick}
            tabindex=0
            title="Remove skill">
            <d2l-icon icon="tier1:close-small"></d2l-icon>
          </button>
        `
      : nothing;
  }

  _chipTemplate(displayText) {
    return html`
      <div title=${this.titleText ?? displayText}>
        <p class="skill-text">${displayText}</p>
        ${this._removeButtonTemplate}
      </div>
    `;
  }

  _handleRemoveClick() {
    const removeEvent = new CustomEvent('nova-chip-remove', {
      detail: { skill: this.skill },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(removeEvent);
  }
}

window.customElements.define('skill-chip', SkillChip);
